import GeopostDeliveringChange from "../assets/pdfs/GeopostDeliveringChange.pdf";
import DPDInternationalBrochure from "../assets/pdfs/DPDInternationalBrochure.pdf";
import ProhibitedGoodsEU from "../assets/pdfs/ProhibitedGoodsEU.pdf";
import AirInternationalDeliveryPDF from "../assets/pdfs/AirInternationalDeliverySchedules.pdf";
import RoadInternationalDeliveryPDF from "../assets/pdfs/RoadInternationalDeliverySchedules.pdf";
import BankHolidays2024 from "../assets/pdfs/BankHolidays2024.pdf";
import airShipping from "../assets/pdfs/AirShipping.pdf";
import roadShipping from "../assets/pdfs/RoadShipping.pdf";
import { MENU, SUB_MENU, SUPPORT_FAQS } from "../constants/actions";
import DPDECDGuide from "../assets/pdfs/DPD_ECD_Guide.pdf";

export const HOME = "/";
export const WORLD = "/world-map";
export const SEARCH = "/search";
export const ABOUT_US = "/about";
export const WHO_WE_ARE = "/about/who-we-are";
export const SUSTAINABILITY = "/about/sustainability";
export const CSR_REPORT =
  "https://www.geopost.com/wp-content/uploads/sites/286/2023/06/Geopost_Sustainability_Report_Final.pdf";
export const GREEN_WEBSITE = "https://green.dpd.co.uk/";

export const OUR_SERVICES = "/services";
export const DPD_DIRECT = "/services/dpd-direct";
export const DPD_CLASSIC = "/services/dpd-classic";
export const AIR_CLASSIC = "/services/air-classic";
export const AIR_EXPRESS = "/services/air-express";

export const WORLD_MAP = "/services/world-map";
export const CUSTOM_DETAILS = DPDECDGuide;
export const EU_PROHIBITED_GOODS = ProhibitedGoodsEU;
export const ROW_PROHIBITED_GOODS =
  "https://e.marketing.dpd.co.uk/l/269632/2022-06-21/22mmzr/269632/16558291329WVTethM/Prohibited_Goods.pdf";

export const INNOVATIONS = "/services/innovations";

export const INTERNATIONAL_UNCOVERED = "/international";
export const GET_EXPORTING_QUESTIONS_ANSWERED =
  "https://www.gov.uk/ask-export/";
export const EVENTS_WEBINARS =
  "https://www.events.great.gov.uk/ehome/trade-events-calendar/all-events/";
export const EXPORT_FINANACE_INSURANCE =
  "https://www.great.gov.uk/get-finance/";
export const CHECK_HOW_TO_EXPORT_GOODS = "https://www.gov.uk/export-goods";
export const APPLY_TO_GET_EORI_NUMBER = "https://www.gov.uk/eori";
export const LOOK_UP_COMMIDITY_CODES =
  "https://www.trade-tariff.service.gov.uk/find_commodity";
export const FIND_COMMIDITY_CODES =
  "https://www.gov.uk/guidance/finding-commodity-codes-for-imports-or-exports";
export const GUIDANCE_ON_RULES_ORIGIN =
  "https://www.gov.uk/guidance/get-proof-of-origin-for-your-goods";
export const MEMBERSHIP_DISCOUNT =
  "https://www.export.org.uk/page/FREE_taster_membership_benefits?gclid=Cj0KCQiA64GRBhCZARIsAHOLriKazu27O64OM2ljjmcfWBjdhDi9fF_AKjmOcfvyuOQiJxfYJIhFZrYaAu6WEALw_wcB";

export const OUR_PARTNERS = "/international/partners";
export const OUR_GROUP_PARTNERSHIPS =
  "/international/partners/our-group-partnerships";
export const OUR_TRADE_PARTNERSHIPS =
  "/international/partners/our-trade-partnerships";
export const REGISTER_FOR_EVENT =
  "https://www.events.great.gov.uk/ehome/index.php?eventid=200183029&";
export const HOW_TO_EXPORT_USING_HM_SERVICE = "https://www.gov.uk/export-goods";
export const TRADING_IN_OUT_NORTHERN_IRELAND =
  "https://www.gov.uk/guidance/trading-and-moving-goods-in-and-out-of-northern-ireland";
export const UK_EXPORT_ACADEMY = "https://www.great.gov.uk/export-academy/";
export const UK_TRADESHOW =
  "https://www.gov.uk/guidance/uk-tradeshow-programme";
export const FREE_TRADE_AGREEMENT =
  "https://www.great.gov.uk/campaigns/free-trade-agreements/";
export const EXPERT_SUPPORT_TEAM = "https://www.gov.uk/ask-export-support-team";
export const INTERNATIONAL_TRADE_ADVISORS =
  "https://www.great.gov.uk/contact/office-finder/";

export const INTERNATIONAL_INSIGHTS = "/international/international-insights";
export const DPD_BROCHURE = GeopostDeliveringChange;
export const DPD_INTERNATIONAL_BROCHURE = DPDInternationalBrochure;
export const ESHOPPER_TOOL =
  "https://www.geopost.com/en/expertise/e-shoppers/e-shopper-comparison-tool/";
export const WHITE_PAPER =
  "https://www.dpd.com/wp-content/uploads/sites/77/2021/10/WHITE_PAPER_New-Commerce.pdf";

export const BLOG = "/international/blog";
export const BLOG_ARTICLE = "/international/blog/:id";
export const ROAD_DELIVERY_SCHEDULE = RoadInternationalDeliveryPDF;
export const AIR_DELIVERY_SCHEDULE = AirInternationalDeliveryPDF;
export const BANK_HOLIDAYS = BankHolidays2024;
export const AIR_SHIPPING = airShipping;
export const ROAD_SHIPPING = roadShipping;

export const EXPORT_FUNDAMENTALS = "/international/export-fundamentals";
export const EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS = `${EXPORT_FUNDAMENTALS}?scrollTo=International%20destination%20specific%20guidance`;
export const HOW_TO_EXPORT = "/international/export-fundamentals/how-to-export";
export const YOUR_GUIDE_DUTIES =
  "/international/export-fundamentals/your-guide-to-duties";
export const INTERNATIONAL_PACKAGING_GUIDE =
  "https://e.marketing.dpd.co.uk/l/269632/2022-06-10/21vwwl/269632/1654869596Guy5vttP/Packaging_Guidelines.pdf";

export const SHIPPING_TO_EU =
  "/international/export-fundamentals/shipping-to-eu";
export const EXPORT_CONTROLS =
  "/international/export-fundamentals/export-controls";
export const REVIEW_VAT_RATES =
  "https://ec.europa.eu/taxation_customs/system/files/2020-10/vat_rates_en.pdf";

export const EU_VAT_CHANGES =
  "/international/export-fundamentals/eu-vat-changes";
export const EU_GUIDANCE = "https://ec.europa.eu/taxation_customs/ioss_en";
export const DPD_EU_GUIDANCE = null;
export const KPMG = "https://www.kpmg-vat-oss.com/gb/en/fr";
export const DELOITTE =
  "https://www2.deloitte.com/uk/en/pages/tax/solutions/ioss.html";
export const TAX_DESK = "https://www.taxdesk.com/geopost/";
export const TAXAMO = "https://www.vertexinc.com/ioss-solutions";

export const TIPS_FOR_EXPORT_SUCCESS =
  "/international/export-fundamentals/tips-for-export-success";
export const WTO_RULES =
  "https://www.wto.org/english/Tratop_e/cusval_e/cusval_info_e.htm#3";
export const CHECK_IF_GOODS_MEET_RULES =
  "https://www.gov.uk/guidance/check-your-goods-meet-the-rules-of-origin";
export const GOVERNMENT_GUIDANCE =
  "https://www.gov.uk/guidance/get-proof-of-origin-for-your-goods";
export const MORE_INFO =
  "https://www.gov.uk/guidance/ask-hmrc-for-advice-on-classifying-your-goods";

export const UK_TRADE_AGREEMENT =
  "/international/export-fundamentals/uk-trade-agreements";
export const SUMMARY_NEW_TRADING =
  "https://www.gov.uk/government/publications/summary-the-uks-new-relationship-with-the-eu";
export const TREATY =
  "https://www.gov.uk/government/publications/ukeu-and-eaec-trade-and-cooperation-agreement-ts-no82021";
export const NORTHERN_IRELAND_PROTOCAL =
  "https://www.gov.uk/government/collections/moving-goods-into-out-of-or-through-northern-ireland";
export const CANADA_AGREEMENT =
  "https://www.gov.uk/guidance/summary-of-the-uk-canada-trade-continuity-agreement";
export const JAPAN_AGREEMENT =
  "https://www.gov.uk/guidance/summary-of-the-uk-japan-comprehensive-economic-partnership-agreement";
export const SOUTH_KOREA_AGREEMENT =
  "https://www.gov.uk/guidance/summary-of-uk-south-korea-trade-agreement";
export const NON_EU_AGREEMENT =
  "https://www.gov.uk/guidance/uk-trade-agreements-with-non-eu-countries";
export const FIND_OUT_MORE_WORLD_TRADE_ORGANISATION =
  "https://www.wto.org/english/thewto_e/whatis_e/tif_e/fact2_e.htm";

export const SUPPORT = "/support";
export const FAQS = "/support/faqs";

export const NAV_LINKS = [
  {
    href: HOME,
    name: "Home",
    actionId: MENU.ON_CLICK_HOME, // todo add
  },
  // {
  //   href: WORLD,
  //   name: "World Map",
  //   actionId: MENU.ON_CLICK_HOME,
  // },
  {
    href: ABOUT_US,
    name: "About Us",
    actionId: MENU.ON_CLICK_ABOUT_US,
    secondaryLinks: [
      {
        href: WHO_WE_ARE,
        name: "Who We Are",
        actionId: SUB_MENU.ON_CLICK_WHO_WE_ARE,
      },
      {
        href: SUSTAINABILITY,
        name: "Sustainability",
        actionId: SUB_MENU.ON_CLICK_SUSTAINABILITY,
      },
    ],
  },
  {
    href: OUR_SERVICES,
    name: "Our Services",
    actionId: MENU.ON_CLICK_OUR_SERVICES,
    secondaryLinks: [
      {
        href: DPD_CLASSIC,
        name: "DPD Classic",
        actionId: SUB_MENU.ON_CLICK_DPD_CLASSIC,
      },
      {
        href: DPD_DIRECT,
        name: "DPD Direct & Lite",
        actionId: SUB_MENU.ON_CLICK_DPD_DIRECT,
      },
      {
        href: AIR_CLASSIC,
        name: "Air Classic",
        actionId: SUB_MENU.ON_CLICK_AIR_CLASSIC,
      },
      {
        href: AIR_EXPRESS,
        name: "Air Express",
        actionId: SUB_MENU.ON_CLICK_AIR_EXPRESS,
      },
      {
        href: WORLD_MAP,
        name: "World Map",
        actionId: SUB_MENU.ON_CLICK_WORLD_MAP,
      },
      {
        href: INNOVATIONS,
        name: "Innovations",
        actionId: SUB_MENU.ON_CLICK_INNOVATIONS,
      },
    ],
  },
  {
    href: INTERNATIONAL_UNCOVERED,
    name: "Insight & Info",
    actionId: MENU.ON_CLICK_INSIGHT_INFO,
    secondaryLinks: [
      {
        href: OUR_PARTNERS,
        name: "Our Partners",
        actionId: SUB_MENU.ON_CLICK_OUR_PARTNERS,
      },
      {
        href: INTERNATIONAL_INSIGHTS,
        name: "International Insights",
        actionId: SUB_MENU.ON_CLICK_INTERNATIONAL_INSIGHTS,
      },
      {
        href: BLOG,
        name: "Blog",
        actionId: SUB_MENU.ON_CLICK_NEWSROOM,
      },
      {
        href: EXPORT_FUNDAMENTALS,
        name: "Export Fundamentals",
        actionId: SUB_MENU.ON_CLICK_EXPORT_FUNDAMENTALS,
      },
    ],
  },
  {
    href: SUPPORT,
    name: "Support",
    actionId: MENU.ON_CLICK_SUPPORT,
    secondaryLinks: [
      {
        href: FAQS,
        name: "FAQs",
        actionId: SUPPORT_FAQS.ON_CLICK_FAQS,
      },
    ],
  },
];

export const EXPORT_CONTROLS_LINKS = {
  UK_STRATEGIC_EXPORT_CONTROLS:
    "https://www.gov.uk/guidance/uk-strategic-export-controls",
  UK_SANCTIONS: "https://www.gov.uk/guidance/uk-sanctions",
  EXPORT_ADMINISTRATION_REGULATIONS:
    "https://www.bis.doc.gov/index.php/licensing/commerce-control-list-classification",
  EXPORTING_DUAL_USE_ITEMS:
    "https://policy.trade.ec.europa.eu/help-exporters-and-importers/exporting-dual-use-items_en",
  EU_SANCTIONS_MAP: "https://www.sanctionsmap.eu/#/main",
  UNITED_NATIONS_SECURITY_COUNCIL: "https://www.un.org/securitycouncil/",
  FOREIGN_ASSETS_CONTROL: "https://ofac.treasury.gov/",
};
