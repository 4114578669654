import classNames from "classnames";
import PropTypes from "prop-types";

export const Heading6 = ({ children, classes }) => {
  return <h6 className={classNames("heading-h6", classes)}>{children}</h6>;
};

Heading6.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
