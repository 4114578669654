import classNames from "classnames";
import PropTypes from "prop-types";

export const Heading1 = ({ children, classes }) => {
  return <h1 className={classNames(classes, "heading-h1")}>{children}</h1>;
};

Heading1.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
