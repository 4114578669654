import { useState } from "react";

import classNames from "classnames";
import { Card, Col, Row } from "react-bootstrap";

import { ReactComponent as LightBulbIcon } from "../../assets/icons/iconLightBulbSm.svg";
import { MARKET_INFO_CONTENT_TYPES } from "../../constants/constants";
import { useStore } from "../../store/store";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import { CountrySources } from "../CountrySources/CountrySources";
import { Heading6 } from "../Typography/Heading6";
import styles from "./CountryCardMarketInfo.module.scss";
import useMarketInfo from "../../hooks/widgets/useMarketInfo";

export const CountryCardMarketInfo = () => {
  const [activeLink, setActiveLink] = useState("");
  const {
    country,
    isCountrySideDrawerOpen,
    resetCountrySideDrawer,
    openCountrySideDrawer,
    setCountrySideDrawerContentType,
  } = useStore();

  const {
    marketInfoHeadings,
    sources,
    marketInfoList,
    onMarketInfoListItemClick,
  } = useMarketInfo({
    country,
  });

  const handleMarketInfoListItemClick = (listItem, listItemType) => {
    resetCountrySideDrawer();
    setActiveLink(listItem);

    onMarketInfoListItemClick(listItemType);
    openCountrySideDrawer();
    setCountrySideDrawerContentType(listItemType);
  };

  return (
    <Card.Body
      className={classNames("dark-scrollbar", styles["country-market-info"])}
    >
      <Row>
        {marketInfoHeadings.map(({ heading, value }) => (
          <Col key={heading} md={3}>
            <Heading6>
              <span className="bgd-website-shade-1">{heading}: </span>
              <br /> {value}
            </Heading6>
          </Col>
        ))}
      </Row>

      <Row className="mt-2 px-2">
        <Heading6>{country && country.marketInfo.countrySummary}</Heading6>
      </Row>

      <Row className="mt-2">
        <CallToActionBtn
          classes={styles["country-market-info-export-info-btn"]}
          onClick={() =>
            handleMarketInfoListItemClick(
              "Export Opportunities for Online Sellers",
              MARKET_INFO_CONTENT_TYPES.COUNTRY_OPPORTUNITIES
            )
          }
        >
          <span className="pr-2">
            <LightBulbIcon />
          </span>
          Export Opportunities for Online Sellers
        </CallToActionBtn>
        <ul>
          {marketInfoList.map(({ contentType, content }, index) => (
            <li
              key={`${content}-${index}`}
              className={classNames(
                styles["country-market-info-list-item"],
                content === activeLink &&
                  isCountrySideDrawerOpen &&
                  "country-item-active"
              )}
              onClick={() =>
                handleMarketInfoListItemClick(content, contentType)
              }
            >
              {content}
            </li>
          ))}
        </ul>
      </Row>

      <CountrySources sources={sources} />
    </Card.Body>
  );
};
