import PropTypes from "prop-types";
import { CardDeck, Col } from "react-bootstrap";

import { InfoCard } from "../InfoCard/InfoCard";
export const CardContainer = ({
  cards,
  cardsBgColor,
  columnSpan,
  imgHeight,
  imgWidth,
  cardFlip,
  dpdRedButton,
}) => {
  return (
    <CardDeck className="mb-3 w-100">
      {cards.map((card, index) => (
        <Col
          key={`${card.cardTitle}-${card.cardText}-${index}`}
          md={columnSpan || card.columnSpan}
          sm={columnSpan || card.columnSpan}
          xs={12}
          className="mb-3"
        >
          <InfoCard
            cardText={card.cardText ? card.cardText : ""}
            cardTitle={card.cardTitle ? card.cardTitle : ""}
            cardBackText={card.cardBackText ? card.cardBackText : ""}
            cardBackTitle={card.cardBackTitle ? card.cardBackTitle : ""}
            cardBg={card.cardBg && card.cardBg}
            cardBgColor={cardsBgColor}
            cardImg={card.cardImg && card.cardImg}
            cardBtnTxt={card.cardBtnTxt && card.cardBtnTxt}
            cardBtnLink={card.cardBtnLink && card.cardBtnLink}
            externalLink={card.externalLink}
            shouldFlip={cardFlip}
            imgHeight={imgHeight}
            imgWidth={imgWidth}
            cardHoverFn={card.cardHoverFn}
            cardBtnOnClick={card.cardBtnOnClick}
            cardBgClassName={card.cardBgClassName}
            dpdRedButton={dpdRedButton}
          />
        </Col>
      ))}
    </CardDeck>
  );
};
CardContainer.propTypes = {
  cards: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.any)]),
  cardsBgColor: PropTypes.string,
  columnSpan: PropTypes.number,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  cardFlip: PropTypes.bool,
  cardHoverFn: PropTypes.func,
  dpdRedButton: PropTypes.bool,
};
