import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import asendiaImg from "../../assets/pages/our-services/dpd-direct/asendia.png";
import { ANIMATIONS } from "../../constants/constants";
import { ImgContainer } from "../ImgContainer/ImgContainer";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading4 } from "../Typography/Heading4";

export const DpdDirectDeliveryOption = () => {
  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container>
          <Col md={12}>
            <Heading3>DPD Direct</Heading3>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              Expand your online business and accelerate your growth with our
              international e-commerce delivery solution, delivered in
              partnership with Asendia
            </Heading4>
            <ImgContainer imgs={asendiaImg} columnSpan={6} />
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Exporting your goods to international customers may seem a
              daunting prospect - but where you see challenge, we see
              opportunity With DPD Direct, our global delivery expertise will
              give you the peace of mind and confidence you need.
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              It’s all about choice and experience - DPD Direct offers you a
              variety of tracked worldwide services that can be tailored for
              your needs depending on destination, cost, weight, delivery
              options and experience.
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Designed and refined for International e-commerce. You can choose
              a fully managed cost effective delivery solution that combines
              commercial clearance expertise and leading last mile local
              delivery for your target markets
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Take advantage of our tracked postal options. Owned by La Poste
              one of the world’s largest and leading postal authorities,
              DPDGroup enjoys direct access to the global postal solutions, so
              you know you are in safe hands.
            </Body>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
};
