import { Layer, Source } from "react-map-gl";

import { LONDON_COORDINATES } from "../../constants/constants";
import { useFetchCountryMapboxData } from "../../hooks/useFetchCountryMapboxData";
import { useStore } from "../../store/store";
import variables from "../../styles/colors.scss";

export const MapLine = () => {
  const { country } = useStore();
  const { coordinates } = useFetchCountryMapboxData(country);

  return (
    <>
      {country && coordinates.length !== 0 && (
        <Source
          id="polylineLayer"
          type="geojson"
          data={{
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [LONDON_COORDINATES, coordinates],
            },
          }}
        >
          <Layer
            id="lineLayer"
            type="line"
            source="my-data"
            layout={{
              "line-join": "round",
              "line-cap": "round",
            }}
            paint={{
              "line-color": variables.dpdRed,
              "line-width": 5,
            }}
          />
        </Source>
      )}
    </>
  );
};
