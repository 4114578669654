import { useEffect } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Row } from "react-bootstrap";

import { COUNTRY_CARD_NAV_LINKS } from "../../constants/constants";
import { useStore } from "../../store/store";
import styles from "./CountryCardNav.module.scss";

export const CountryCardNav = ({ activeLink, setActiveLink }) => {
  const { resetCountrySideDrawer } = useStore();

  useEffect(() => {
    return () => {
      setActiveLink("Delivery Options");
    };
  }, []);

  const handleNavClick = link => {
    setActiveLink(link);
    resetCountrySideDrawer();
  };

  return (
    <div className={styles.container}>
      <Row>
        {COUNTRY_CARD_NAV_LINKS.map(link => (
          <span
            key={link}
            className={classNames(
              styles.navlink,
              link === activeLink && "country-item-active"
            )}
            onClick={() => handleNavClick(link)}
          >
            {link}
          </span>
        ))}
      </Row>
    </div>
  );
};

CountryCardNav.propTypes = {
  activeLink: PropTypes.string,
  setActiveLink: PropTypes.func,
};
