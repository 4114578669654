import { useEffect, useState } from "react";

import { fetchCountryMapboxData } from "../apis/api";

export const useFetchCountryMapboxData = country => {
  const [coordinates, setCoordinates] = useState([]);
  const [countryIsoCode, setCountryIsoCode] = useState("");

  useEffect(() => {
    const fetchCountryData = async () => {
      if (country && country.marketInfo) {
        const { coordinates, countryIsoCode } = await fetchCountryMapboxData(
          country.marketInfo.countryName
        );

        setCoordinates(coordinates);
        setCountryIsoCode(countryIsoCode);
      }
    };

    if (country) {
      fetchCountryData();
    }
  }, [country]);

  return { coordinates, countryIsoCode };
};
