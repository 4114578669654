import classNames from "classnames";
import PropTypes from "prop-types";

export const Heading5 = ({ children, classes }) => {
  return <h5 className={classNames(classes, "heading-h5")}>{children}</h5>;
};

Heading5.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
