import { PrimaryNavBar } from "../PrimaryNavBar/PrimaryNavBar";
import { SecondaryNarBar } from "../SecondaryNavBar/SecondaryNarBar";
import styles from "./Header.module.scss";

export const Header = () => {
  return (
    <header className={styles["mydpd-header"]}>
      <PrimaryNavBar />
      <SecondaryNarBar />
    </header>
  );
};
