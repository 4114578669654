import PropTypes from "prop-types";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ReactComponent as SearchIcon } from "../../assets/icons/iconSearch.svg";
import { ReactComponent as SearchIconDark } from "../../assets/icons/iconSearchDark.svg";
import { ReactComponent as XmarkIcon } from "../../assets/icons/xmark-light.svg";

import { MOST_POPULAR } from "../../constants/constants";
import {
  addRecentSuggested,
  clearRecentSuggested,
  getRecentSuggested,
} from "../../helpers/helpers";
import style from "./SearchInput.module.scss";

export const SearchInput = ({ isMobile = false }) => {
  const navigate = useNavigate();
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [suggestValues, setSuggestValues] = useState(MOST_POPULAR);
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    setRecentSearches(getRecentSuggested);
  }, [open]);

  useEffect(() => {
    if (searchValue === "" || !searchValue) {
      return setSuggestValues(MOST_POPULAR);
    }

    const list = MOST_POPULAR.filter(
      item => item.value.toUpperCase().indexOf(searchValue.toUpperCase()) !== -1
    );

    setSuggestValues(list);
  }, [searchValue]);

  const onSearch = e => {
    if (e.key === "Enter" || e.keyCode === 13) {
      const data = { searchTerm: searchValue };

      const blob = new Blob([JSON.stringify(data, null, 2)], {
        type: "application/json",
      });
      navigator.sendBeacon(
        `${`${process.env.REACT_APP_IW_APIS_URL}/v1/searchTerm`}`,
        blob
      );
      setOpen(false);
      addRecentSuggested(searchValue, recentSearches);
      setSearchValue("");
      navigate(`/search?query=${searchValue}`);
    }
  };

  const calculateHeight = () => {
    if (!open) return {};

    if (isMobile) {
      const contentElement = document.getElementById("dynamic-content");

      return recentSearches.length > 0
        ? { height: contentElement?.offsetHeight + 15 || "250px" }
        : { height: "105px" };
    }

    return recentSearches.length > 0 ? { height: "164px" } : { height: "85px" };
  };

  return (
    <>
      <div
        className={`${style.inputContainer} ${
          open ? style.inputOpen : style.inputClose
        }`}
      >
        {!open ? (
          <SearchIcon
            onClick={() => setOpen(true)}
            style={{ cursor: "pointer" }}
          />
        ) : (
          <SearchIconDark style={{ cursor: "pointer" }} />
        )}
        {open && (
          <>
            <input
              className={style.input}
              placeholder="How can we help you?"
              onChange={e => {
                setSearchValue(e.target.value);
              }}
              onKeyDown={onSearch}
            />
            <XmarkIcon className={style.xmark} onClick={() => setOpen(false)} />
          </>
        )}
      </div>
      <div
        className={`${style.suggestContainer} ${
          open ? style.suggestOpen : style.suggestClose
        }`}
        style={calculateHeight()}
      >
        {open && (
          <div className={`${style.suggestContent}`} id="dynamic-content">
            <div className={style.suggest}>
              <h6 className={style.headerText}>Suggested searches:</h6>
              {suggestValues.length > 0 ? (
                suggestValues?.map(({ value, link }) => (
                  <a
                    key={value}
                    className={style.suggestItem}
                    href={link}
                    onClick={() => addRecentSuggested(value, recentSearches)}
                  >
                    {value}
                  </a>
                ))
              ) : (
                <br></br>
              )}
            </div>
            {recentSearches.length > 0 ? (
              <div className={style.recent}>
                <h6 className={style.headerText}>Your recent searches:</h6>
                <>
                  {recentSearches
                    ?.slice(0) // create shallow copy to display searches in reverse order
                    .reverse()
                    .map((item, index) => (
                      <a
                        key={index}
                        className={style.recentItem}
                        href={`/search?query=${item}`}
                      >
                        {item}
                      </a>
                    ))}
                  <p
                    className={style.clear}
                    onClick={() => {
                      clearRecentSuggested();
                      setRecentSearches([]);
                    }}
                  >
                    Clear recent
                  </p>
                </>
              </div>
            ) : (
              <br></br>
            )}
          </div>
        )}
      </div>
    </>
  );
};

SearchInput.propTypes = {
  isMobile: PropTypes.bool,
};
