import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import section4Bg from "../../../assets/backgrounds/jumbotron-bg.png";
import jumbotronBg from "../../../assets/backgrounds/jumbotron-purple-right-bg.png";
import dpdAsendia from "../../../assets/pages/our-services/dpd-direct/dpdAsendia.png";
import jumbotronImg from "../../../assets/pages/our-services/dpd-direct/jumbotron-img.png";
import section1Bg from "../../../assets/pages/our-services/dpd-direct/section-1-bg.png";
import section2Img from "../../../assets/pages/our-services/dpd-direct/section-2-img.png";
import section3Bg from "../../../assets/pages/our-services/dpd-direct/section-3-bg.png";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentHotPink } from "../../../components/Typography/AccentHotPink";
import { AccentLavenderIndigo } from "../../../components/Typography/AccentLavenderIndigo";
import { AccentMint } from "../../../components/Typography/AccentMint";
import { AccentOrange } from "../../../components/Typography/AccentOrange";
import { AccentRed } from "../../../components/Typography/AccentRed";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { Bold } from "../../../components/Typography/BlogArticle/Bold";
import { DPD_DIRECT } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../constants/constants";

export const DpdDirect = () => {
  const tracker = useTracker();

  return (
    <Trackable
      interfaceId={DPD_DIRECT.INTERFACE_ID}
      loadId={DPD_DIRECT.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>DPD Direct & DPD Direct Lite</Heading5>
            <Heading1>
              Expand your online business and
              <AccentMint> accelerate your growth</AccentMint>
            </Heading1>
            <Heading2>
              with our international e-commerce delivery solutions
            </Heading2>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={jumbotronImg} columnSpan={6} />
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              Exporting your goods to international customers may seem a
              daunting prospect
            </Heading1>
            <Heading3>
              Where you see challenge, we see{" "}
              <AccentOrange>opportunity</AccentOrange>
            </Heading3>
            <Body>
              With DPD Direct, our global delivery expertise will give you the
              peace of mind and confidence you need - leave the delivery
              solutions to us so you can focus on developing your business.
            </Body>
            <Col md={12}>
              <ReactPlayer
                url={"https://player.vimeo.com/video/130777271?h=f48a8ad3a4"}
                width="100%"
                controls={true}
                onPlay={() => tracker.logEvent(DPD_DIRECT.ON_CLICK_PLAY_VIDEO)}
              />
            </Col>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <ImgContainer
          imgs={section2Img}
          columnSpan={6}
          imgsAnimation={ANIMATIONS.FADE_IN_LEFT}
        />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              It’s all about{" "}
              <AccentLavenderIndigo>
                choice and experience{" "}
              </AccentLavenderIndigo>
            </Heading1>
            <Heading3>
              When it comes to international delivery, we know that options are
              key. That&apos;s why we&apos;ve extended our tracked worldwide
              solutions to give you even more choice
            </Heading3>
            <Body>
              Select from a blend of postal and courier delivery, with or
              without, signature, to a home, mailbox or collection point.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <div className="GetInTouch_card__mVGC4 card">
          <div className="card-body">
            <Col className="text-center">
              <Heading4>
                We are excited to introduce you to your{" "}
                <AccentRed>
                  <Bold>new delivery experience</Bold>
                </AccentRed>
              </Heading4>
            </Col>
            <Row className="row-center">
              <Col md={5}>
                <div className="text-center">
                  <Heading2>DPD Direct</Heading2>
                </div>
                <Body>
                  If you&apos;re looking for an affordable tracked parcel
                  service, available for shipping to all the world&apos;s key
                  e-commerce destinations, this solution might be just what you
                  need.
                </Body>
                <Body>
                  Deliveries are carried out by postal & commercial specialists
                  within the country, providing comprehensive tracking and
                  delivery experience.
                </Body>
                <Body>
                  It allows you and your customers to stay informed about the
                  status of your packages and have confidence that they&apos;re
                  en route. Suitable for packages weighing{" "}
                  <Bold>up to 5KG.</Bold>
                </Body>
              </Col>
              <Col md={5}>
                <div className="text-center">
                  <Heading2>DPD Direct Lite</Heading2>
                </div>
                <Body>
                  Our most budget-friendly option for shipping packages
                  internationally, ideal for Customers shipping low-value
                  parcels.
                </Body>
                <Body>
                  This service carried out by the local postal operator,
                  ensuring both reliability and excellent value for your money,
                  DPD Direct Lite provides a simple solution, available
                  worldwide, and with tracking for most destinations.
                </Body>
                <Body>
                  It covers destinations across the world and it&apos;s suitable
                  for packages weighing <Bold>up to 2KG.</Bold>
                </Body>
              </Col>
            </Row>
            <Col className="text-center">
              <Heading4>
                Now available on{" "}
                <AccentRed>
                  <Bold>all shipping platforms!</Bold>
                </AccentRed>
              </Heading4>
            </Col>
          </div>
        </div>
      </Section>

      <Section bgImg={section3Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              Customs clearance doesn’t have to be complicated
            </Heading1>
            <Heading3>
              We provide a range of customs clearance methods to
              <AccentHotPink> simplify the process</AccentHotPink> of
              international trade.
            </Heading3>
            <Body>
              We’ve developed our customs clearance solutions to give you the
              most appropriate and efficient options, not just for your business
              but for your customers too.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section4Bg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ImgContainer imgs={[dpdAsendia]} columnSpan={12} />

            <Heading2>
              A strategic partnership between Asendia and DPD UK to facilitate
              UK retailers global e-commerce expansion
            </Heading2>
            <Heading3>
              Asendia, the joint venture between La Poste and Swiss Post, brings
              together logistics excellence and smart technologies to support
              e-commerce businesses to grow internationally.
            </Heading3>
            <Body>
              As part of La Poste Groupe, Asendia and Geopost have combined
              their extensive international logistics expertise and network
              connections to build a tailored, global e-commerce solution with
              DPD Direct.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
