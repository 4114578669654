import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import { useStore } from "../../store/store";
import { AnchorLink } from "../AnchorLink/AnchorLink";
import styles from "./CountrySources.module.scss";

export const CountrySources = ({ sources }) => {
  const { country } = useStore();

  return (
    <>
      {country && country.marketInfo.sources && sources.length > 0 && (
        <div className={styles["sources-container"]}>
          <p className={styles["sources-title"]}>Sources:</p>
          <Row className={styles["sources-links"]}>
            <Col>
              {Array.isArray(sources) &&
                sources.map(({ link, linkText }, index) => (
                  <AnchorLink
                    key={`${index}-${link}-${linkText}-${country.marketInfo.countryName}`}
                    to={link}
                    externalLink={true}
                    classes="mr-3"
                  >
                    {linkText}
                  </AnchorLink>
                ))}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

CountrySources.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.any),
};
