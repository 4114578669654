import classNames from "classnames";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { ANIMATIONS, DEFAULT_ANIMATION_DELAY } from "../../constants/constants";
import styles from "./ImgContainer.module.scss";

export const ImgContainer = ({
  imgs,
  columnSpan = 6,
  imgsAnimation = ANIMATIONS.ZOOM_IN,
}) => {
  const imgArr = Array.isArray(imgs) ? imgs : [imgs];

  return (
    <>
      {imgArr.map((img, index) => (
        <Col
          key={`${img}-${index}`}
          md={columnSpan}
          className={styles["img-container"]}
        >
          {imgsAnimation ? (
            <ScrollAnimation
              animateIn={imgsAnimation}
              delay={DEFAULT_ANIMATION_DELAY}
              animateOnce={true}
            >
              <img
                className={classNames("mx-auto d-block img-fluid")}
                src={img}
              />
            </ScrollAnimation>
          ) : (
            <img
              className={classNames("mx-auto d-block img-fluid")}
              src={img}
            />
          )}
        </Col>
      ))}
    </>
  );
};

ImgContainer.propTypes = {
  imgs: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  columnSpan: PropTypes.number,
  imgsAnimation: PropTypes.string,
};
