import { useState } from "react";

import classNames from "classnames";
import { Card, Row } from "react-bootstrap";

import * as routes from "../../router/constants";
import { useStore } from "../../store/store";
import { DownloadBtn } from "../DownloadBtn/DownloadBtn";
import { Heading6 } from "../Typography/Heading6";
import styles from "./CountryCardExportInfo.module.scss";
import useExportCards from "../../hooks/widgets/useExportCards";

export const CountryCardExportInfo = () => {
  const [activeLink, setActiveLink] = useState("");
  const {
    country,
    isCountrySideDrawerOpen,
    resetCountrySideDrawer,
    openCountrySideDrawer,
    setCountrySideDrawerContentType,
  } = useStore();

  const handleExportInfoListItemClick = (listItem, listItemType) => {
    resetCountrySideDrawer();
    openCountrySideDrawer();
    setCountrySideDrawerContentType(listItemType);
    setActiveLink(listItem);
  };

  const { exportInfoList } = useExportCards({ country });

  return (
    <Card.Body>
      <Row className="mt-2 px-2">
        <Heading6>{country?.exportInfo.exportSummary}</Heading6>
      </Row>

      <Row className="mt-2">
        <ul>
          {exportInfoList.map(({ contentType, content }, index) => (
            <li
              key={`${content}-${index}`}
              className={classNames(
                styles["country-market-info-list-item"],
                content === activeLink &&
                  isCountrySideDrawerOpen &&
                  "country-item-active"
              )}
              onClick={() =>
                handleExportInfoListItemClick(content, contentType)
              }
            >
              {content}
            </li>
          ))}
        </ul>
      </Row>

      <Row className="mt-2">
        <DownloadBtn classes="w-100" downloadLink={routes.CUSTOM_DETAILS}>
          Download Customs Details (1.2mb)
        </DownloadBtn>
      </Row>
    </Card.Body>
  );
};
