import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import section1Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import { ReactComponent as CanadaIcon } from "../../../../assets/icons/flagCanadaSmall.svg";
import { ReactComponent as EuIcon } from "../../../../assets/icons/flagEuropeanUnion.svg";
import { ReactComponent as JapanIcon } from "../../../../assets/icons/flagJapanSmall.svg";
import { ReactComponent as SouthKoreaIcon } from "../../../../assets/icons/flagSouthKorea.svg";
import { ReactComponent as UkIcon } from "../../../../assets/icons/flagUnitedKingdom.svg";
import jumbotronBg from "../../../../assets/pages/international/uk-trade-agreement/jumbotron-bg.png";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import * as router from "../../../../router/constants";
import variables from "../../../../styles/colors.scss";

export const UkTradeAgreement = () => {
  return (
    <>
      <Section bgImg={jumbotronBg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader headerText="The UK’s Trade Agreements" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Learn more about the trade agreements the UK has signed and those
              that are under negotiation.
            </Heading2>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <EuIcon className="mr-3" />
              European Union
            </Heading3>
            <Body>
              The UK has now left the EU Single Market and Customs Union and as
              a result the trading relationship with the EU has changed. Since
              January 2021, trade between the UK and EU has been governed by the
              Trade and Cooperation Agreement.
            </Body>
            <ExternalLinkBtn
              classes={"mr-4"}
              btnLink={router.SUMMARY_NEW_TRADING}
            >
              Summary of the new trading relationship
            </ExternalLinkBtn>
            <ExternalLinkBtn classes={"mr-4"} btnLink={router.TREATY}>
              Treaty
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <UkIcon className="mr-3" />
              The Northern Ireland Protocol
            </Heading3>
            <Body>
              If you are moving goods to Northern Ireland, there are also
              changes you need to be aware of. The most up-to-date guidance on
              the Northern Ireland Protocol can be found below
            </Body>
            <ExternalLinkBtn
              classes={"mr-4"}
              btnLink={router.NORTHERN_IRELAND_PROTOCAL}
            >
              Northern Ireland Protocol
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <CanadaIcon className="mr-3" />
              Canada
            </Heading3>
            <Body>
              On 1 April 2021, the UK signed a trade agreement with Canada
            </Body>
            <ExternalLinkBtn classes={"mr-4"} btnLink={router.CANADA_AGREEMENT}>
              Access details about the Agreement here
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <JapanIcon className="mr-3" />
              Japan
            </Heading3>
            <Body>
              The UK signed a free trade agreement (FTA) with Japan on 23
              October 2020
            </Body>
            <ExternalLinkBtn classes={"mr-4"} btnLink={router.JAPAN_AGREEMENT}>
              Access details about the Agreement here
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <SouthKoreaIcon className="mr-3" />
              South Korea
            </Heading3>
            <Body>
              The UK has signed a trade agreement with South Korea, which is in
              effect.
            </Body>
            <ExternalLinkBtn btnLink={router.SOUTH_KOREA_AGREEMENT}>
              Access details about the Agreement here
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Other Countries</Heading3>
            <Body>
              Find out details on other Free Trade Agreements with other non-EU
              countries below
            </Body>
            <ExternalLinkBtn classes={"mr-4"} btnLink={router.NON_EU_AGREEMENT}>
              Free Trade Agreements with other non-EU countries
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              What happens if the UK doesn’t have a Trade Agreement in place?
            </Heading3>
            <Body>
              If no trade agreement exists between the UK and another country,
              trade with that country takes place under World Trade Organization
              (WTO) rules. Unless there is a trade agreement between two or more
              nations, WTO regulations require that the same trading terms be
              applied to all WTO members - this is known as most-favoured-nation
              (MFN) treatment.
            </Body>
            <Body>
              Some countries who trade with the UK under MFN rules are:
            </Body>
            <Body>
              United States, United Arab Emirates, Saudi Arabia, China and India
            </Body>
            <ExternalLinkBtn
              classes={"mr-4"}
              btnLink={router.FIND_OUT_MORE_WORLD_TRADE_ORGANISATION}
            >
              Find out more about the World Trade Organisation
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </>
  );
};
