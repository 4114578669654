import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import section2Img from "../../../assets/icons/weight.png";
import jumbotronBg from "../../../assets/pages/our-services/air-classic/jumbotron-bg.png";
import jumbotronImg from "../../../assets/pages/our-services/air-classic/jumbotron-img.png";
import section1Bg from "../../../assets/pages/our-services/air-classic/section-1-bg.png";
import section2Bg from "../../../assets/pages/our-services/air-classic/section-2-bg.png";
import section3Bg from "../../../assets/pages/our-services/air-express/section-3-bg.png";
import section3Img from "../../../assets/pages/our-services/air-express/section-3-img.png";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentMint } from "../../../components/Typography/AccentMint";
import { AccentOrange } from "../../../components/Typography/AccentOrange";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading5 } from "../../../components/Typography/Heading5";
import { AIR_CLASSIC } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";

export const AirClassic = () => {
  return (
    <Trackable
      interfaceId={AIR_CLASSIC.INTERFACE_ID}
      loadId={AIR_CLASSIC.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <ImgContainer imgs={jumbotronImg} columnSpan={12} />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>Air Classic</Heading5>
            <Heading1>
              More destinations, <br />
              <AccentOrange>more control</AccentOrange>
            </Heading1>
            <Heading2>
              All at the right price to bring you closer to your customers
            </Heading2>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              An extension to our leading European road service, Air Classic
              connects you to{" "}
              <AccentMint>over 200 countries worldwide</AccentMint>
            </Heading2>
            <Body>
              Air Classic is the perfect service if you don’t want to compromise
              the quality or speed of the delivery experience but want more
              control or even better, to reduce your international shipping
              costs.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section2Bg} style={{ height: SECTION_HEIGHT }}>
        <ImgContainer
          imgs={section2Img}
          columnSpan={12}
          imgsAnimation={ANIMATIONS.FADE_IN}
        />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Discover a whole new dimension with our deadweight billing option
            </Heading2>
            <Heading3>
              Transporting goods by air can be costly if your dimensional weight
              is larger than the actual or dead weight.
            </Heading3>
            <Body>
              With Air Classic, no matter where in the world you need to ship,
              we have deadweight billing options available so you can easily
              track your charges and enjoy a more competitive edge.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <ImgContainer
          imgs={section3Img}
          columnSpan={6}
          imgsAnimation={ANIMATIONS.FADE_IN_LEFT}
        />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>In flight and flexible customs clearance</Heading1>
            <Heading3>
              We’ve developed a{" "}
              <AccentOrange> user-friendly leading-edge solution </AccentOrange>{" "}
              for the payment of duties and taxes.
            </Heading3>
            <Body>
              To improve customer confidence and avoid unnecessary delays, a
              branded email is sent to the nominated recipient informing them of
              the charges, with secure options to pay. If you want to cover the
              duty and tax charges, then no problem, duty paid is also available
              with DPD Air Classic. The choice is yours!
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
