import PropTypes from "prop-types";

import { FILTER_ITEMS, FILTERS } from "../../constants/constants";
import { Dropdown } from "../Dropdown/Dropdown";

export const SearchFiltersMobile = ({
  active,
  setActive,
  setItemOffset,
  filterLengths,
  setCurrentPage,
}) => {
  const activeIndex = Object.values(FILTERS).indexOf(active);
  const activeValue = Object.values(FILTERS)[activeIndex];

  const activeFilter = `${FILTER_ITEMS[activeIndex]} (${filterLengths[activeValue]})`;

  const items = FILTER_ITEMS.map(
    (item, index) => `${item} (${filterLengths[Object.values(FILTERS)[index]]})`
  );

  const onChange = value => {
    const filterName = value.split("(")[0];
    const index = FILTER_ITEMS.indexOf(filterName.trim());

    setActive(Object.values(FILTERS)[index]);
    setItemOffset(0);
    setCurrentPage(0);
  };

  return (
    <>
      {filterLengths.all ? (
        <Dropdown
          activeSearch={activeFilter}
          options={items}
          onChange={onChange}
          forSearch
        />
      ) : (
        <></>
      )}
    </>
  );
};

SearchFiltersMobile.propTypes = {
  active: PropTypes.string,
  setActive: PropTypes.func,
  setItemOffset: PropTypes.func,
  filterLengths: PropTypes.object,
  setCurrentPage: PropTypes.func,
};
