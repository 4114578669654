import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/jumbotron-purple-right-bg.png";
import videoBg from "../../../assets/backgrounds/purple-bottom-bg.png";
import { ReactComponent as BatteryIcon } from "../../../assets/icons/iconBattery.svg";
import { ReactComponent as ElectricVanIcon } from "../../../assets/icons/iconElectricVan.svg";
import { ReactComponent as GlobeIcon } from "../../../assets/icons/iconGlobe.svg";
import { ReactComponent as HeartIcon } from "../../../assets/icons/iconHeart.svg";
import { ReactComponent as ParcelIcon } from "../../../assets/icons/iconParcel.svg";
import { ReactComponent as PeopleIcon } from "../../../assets/icons/iconPeople.svg";
import jumbotronImg from "../../../assets/pages/about-us/sustainability/jumbotron-img.png";
import section1Bg from "../../../assets/pages/about-us/sustainability/section-1-bg.png";
import section2Bg from "../../../assets/pages/about-us/sustainability/section-2-bg.png";
import { CallToActionBtn } from "../../../components/CallToActionBtn/CallToActionBtn";
import { CardContainer } from "../../../components/CardContainer/CardContainer";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentMint } from "../../../components/Typography/AccentMint";
import { Body } from "../../../components/Typography/Body";
import { BulletList } from "../../../components/BulletList/BulletList";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { YoutubeEmbed } from "../../../components/YoutubeEmbed/YoutubeEmbed";
import { SUSTAINABILITY } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";
import * as routes from "../../../router/constants";

export const Sustainability = () => {
  return (
    <Trackable
      interfaceId={SUSTAINABILITY.INTERFACE_ID}
      loadId={SUSTAINABILITY.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>Sustainability</Heading5>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              For our people, our planet and all of our communities, we are
              innovating for a better, brighter and more sustainable tomorrow.
            </Heading2>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading4>
              Be part of our journey, because no matter where you live or where
              your parcels are delivered…
            </Heading4>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              We all share the <AccentMint>#SameAddress</AccentMint>
            </Heading1>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={jumbotronImg} columnSpan={6} />
      </Section>

      <Section bgImg={videoBg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              We all share the <AccentMint>#SameAddress</AccentMint>
            </Heading2>
            <YoutubeEmbed embedId="_hxqNjQiOec" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>One journey, many deliveries along the way</Heading3>
            <Body>
              We are the largest parcel delivery network in Europe and we
              continue to expand globally.
            </Body>
            <Body>
              Our sustainability actions extend far beyond decarbonisation of
              our fleet and vehicles. We are embracing new technologies to make
              every aspect of our business more sustainable. From switching to
              renewable energy in our buildings and depots, to investing in
              recycled packaging and maximising the reuse of as many materials
              as possible.
            </Body>
            <Body>
              Find out more about our sustainability goals and achievements in
              our annual report
            </Body>
            <CallToActionBtn externalBtnLink={true} btnLink={routes.CSR_REPORT}>
              Read our CSR report
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>Our sustainability highlights across Europe</Heading2>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.ZOOM_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardTitle: (
                    <>
                      <GlobeIcon />
                      <Heading2>
                        <AccentMint>350 cities green-delivered</AccentMint>
                      </Heading2>
                    </>
                  ),
                  cardText: <Body>by 2025</Body>,
                },
                {
                  cardTitle: (
                    <>
                      <ElectricVanIcon />
                      <Heading2>
                        <AccentMint>15,000 low-emission vehicles</AccentMint>
                      </Heading2>
                    </>
                  ),
                  cardText: <Body>by 2025</Body>,
                },
                {
                  cardTitle: (
                    <>
                      <ParcelIcon />
                      <Heading2>
                        <AccentMint>-30% of CO2 per parcel</AccentMint>
                      </Heading2>
                    </>
                  ),
                  cardText: <Body>by 2025</Body>,
                },
                {
                  cardTitle: (
                    <>
                      <HeartIcon />
                      <Heading2 classes="pt-2">
                        <AccentMint>81%</AccentMint>
                      </Heading2>
                    </>
                  ),
                  cardText: (
                    <Body classes="pb-5">
                      employees satisfied with their working environment in 2021
                    </Body>
                  ),
                },
                {
                  cardTitle: (
                    <>
                      <PeopleIcon />
                      <Heading2 classes="pt-2">
                        <AccentMint> {">"}30,000</AccentMint>
                      </Heading2>
                    </>
                  ),
                  cardText: (
                    <Body classes="pb-5">
                      employees engaged in closer communities initiatives in
                      2021
                    </Body>
                  ),
                },
                {
                  cardTitle: (
                    <>
                      <BatteryIcon />
                      <Heading2 classes="pt-2">
                        <AccentMint>84.7%</AccentMint>
                      </Heading2>
                    </>
                  ),
                  cardText: (
                    <Body classes="pb-5">
                      of electricity consumed came from renewable sources in
                      2021
                    </Body>
                  ),
                },
              ]}
              columnSpan={4}
            />

            <Col md={12}>
              <Heading3>
                <AccentMint>
                  More sustainable delivery in 350 European cities by 2025
                </AccentMint>
                <br />
                Discover our interactive green map
              </Heading3>
              <CallToActionBtn
                btnLink="https://www.geopost.com/wp-content/uploads/sites/286/2022/06/DPDgroup-_sustainable-delivery-map.pdf"
                externalBtnLink={true}
              >
                Discover now
              </CallToActionBtn>
            </Col>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <Heading4>
            Whitepaper
            <Heading2>
              <AccentMint>Sustainability in times of uncertainty</AccentMint>
            </Heading2>
          </Heading4>
          <Body>
            Geopost and Euromonitor International conducted a research to assess
            how companies and consumers perceive sustainability in times of
            uncertainty.
          </Body>
          <Body>Two main insights come out of this research:</Body>
          <BulletList
            listItems={[
              {
                text: (
                  <Body>
                    Despite geopolitical and economic pressures, companies
                    continue to invest in sustainability.
                  </Body>
                ),
              },
              {
                text: (
                  <Body>
                    Consumers pay attention to sustainability in their buying
                    process but are also considering other factors, such as
                    affordability and immediate availability.
                  </Body>
                ),
              },
            ]}
          />
          <Body>Find out more:</Body>
          <div className="text-center">
            <CallToActionBtn
              btnLink="https://www.geopost.com/wp-content/uploads/sites/286/2023/05/Geopost_Euromonitor_White-paper.pdf"
              externalBtnLink={true}
            >
              Download our whitepaper
            </CallToActionBtn>
          </div>
        </Col>
      </Section>

      <Section bgImg={section2Bg} style={{ height: "515px" }}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Find out more about how we aim to be the UK&apos;s most
              sustainable delivery company
            </Heading2>
            <CallToActionBtn
              btnLink={routes.GREEN_WEBSITE}
              externalBtnLink={true}
            >
              Visit Our Green Website
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
