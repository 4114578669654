import { useCallback, useEffect, useRef, useState } from "react";

import { isMobile } from "react-device-detect";
import Map, { Layer, Marker, Source } from "react-map-gl";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { fetchCountryMapboxData, mapBoxSourceUrl } from "../../../apis/api";
import { ReactComponent as OriginPin } from "../../../assets/icons/world-map-pin-black.svg";
import { ReactComponent as CountryPin } from "../../../assets/icons/world-map-pin.svg";
import { CountryCard } from "../../../components/CountryCard/CountryCard";
import { CountrySideDrawer } from "../../../components/CountrySideDrawer/CountrySideDrawer";
import { MapLine } from "../../../components/MapLine/MapLine";
import { MapPin } from "../../../components/MapPin/MapPin";
import { WORLD_MAP } from "../../../constants/actions";
import {
  DESTINATION_LAYER_COLOR,
  LONDON_COORDINATES,
  ORIGIN_LAYER_COLOR,
  STRATEGIC_LOCATION_LAYER_COLOR,
} from "../../../constants/constants";
import { useFetchCountryMapboxData } from "../../../hooks/useFetchCountryMapboxData";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import { useStore } from "../../../store/store";
import variables from "../../../styles/colors.scss";

export const WorldMap = () => {
  const [hasMapLoaded, setHasMapLoaded] = useState(false);
  const [isoCode, setIsoCode] = useState(null);
  const [countryErrorIsoCode, setCountryErrorIsoCode] = useState(null);
  const [depotPins, setDepotPins] = useState([]);
  const [inputFocused, setInputFocused] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(120);
  const mapRef = useRef();
  const {
    country,
    resetCountry,
    locations,
    errorCountry,
    shouldFly,
    setShouldFly,
    isCountrySideDrawerOpen,
  } = useStore();
  const { coordinates, countryIsoCode } = useFetchCountryMapboxData(country);
  const isMobileDevice = useMediaQuery("(max-width: 480px)");

  useEffect(() => {
    const header = document.querySelector(".Header_mydpd-header__JdzfP");

    if (header) {
      setHeaderHeight(header.clientHeight);
    }
  }, []);

  useEffect(() => {
    const mapObj = mapRef.current;

    if (country || isCountrySideDrawerOpen) {
      if (mapObj) {
        mapObj.resize();
      }
    }
  }, [country, isCountrySideDrawerOpen]);

  useEffect(() => {
    // Only show the strategic locations which have a description for sidedrawer
    const allLocations = [...locations.strategicLocations].filter(
      ({ description }) => description !== ""
    );

    const formattedLocations = allLocations.map(
      ({ address, latLong, country, description, link, depotName }) => {
        const coordinates = latLong
          .split(", ")
          .map(coordinate => parseFloat(coordinate));

        return {
          place: address,
          coordinates,
          country,
          location: {
            country,
            address,
            description,
            latLong,
            link,
            depotName,
          },
        };
      }
    );

    setDepotPins([...formattedLocations]);

    return () => {
      resetCountry();
    };
  }, [locations]);

  useEffect(() => {
    const fetchCountryDetails = async () => {
      if (errorCountry && shouldFly) {
        const mapObj = mapRef.current;

        if (mapObj) {
          const { coordinates, countryIsoCode } = await fetchCountryMapboxData(
            errorCountry
          );

          if (coordinates && countryIsoCode) {
            mapObj.flyTo({
              center: coordinates,
              zoom: 2.5,
              bearing: 0,
              speed: 0.6,
              curve: 1,
              easing: t => t,
              essential: true,
            });

            setCountryErrorIsoCode(countryIsoCode);
          }

          setShouldFly(false);
        }
      } else {
        setCountryErrorIsoCode(null);
      }
    };

    fetchCountryDetails();
  }, [errorCountry]);

  useEffect(() => {
    const flyToCountry = async () => {
      let flyToCoordinates = LONDON_COORDINATES;
      const mapObj = mapRef.current;

      if (country) {
        const { coordinates } = await fetchCountryMapboxData(
          country.marketInfo.countryName
        );
        flyToCoordinates = coordinates;
      }

      if (mapObj) {
        mapObj.flyTo({
          center: flyToCoordinates,
          zoom: isMobileDevice ? 2.5 : 3.5,
          bearing: 0,
          speed: 0.6,
          curve: 1,
          easing: t => t,
          essential: true,
        });
      }
    };

    flyToCountry();
  }, [country]);

  const onMapLoad = useCallback(async () => {
    const mapObj = mapRef.current;
    let flyToObj = {};

    if (mapObj) {
      if (errorCountry) {
        const { coordinates, countryIsoCode } = await fetchCountryMapboxData(
          errorCountry
        );

        if (coordinates && countryIsoCode) {
          flyToObj = {
            center: coordinates,
            zoom: 2.5,
            bearing: 0,
            speed: 0.6,
            curve: 1,
            easing: t => t,
            essential: true,
          };
          setCountryErrorIsoCode(countryIsoCode);
        }
      } else {
        let flyToCoordinates = LONDON_COORDINATES;

        if (country) {
          const { coordinates } = await fetchCountryMapboxData(
            country.marketInfo.countryName
          );
          flyToCoordinates = coordinates;
        }

        flyToObj = {
          center: flyToCoordinates,
          zoom: isMobileDevice ? 2.5 : 4.5,
          bearing: 0,
          speed: 0.6,
          curve: 1,
          easing: t => t,
          essential: true,
        };
      }

      mapObj.flyTo(flyToObj);
    }
  }, []);

  return (
    <Trackable interfaceId={WORLD_MAP.INTERFACE_ID} loadId={WORLD_MAP.ON_LOAD}>
      <div
        style={{
          height:
            hasMapLoaded &&
            ((isMobileDevice && country) ||
              (isMobileDevice && !country && isCountrySideDrawerOpen))
              ? "100%"
              : !country && !isCountrySideDrawerOpen
              ? `calc(100vh - ${headerHeight}px)`
              : "100vh",
        }}
      >
        <Map
          ref={mapRef}
          onLoad={onMapLoad}
          initialViewState={{ zoom: 1 }}
          mapStyle="mapbox://styles/mapbox/dark-v10"
          mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
          antialias={true}
          projection="globe"
          onMoveEnd={() => setHasMapLoaded(true)}
          maxZoom={5.99}
          style={{
            height: "100%",
            pointerEvents:
              inputFocused && isMobile && !country && !isCountrySideDrawerOpen
                ? "none"
                : "all",
            backgroundColor: variables.darkShade1,
          }}
          fog={{
            range: [0, 0], // Disables fog effect on the earth's edges
            "horizon-blend": 0, // Disables atmospheric glow around the earth
            color: "rgba(0, 0, 0, 0)",

            "high-color": "rgba(0, 0, 0, 0)",
            "star-intensity": 0,
            "space-color": variables.darkShade1,
          }}
        >
          {hasMapLoaded && (
            <>
              {isoCode && (
                <Source type="vector" url={mapBoxSourceUrl}>
                  <Layer
                    {...{
                      id: "strategic-layer",
                      source: "country-boundaries",
                      "source-layer": "country_boundaries",
                      type: "fill",
                      filter: ["==", ["get", "iso_3166_1"], isoCode],
                      paint: {
                        "fill-color": STRATEGIC_LOCATION_LAYER_COLOR,
                      },
                    }}
                  />
                </Source>
              )}

              {depotPins.map(
                ({ place, coordinates, country, location }, index) => (
                  <MapPin
                    key={`${index} - ${place} - ${coordinates} - ${country}`}
                    place={place}
                    coordinates={coordinates}
                    country={country}
                    setIsoCode={setIsoCode}
                    location={location}
                  />
                )
              )}

              {country && coordinates.length !== 0 && (
                <>
                  <MapLine />
                  <Source type="vector" url={mapBoxSourceUrl}>
                    <Layer
                      {...{
                        id: "origin-layer",
                        source: "country-boundaries",
                        "source-layer": "country_boundaries",
                        type: "fill",
                        filter: ["==", ["get", "iso_3166_1"], "GB"],
                        paint: {
                          "fill-color": ORIGIN_LAYER_COLOR,
                        },
                      }}
                    />
                    <Layer
                      {...{
                        id: "country-layer",
                        source: "country-boundaries",
                        "source-layer": "country_boundaries",
                        type: "fill",
                        filter: ["==", ["get", "iso_3166_1"], countryIsoCode],
                        paint: {
                          "fill-color": DESTINATION_LAYER_COLOR,
                        },
                      }}
                    />
                  </Source>

                  <Marker
                    longitude={LONDON_COORDINATES[0]}
                    latitude={LONDON_COORDINATES[1]}
                    pitchAlignment="viewport"
                    rotationAlignment="viewport"
                  >
                    <OriginPin />
                  </Marker>
                  <Marker
                    longitude={coordinates[0]}
                    latitude={coordinates[1]}
                    pitchAlignment="viewport"
                    rotationAlignment="viewport"
                  >
                    <CountryPin />
                  </Marker>
                </>
              )}

              {countryErrorIsoCode && (
                <Source type="vector" url={mapBoxSourceUrl}>
                  <Layer
                    {...{
                      id: "country-layer",
                      source: "country-boundaries",
                      "source-layer": "country_boundaries",
                      type: "fill",
                      filter: [
                        "==",
                        ["get", "iso_3166_1"],
                        countryErrorIsoCode,
                      ],
                      paint: {
                        "fill-color": DESTINATION_LAYER_COLOR,
                      },
                    }}
                  />
                </Source>
              )}
            </>
          )}

          <CountrySideDrawer />
          <CountryCard
            mapLoaded={hasMapLoaded}
            inputFocused={inputFocused}
            setInputFocused={setInputFocused}
          />
        </Map>
      </div>
    </Trackable>
  );
};
