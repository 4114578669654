import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import { ReactComponent as DutyIcon } from "../../assets/icons/iconDuty.svg";
import { ReactComponent as VatIcon } from "../../assets/icons/iconVAT.svg";
import { ANIMATIONS } from "../../constants/constants";
import { CountryIcons } from "../CountryIcons/CountryIcons";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import styles from "./CountryDutiesTaxes.module.scss";

export const CountryDutiesTaxes = () => {
  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div>
        <Container>
          <Col md={12}>
            <Heading3>
              When sending parcels to an international destination, the goods
              may be subject to Duties and Taxes
            </Heading3>
          </Col>

          <Col md={12}>
            <Body>
              The term ‘duties and taxes’ refers to two separate charges that
              are levied by customs authorities in the receiving country. These
              charges vary by destination depending on the type of goods you are
              sending, where they were made and their customs value.
            </Body>
          </Col>

          <CountryIcons
            icons={[
              {
                icon: (
                  <div className={styles.icon}>
                    <DutyIcon />
                  </div>
                ),
                title: "",
                body: "Duty is often referred to as import duty and is based on the trade tariff negotiated by trade partners (Governments). This is a tax for goods entering another country.",
              },
              {
                icon: (
                  <div className={styles.icon}>
                    <VatIcon />
                  </div>
                ),
                title: "",
                body: "VAT is a consumption tax (another form of tax) and is charged on all purchases. VAT is charged based on the total invoice value declared in the customs data you have supplied and varies by destination. Therefore, It’s important to know that the VAT rate applicable will depend on which destination the goods have been imported to.",
              },
            ]}
          />

          <Col md={12}>
            <Body>
              If the value of the products in a consignment declared to customs
              is below the de minimis threshold of the importing country, then
              duties and taxes may not apply.
            </Body>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
};
