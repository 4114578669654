import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import section5Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import section4Bg from "../../../../assets/pages/international/eu-vat-changes/section-4-bg.png";
import taxDeskImg from "../../../../assets/pages/international/export-fundamentals/taxDesk-card-img.svg";
import jumbotronBg from "../../../../assets/pages/international/shipping-to-eu/jumbotron-bg.png";
import { BulletList } from "../../../../components/BulletList/BulletList";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { DownloadBtn } from "../../../../components/DownloadBtn/DownloadBtn";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading1 } from "../../../../components/Typography/Heading1";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import { Heading5 } from "../../../../components/Typography/Heading5";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import { SHIPPING_TO_EU } from "../../../../router/constants";
import * as router from "../../../../router/constants";
import variables from "../../../../styles/colors.scss";

export const EuVatChanges = () => {
  return (
    <>
      <Section bgImg={jumbotronBg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader
              headerText="EU VAT Changes"
              anchorLinkText="Shipping to the European Union"
              anchorLinkHref={SHIPPING_TO_EU}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              On 1 July 2021 the European Union introduced a number of
              wide-ranging VAT reforms and changes covering both the distance
              sales of goods and B2C services.
            </Heading2>
            <Heading3>These changes included:</Heading3>
            <CardContainer
              cards={[
                {
                  cardText: (
                    <BulletList
                      listItems={[
                        {
                          text: "The €22 value added tax (VAT) exemption on goods imported to the EU is removed. This means that all goods regardless of value are subject to VAT",
                        },
                        {
                          text: "The introduction of the Import One Stop Shop (IOSS) to facilitate and simplify the declaration and payment of VAT for distance sales of imported goods with a value not exceeding €150",
                        },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>What is the Import One Stop Shop (IOSS)?</Heading3>
            <Body>
              The Import One Stop Shop (IOSS) is the electronic portal
              businesses can use to comply with their VAT e-commerce obligations
              on distance sales of imported goods to the EU from the UK. By
              registering for IOSS and collecting VAT at the point of sale, no
              additional VAT will be applied and charged when the parcels arrive
              at the EU customs facility, speeding up the release into free
              circulation of the goods to your customer.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>What does having an IOSS registration involve?</Heading3>
            <Heading4>You will need to:</Heading4>
            <CardContainer
              cards={[
                {
                  cardText: (
                    <BulletList
                      listItems={[
                        {
                          text: "Provide your IOSS registration number in the electronic customs data file you submit to DPD",
                        },
                        {
                          text: "Charge and collect the correct amount of VAT at the time of purchase to the buyer (i.e. the correct VAT amount for the Member State in which your customer is located)",
                        },
                        {
                          text: "Apply this only to goods with a purchase value up to €150",
                        },
                        {
                          text: "Ensure you comply with the accounting obligations of the scheme.",
                        },
                      ]}
                    />
                  ),
                },
              ]}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Where can I find out more about these changes?</Heading3>
            <Body>
              The EU has published some useful guidance on these changes that
              you can access below.
            </Body>
            <ExternalLinkBtn btnLink={router.EU_GUIDANCE}>
              EU Guidance
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section4Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              We&apos;ve also produced this useful guide to help you understand
              the changes
            </Heading1>
            <DownloadBtn downloadLink="https://e.marketing.dpd.co.uk/l/269632/2022-05-10/y5zlg/269632/1652180647CjPmGj8i/EU_Vat_Doc__1_.pdf">
              DPD EU Guidance (1.43mb)
            </DownloadBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section5Bg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              Registering for the IOSS is beneficial for sellers, buyers and
              carriers as the IOSS facilitates the declaration, payment and
              collection of VAT
            </Heading3>
            <Heading5>
              Geopost is aware of the administrative complexity and challenge of
              this new EU VAT reform especially when an intermediary is
              mandatory for IOSS registration
            </Heading5>
            <Heading4>
              To help its customers to find an intermediary, Geopost has
              carefully selected a number of reliable partners who can assist
              you with simple registration and intermediary services
            </Heading4>
            <CardContainer
              cards={[
                {
                  cardImg: taxDeskImg,
                  cardBtnTxt: "Visit Tax Desk",
                  cardBtnLink: router.TAX_DESK,
                  externalLink: true,
                },
              ]}
              imgHeight={"150px"}
              imgWidth={"232px"}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </>
  );
};
