export const ACCOUNT_DETAILS = {
  INTERFACE_ID: "vokKgYGGAqACmEq0",
  ON_CLICK_TEXT_FIELD: "XFoqgYGGAqACmEuW",
  ON_CLICK_CLOSE: "mloqgYGGAqACmEui",
  ON_LOAD: "gFoqgYGGAqACmEuB",
  ON_CLICK_NEXT: "BVoqgYGGAqACmEvA",
  ON_CLICK_BACK: "9VAagYGGAqACmEzE",
};

export const AIR_CLASSIC = {
  INTERFACE_ID: "k8rJAYGGAqACkbZX",
  ON_LOAD: "JMapAYGGAqACkbcL",
};

export const AIR_EXPRESS = {
  INTERFACE_ID: "jd1FAYGGAqACkb0g",
  ON_LOAD: "mCKbAYGGAqACkcZ4",
};

export const CONTACT_DETAILS = {
  INTERFACE_ID: "1s35gYGGAqACmLN8",
  ON_CLICK_BACK: "1A1FgYGGAqACmLU2",
  ON_CLICK_TEXT_FIELD: "13algYGGAqACmLXR",
  ON_CLICK_CLOSE: "0A1FgYGGAqACmLUl",
  ON_LOAD: "h_VFgYGGAqACmLUR",
  ON_CLICK_NEXT: "BVoqgYGGAqACmEvA",
  ON_CLICK_CHECKBOX: "8qblgYGGAqACmLZk",
  ON_CLICK_SUBMIT: "I581gYGGAqACmLsw",
};

export const CONTACT_US = {
  INTERFACE_ID: "mB6egYGGAqACmAHn",
  ON_CLICK_BACK: "xC.BgYGGAqACmAwi",
  ON_CLICK_CLOSE: "BiYBgYGGAqACmAsn",
  ON_LOAD: "38YBgYGGAqACmAsF",
};

export const CUSTOMER_ENQUIRY = {
  INTERFACE_ID: "6Ij6gYGGAqACmE33",
  ON_CLICK_NEXT: "7JC2gYGGAqACmOIP",
  ON_LOAD: "n9fmgYGGAqACmFAp",
  ON_CLICK_CLOSE: "7DfmgYGGAqACmFBD",
  ON_CLICK_TEXT_FIELD: "nrOugYGGAqACmOex",
  ON_CLICK_RADIO_BUTTON: "My_WgYGGAqACmODb",
};

export const DPD_CLASSIC = {
  INTERFACE_ID: "vBYiAYGGAqACkacU",
  ON_CLICK_PLAY_VIDEO: "SgPiAYGGAqACkaec",
  ON_LOAD: "dAPiAYGGAqACkaeR",
};

export const DPD_DIRECT = {
  INTERFACE_ID: "8VoUAYGGAqACkZtY",
  ON_CLICK_PLAY_VIDEO: "28GCAYGGAqACkaOF",
  ON_LOAD: "VH1UAYGGAqACkZ2g",
};

export const EXPORT_FUNDAMENTALS = {
  INTERFACE_ID: "4NTH8YGGAqACmDxB",
  ON_HOVER_INTERNATIONAL_FLAG: "WDN5CYGGAqACmEeY",
  ON_CLICK_TRADE_AGREEMENT: "Bgpf8YGGAqACmEb.",
  ON_CLICK_GET_STARTED: "nNAf8YGGAqACmEa3",
  ON_CLICK_FIND_OUT_WHAT: "9qS5CYGGAqACmEdg",
  ON_CLICK_GOVERNMENT_LINK: ".yyn8YGGAqACmD45",
  ON_CLICK_LEARN_HOW_TO: "6Nov8YGGAqACmEZU",
  ON_CLICK_VISIT_OUR_FAQS: "yTbFCYGGAqACmEfc",
  ON_LOAD: "H8yn8YGGAqACmD25",
  ON_CLICK_DISCOVER_OUR_TIPS: "p4Fv8YGGAqACmEaS",
};

export const FAQS = {
  INTERFACE_ID: "2k8VCYGGAqACmEv8",
};

export const FOOTER = {
  ON_CLICK_EMAIL_ICON: "nYbRhoGGAqACkaj8",
  ON_LOAD: "5.SwNoGGAqACkXkh",
  ON_CLICK_GET_IN_TOUCH: "MlCehoGGAqACkaWq",
};

export const GET_IN_TOUCH = {
  INTERFACE_ID: "6Wh8gYGGAqACmDwI",
  ON_CLICK_RADIO_BUTTON: "tZaigYGGAqACmEfs",
  ON_LOAD: "g9gCgYGGAqACmEbn",
  ON_CLICK_NEXT: "DMQygYGGAqACmEl0",
  ON_CLICK_CLOSE: ".PFSgYGGAqACmEjn",
};

export const HOME = {
  INTERFACE_ID: "p.HKhoGGAqACkY_R",
  ON_LOAD: "6yFuhoGGAqACkaIQ",
  ON_CLICK_SEARCH: "gg8L1oGGAqACkTNG",
};

export const INNOVATIONS = {
  INTERFACE_ID: "v5CHAYGGAqACkcj_",
  ON_LOAD: "ewbHAYGGAqACkcmI",
};

export const INSIGHT_INFO = {
  INTERFACE_ID: "qdxBoYGGAqACmBFc",
  ON_LOAD: "2ibgYYGGAqACmBwJ",
  ON_CLICK_FIND_OUT_MORE_LATEST: "dFDUYYGGAqACmCeX",
  ON_CLICK_FIND_OUT_MORE_PARTNERS: "klB0YYGGAqACmCfx",
  ON_CLICK_FIND_OUT_MORE_INSIGHT: "8abgYYGGAqACmBx7",
  ON_CLICK_ACCESS_LEARNING_LIBRARY: "LTCUYYGGAqACmCdP",
};

export const INTERNATIONAL_INSIGHTS = {
  INTERFACE_ID: "XDEyYYGGAqACmDUm",
  ON_CLICK_WHITE_PAPER: "SckRYYGGAqACmD3h",
  ON_CLICK_ESHOPPER_TOOL: "IsqhYYGGAqACmD19",
  ON_LOAD: "3mUKYYGGAqACmDYE",
  ON_CLICK_DPD_BROCHURE: "7PE.YYGGAqACmD0o",
  ON_CLICK_PLAY_VIDEO: "NSaeYYGGAqACmDzJ",
};

export const MENU = {
  ON_CLICK_ABOUT_US: "3rZANoGGAqACkRrs",
  ON_LOAD: "HQMf1oGGAqACkQ.d",
  ON_CLICK_SUPPORT: "3xBgNoGGAqACkR6V",
  ON_CLICK_DPD_IMAGE: "fTwANoGGAqACkRpQ",
  ON_CLICK_HOME: "WFWANoGGAqACkRqu",
  ON_CLICK_INSIGHT_INFO: "v1CgNoGGAqACkR5K",
  ON_CLICK_OUR_SERVICES: "GsfANoGGAqACkR4O",
};

export const NEW_CUSTOMER_ENQUIRY = {
  INTERFACE_ID: "IilSoYGGAqACmFnv",
  ON_CLICK_TEXT_FIELD: "fJayoYGGAqACmF1h",
  ON_LOAD: "yBayoYGGAqACmFy0",
  ON_CLICK_RADIO_BUTTON: "BRayoYGGAqACmF0B",
  ON_CLICK_BACK: "KhayoYGGAqACmFzW",
  ON_CLICK_NEXT: "0JayoYGGAqACmF1E",
  ON_CLICK_CLOSE: "bBayoYGGAqACmFzF",
};

export const INSIGHT_INFO_BLOG = {
  INTERFACE_ID: "NVhx8YGGAqACmEEg",
  ON_CLICK_GROUP_FEED_FILTER_DATE: "VqVT8YGGAqACmMd.",
  ON_CLICK_GROUP_FEED_FILTER_COUNTRY: "y3Lj8YGGAqACmMcE",
  ON_CLICK_GROUP_FEED_FILTER_COUNTRY_LIST: "dSij8YGGAqACmMbA",
  ON_CLICK_GROUP_FEED_FILTER_CATEGORY: "a9Rz8YGGAqACmMgA",
  ON_CLICK_INTERNATIONAL_UPDATES_FILTER_COUNTRY: "suvV8YGGAqACmLpj",
  ON_CLICK_INTERNATIONAL_UPDATES_FILTER_COUNTRY_LIST: "DX6d8YGGAqACmMFs",
  ON_CLICK_GROUP_FEED_NEWS: "X5IL8YGGAqACmMgz",
  ON_CLICK_INTERNATIONAL_UPDATES_ISSUE: "n5jD8YGGAqACmMZq",
  ON_CLICK_INTERNATIONAL_UPDATES_FILTER_DATE: "XvK98YGGAqACmMJ9",
  ON_CLICK_INTERNATIONAL_UPDATES_NEXT: "Az398YGGAqACmMYU",
  ON_CLICK_GROUP_FEED_NEXT: "LmGL8YGGAqACmMhn",
  ON_CLICK_GROUP_FEED_FILTER_CATEGORY_LIST: "LNcz8YGGAqACmMe.",
  ON_CLICK_DELIVERY_SCEDULE: "e1vL8YGGAqACmMo3.",
  ON_LOAD: "XiAJ8YGGAqACmEO9",
  ON_CLICK_INTERNATIONAL_UPDATES_FILTER_MONTH_YEAR: "aQ7d8YGGAqACmMH6",
  ON_CLICK_GROUP_FEED_FILTER_MONTH_YEAR: "VZST8YGGAqACmMdA",
};

export const OUR_PARTNERS = {
  INTERFACE_ID: "AExMYYGGAqACmCsr",
  ON_LOAD: "tuZiYYGGAqACmDDU",
  ON_CLICK_EXPORT_SUPPORT: "nPaSYYGGAqACmDHd",
  ON_CLICK_REGISTER_FOR_EVENTS: "yeZiYYGGAqACmDDl",
};

export const OUR_SERVICES = {
  INTERFACE_ID: "IQvDNoGGAqACkTWo",
  ON_CLICK_DISCOVER_MORE: "p97EAYGGAqACkenv",
  ON_CLICK_LEARN_MORE_DPD_CLASSIC: "FEE4AYGGAqACkeiF",
  ON_CLICK_LEARN_MORE_DPD_DIRECT: "frb4AYGGAqACkekz",
  ON_CLICK_SEE_WHATS_NEW: "cEMkAYGGAqACkeot",
  ON_CLICK_LEARN_MORE_AIR_CLASSIC: "3e4EAYGGAqACkelw",
  ON_CLICK_LEARN_MORE_AIR_EXPRESS: "uxhEAYGGAqACkemx",
  ON_LOAD: "EHJYAYGGAqACkedA",
};

export const SUB_MENU = {
  ON_LOAD_ABOUT_US: "I2JENoGGAqACkQKD",
  ON_CLICK_WHO_WE_ARE: "wOJENoGGAqACkQKV",
  ON_CLICK_SUSTAINABILITY: "5OJENoGGAqACkQKk",
  ON_CLICK_OUR_PARTNERS: "SS.oYYGGAqACmB5N",
  ON_CLICK_INTERNATIONAL_INSIGHTS: "OoQYYYGGAqACmB8t",
  ON_CLICK_EXPORT_FUNDAMENTALS: "g55YYYGGAqACmB.u",
  ON_LOAD_INSIGHT_INFO: "wC.oYYGGAqACmB4M",
  ON_CLICK_BLOG: "9mqYYYGGAqACmB9v",
  ON_CLICK_AIR_EXPRESS: "7hnzNoGGAqACkTl2",
  ON_LOAD_OUR_SERVICES: "FWWTNoGGAqACkTgh",
  ON_CLICK_WORLD_MAP: "aI0LNoGGAqACkTmt",
  ON_CLICK_DPD_CLASSIC: "hpszNoGGAqACkTj7",
  ON_CLICK_INNOVATIONS: "fN2LNoGGAqACkTnj",
  ON_CLICK_AIR_CLASSIC: "xdpzNoGGAqACkTk5",
  ON_CLICK_DPD_DIRECT: "lTjTNoGGAqACkTi4",
};

export const SUPPORT_FAQS = {
  ON_CLICK_SELECT_TOPIC_DROPDOWN: "qUajCYGGAqACmF1M",
  ON_CLICK_TOPIC_QUESTION: "BLArCYGGAqACmGlW",
  ON_CLICK_TOPIC: "fMaLCYGGAqACmGXM",
  ON_LOAD: "FEajCYGGAqACmF0i",
  ON_CLICK_FAQS: "A0rrCYGGAqACmGn9",
};

export const SUSTAINABILITY = {
  INTERFACE_ID: "mAAFNoGGAqACkSWu",
  ON_CLICK_READ_OUR_CSR_REPORT: "JNbdNoGGAqACkS8Q",
  ON_LOAD: "bJ7lNoGGAqACkSnw",
  ON_CLICK_VISIT_OUR_GREEN_WEBSITE: "EVJ9NoGGAqACkS9u",
  ON_CLICK_PLAY_VIDEO: "2p7lNoGGAqACkSn6",
};

export const THANK_YOU = {
  INTERFACE_ID: "0MUNgYGGAqACmL6q",
  ON_LOAD: "1buNgYGGAqACmMAJ",
  ON_CLICK_CLOSE: "M7uNgYGGAqACmMAV",
};

export const WHO_WE_ARE = {
  INTERFACE_ID: "JcJoNoGGAqACkX4c",
  ON_LOAD: "Yh90NoGGAqACkQyn",
  ON_CLICK_PLAY_VIDEO: "2lXZNoGGAqACkSRV",
};

export const WORLD_MAP = {
  INTERFACE_ID: "dmM3AYGGAqACkTtT",
  ON_CLICK_EXPORT_INFO_SHIP_TO_EU: "_Hc0gYGGAqACmDiV",
  ON_CLICK_EXPORT_INFO: "y5EUgYGGAqACmDfP",
  ON_CLICK_DELIVERY_OPTIONS_ECONOMY: "sh0kgYGGAqACmDcM",
  ON_CLICK_DELIVERY_OPTIONS_CLASSIC: "ctbEgYGGAqACmDbG",
  ON_LOAD: "Mvd3AYGGAqACkT0B",
  ON_CLICK_MARKET_INFO: "zjiMgYGGAqACmDlI",
  ON_CLICK_MARKET_INFO_ONLINE_SELLERS: "vygsgYGGAqACmDoq",
  ON_CLICK_DPD_ICON: "uNLggYGGAqACmDBA",
  ON_CLICK_SEARCH_BOX: ".8OwgYGGAqACmDKF",
  ON_CLICK_DELIVERY_OPTIONS: "yyVkgYGGAqACmDdZ",
  ON_CLICK_EXPORT_INFO_CUSTOMS_DATA: "DCVUgYGGAqACmDge",
  ON_CLICK_CHECKOUT_OUR_SERVICES: "lQ3YgYGGAqACmDUL",
  ON_CLICK_DELIVERY_OPTIONS_FASTEST: "WEOEgYGGAqACmDZT",
  ON_CLICK_CLOSE: "Yl4wgYGGAqACmDIZ",
  ON_CLICK_MARKET_INFO_GET_IN_TOUCH: "ll_sgYGGAqACmDrA",
};
