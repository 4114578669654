import classNames from "classnames";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import styles from "./ContactUsFormBody.module.scss";

export const ContactUsFormBody = ({ title, children }) => {
  return (
    <Modal.Body
      className={classNames("border-0 py-1", styles["contact-us-form-body"])}
    >
      <div className="px-4">
        <p className={styles["contact-us-form-body-text"]}>{title}</p>
        {children}
      </div>
    </Modal.Body>
  );
};

ContactUsFormBody.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};
