import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import { ReactComponent as InFlightIcon } from "../../assets/icons/iconInFlight.svg";
import { ReactComponent as PredictIcon } from "../../assets/icons/iconPredict.svg";
import { ReactComponent as LiveTrackingIcon } from "../../assets/icons/iconTracking.svg";
import { ANIMATIONS } from "../../constants/constants";
import { CountryIcons } from "../CountryIcons/CountryIcons";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading4 } from "../Typography/Heading4";

export const DpdClassicDeliveryOption = () => {
  const icons = [
    {
      icon: <PredictIcon />,
      title: "Predict",
      body: "Lets your customer know the exact hour we will arrive",
    },
    {
      icon: <LiveTrackingIcon />,
      title: "Live tracking",
      body: "Lets your customer follow the driver to your door",
    },
    {
      icon: <InFlightIcon />,
      title: "In flight options",
      body: "Let your customer change the delivery time and place",
    },
  ];

  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container className={"pt-3 px-3"}>
          <Col md={12}>
            <Heading3>DPD Classic</Heading3>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              Our Classic service gives you access to the largest parcel
              delivery network in Europe.
            </Heading4>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Our solution combines innovative technology and local knowledge to
              provide a flexible and user-friendly service for both shippers and
              shoppers. With our industry-leading Predict service, we are
              setting a new standard for convenience by keeping your customers
              updated on with their deliveries.
            </Body>
          </Col>

          <CountryIcons icons={icons} />
        </Container>
      </div>
    </ScrollAnimation>
  );
};
