import { useEffect, useRef, useState } from "react";

import Fuse from "fuse.js";
import { useSearchParams } from "react-router-dom";
import Typo from "typo-js";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { SearchFilters } from "../../components/SearchFilters/SearchFilters";
import { SearchListItem } from "../../components/SearchListItem/SearchListItem";
import { SearchPagination } from "../../components/SearchPagination/SearchPagination";
import { DPD_WORD_LIST, FILTERS } from "../../constants/constants";
import { SEARCH_RESULTS } from "../../constants/strings";
import searchIndexJson from "../../searchIndex.json";
import styles from "./Search.module.scss";
import { useWindowSize } from "../../hooks/useWindowSize";
import { SearchFiltersMobile } from "../../components/SearchFilters/SearchFiltersMobile";
import { GetInTouch } from "../../components/GetInTouch/GetInTouch";

export const Search = () => {
  const [searchIndex, setSearchIndex] = useState([]);
  const [searchParams] = useSearchParams();
  const [fuzzySearchList, setFuzzySearchList] = useState([]);
  const [activeFilter, setActiveFilter] = useState(FILTERS.ALL);
  const [spellChecked, setSpellChecked] = useState([]);
  const [misspelled, setMisspelled] = useState(false);
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const globalList = useRef(null);
  const windowSize = useWindowSize().width;

  const itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = fuzzySearchList.slice(itemOffset, endOffset);

  const searchQuery = searchParams.get("query");
  const dictionary = new Typo("en_US", false, false, {
    dictionaryPath: "dictionaries",
  });

  const filterList = (list, filterValue) => {
    const filtered = list.filter(({ item: { path } }) => {
      if (path.includes(filterValue)) {
        return true;
      }
      return false;
    });

    return filtered.length;
  };

  const calculateFilterLength = () => {
    const { ABOUT, SERVICES, INSIGHT, SUPPORT } = FILTERS;
    if (globalList.current && globalList.current.length > 0) {
      return {
        all: globalList.current.length,
        about: filterList(globalList.current, ABOUT),
        services: filterList(globalList.current, SERVICES),
        international: filterList(globalList.current, INSIGHT),
        support: filterList(globalList.current, SUPPORT),
      };
    }
    return {
      all: 0,
      about: 0,
      services: 0,
      international: 0,
      support: 0,
    };
  };

  useEffect(() => {
    setSearchIndex(searchIndexJson);
  }, []);

  useEffect(() => {
    globalList.current = null;
  }, [searchQuery]);

  useEffect(() => {
    if (searchIndex && searchQuery) {
      const options = {
        includeScore: true,
        threshold: 0.3,
        ignoreLocation: true,
        keys: ["textContent"],
      };
      const fuse = new Fuse(searchIndex, options);
      const result = fuse.search(searchQuery);
      if (globalList.current === null && result.length > 0) {
        globalList.current = result;
      }
      const withFilter = result.filter(({ item: { path } }) => {
        if (activeFilter === FILTERS.ALL) {
          return true;
        }
        if (path.includes(activeFilter)) {
          return true;
        }
        return false;
      });
      setFuzzySearchList(withFilter);
    }
  }, [searchIndex, searchQuery, activeFilter]);

  useEffect(() => {
    const words = searchQuery.split(" ");
    const checked = words.map(word => {
      const wordLowercase = word.toLowerCase();
      const wordUppercase = word.toUpperCase();

      if (wordUppercase.length >= 20) return word;

      if (DPD_WORD_LIST.includes(wordLowercase)) {
        return word;
      }

      if (dictionary.check(wordLowercase) || dictionary.check(wordUppercase)) {
        return word;
      }

      return dictionary.suggest(word)[0];
    });
    setSpellChecked(checked);
    setMisspelled(checked.some((word, index) => word !== words[index]));
  }, [searchQuery]);

  return (
    <Trackable>
      <div className={styles.mainContent}>
        <div className={styles.topContainer}>
          <div className="container">
            <h1 className={`${styles.heading} heading-h1`}>Search Results</h1>
            <div className={styles.subContainer}>
              {searchQuery &&
                SEARCH_RESULTS(searchQuery, fuzzySearchList.length)}
              <div className={styles.paginationGroup}>
                {misspelled && (
                  <p className="header-h2" style={{ margin: 0 }}>
                    Did you mean{" "}
                    <a
                      className={styles.misspelledLink}
                      href={`/search?query=${spellChecked.join(" ")}`}
                    >
                      {spellChecked.join(" ").toUpperCase()}
                    </a>{" "}
                    ?
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`container ${styles.mobileContainer}`}>
          {windowSize > 992 ? (
            <div className={styles.filters}>
              <SearchFilters
                active={activeFilter}
                setActive={setActiveFilter}
                setItemOffset={setItemOffset}
                filterLengths={calculateFilterLength()}
                setCurrentPage={setCurrentPage}
              />
              <SearchPagination
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                fuzzySearchList={fuzzySearchList}
                itemsPerPage={itemsPerPage}
                endOffset={endOffset}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          ) : (
            <div className={styles.filtersMobile}>
              <SearchFiltersMobile
                active={activeFilter}
                setActive={setActiveFilter}
                setItemOffset={setItemOffset}
                filterLengths={calculateFilterLength()}
                setCurrentPage={setCurrentPage}
              />
              <SearchPagination
                itemOffset={itemOffset}
                setItemOffset={setItemOffset}
                fuzzySearchList={fuzzySearchList}
                itemsPerPage={itemsPerPage}
                endOffset={endOffset}
                isMobile
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
          {currentItems.length > 0 && (
            <div className="list-container">
              {currentItems.map(({ item }, index) => (
                <SearchListItem item={item} key={index} />
              ))}
            </div>
          )}
          <SearchPagination
            itemOffset={itemOffset}
            setItemOffset={setItemOffset}
            fuzzySearchList={fuzzySearchList}
            itemsPerPage={itemsPerPage}
            endOffset={endOffset}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </div>
      </div>
      <GetInTouch cardTitle="Couldn’t find what you were looking for?" />
    </Trackable>
  );
};
