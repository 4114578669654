import { api } from "./";

const accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

export const mapBoxSourceUrl = "mapbox://mapbox.country-boundaries-v1";

export const fetchCountryMapboxData = async country => {
  // TODO: REMOVE THIS TEMP FIX
  let countryName = country;
  if (countryName === "Republic of Ireland") countryName = "Ireland";
  if (countryName === "USA") countryName = "United States of America";

  const res = await fetch(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${countryName}.json?access_token=${accessToken}`
  );

  const data = await res.json();

  const { coordinates } = data.features[0].geometry;

  const isoCode = data.features[0].properties.short_code;
  const countryIsoCode = isoCode ? isoCode.toUpperCase() : null;

  return {
    coordinates,
    countryIsoCode,
  };
};

export const getCountryData = async country => {
  return api.get({
    path: `/country/${country.toLowerCase()}`,
  });
};

export const getStrategicLocations = async () => {
  return api.get({
    path: "/strategicLocations",
  });
};

export const getHubLocations = async () => {
  return api.get({
    path: "/hubLocations",
  });
};

export const getLeadTimeAvailabilty = async () => {
  return api.get({
    path: "/leadTime",
  });
};

export const getLeadTime = countryData => {
  return api.post({
    path: "/leadTime",
    body: countryData,
  });
};

export const postContactUsSalesForceData = async data => {
  return api.post({
    path: "/getInTouch",
    body: data,
  });
};

export const sendMessage = async (message, topicId) =>
  api.post({
    path: `/messages/send?topicId=${topicId}`,
    body: { message },
  });

export const likeMessage = async messageId =>
  api.post({
    path: `/messages/${messageId}/like`,
  });

export const dislikeMessage = async (messageId, comment) =>
  api.post({
    path: `/messages/${messageId}/dislike`,
    body: { comment },
  });

export const initialiseChat = () => api.get("/messages/initialise");

export const checkEoriNumber = eoriNumber =>
  api.get(`/checker/eori/${eoriNumber}`);

export const checkCommodityCode = commodityCode =>
  api.get(`/checker/commodity/${commodityCode}`);

export const getMapImage = parcelCode => {
  const query = new URLSearchParams({
    parcelCode,
    size: "600*200", // TODO: should be double checked
    withMarkers: true,
  });

  api.get(`/map/route?${query}`);
};
export const getParcelDetails = (parcelCode, postcode) =>
  api.get(`/parcel/${parcelCode}?postcode=${postcode}`);
