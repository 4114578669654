import classNames from "classnames";
import PropTypes from "prop-types";
import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import styles from "./NavBarLink.module.scss";

export const NavBarLink = ({ href, name, actionId = "" }) => {
  const tracker = useTracker();

  return (
    <Nav.Link
      key={href}
      className={classNames(styles["navbar-link"])}
      as={NavLink}
      to={href}
      href={href}
      onClick={() => tracker.logEvent(actionId)}
      style={({ isActive }) => ({
        color: isActive ? "white" : "rgba(255, 255, 255, 0.67)",
        textDecoration: isActive ? "solid underline white 1px" : "none",
        WebkitTextDecorationLine: isActive ? "underline" : "none",
        WebkitTextDecorationColor: isActive
          ? "white"
          : "rgba(255, 255, 255, 0.67)",
      })}
    >
      {name}
    </Nav.Link>
  );
};

NavBarLink.propTypes = {
  href: PropTypes.string,
  name: PropTypes.string,
  actionId: PropTypes.string,
};
