import { useState } from "react";

import PropTypes from "prop-types";
import Select, { components } from "react-select";

import { ReactComponent as ActiveDropdownBtn } from "../../assets/icons/active-dropdown.svg";
import { ReactComponent as ActiveVerticalDropdownBtn } from "../../assets/icons/active-vertical-dropdown.svg";
import { ReactComponent as DropdownBtn } from "../../assets/icons/dropdown.svg";

const primaryDarkColor = "rgba(80, 76, 76, 1)";
const primaryLightColor = "rgba(255, 255, 255, 0.5)";
const secondaryDarkColor = "rgba(255, 255, 255, 0.1)";

const selectStyle = props => ({
  control: (styles, { menuIsOpen }) => ({
    ...styles,
    borderColor: props.darkMode ? primaryDarkColor : "white",
    backgroundColor: props.darkMode
      ? props.forSearch
        ? secondaryDarkColor
        : primaryDarkColor
      : menuIsOpen
      ? "white"
      : primaryLightColor,
    boxShadow: "none",
  }),
  option: (styles, { isFocused, isSelected }) => {
    if (props.forSearch) {
      return {
        ...styles,
        color: isSelected ? "white" : "rgba(0, 0, 0, 0.38)",
        backgroundColor: isSelected ? "#1D2226" : "#E6E7E8",

        ":after": {
          paddingLeft: "20px",
          // eslint-disable-next-line quotes
          content: isSelected ? '" \\2713 "' : '"  "',
        },
      };
    }

    return {
      ...styles,
      color: isSelected ? "white" : isFocused ? "white" : "rgba(0, 0, 0, 0.38)",
      backgroundColor: isSelected
        ? "#1D2226"
        : isFocused
        ? "#1D2226"
        : "#E6E7E8",

      ":after": {
        paddingLeft: "20px",
        // eslint-disable-next-line quotes
        content: isSelected ? '" \\2713 "' : '"  "',
      },
    };
  },
  singleValue: styles => {
    return {
      ...styles,

      color: props.darkMode
        ? props.forSearch
          ? "rgba(255, 255, 255, 0.54)"
          : "white"
        : "#414042",
    };
  },
  menuList: styles => {
    return {
      ...styles,
      "::-webkit-scrollbar": {
        backgroundColor: "#383d40",
        borderRadius: "80px",
      },
      "::-webkit-scrollbar-thumb": {
        background: "#1d2226",
        border: "3px solid #383d40",
        borderRadius: "80px",
      },
    };
  },
  menuPortal: base => ({ ...base, zIndex: 9999 }),
});

export const Dropdown = ({
  title,
  options,
  onChange,
  darkMode = true,
  verticalActiveBtn = false,
  forSearch = false,
  activeSearch,
  value,
}) => {
  const [dropdownBtn, setDropdownBtn] = useState(<DropdownBtn />);
  const activeDropdownBtn = verticalActiveBtn ? (
    <ActiveVerticalDropdownBtn />
  ) : (
    <ActiveDropdownBtn />
  );

  const formatOption = option => ({
    value: option,
    label: option,
  });

  const selectOptions = options.map(option => formatOption(option));
  const titleValue = formatOption(title);
  const otherValue = formatOption(value);

  const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        {dropdownBtn}
      </components.DropdownIndicator>
    );
  };

  const handleOnChange = option => {
    if (option && onChange) {
      const { value } = option;
      onChange(value);
    }
  };

  return !forSearch ? (
    <Select
      defaultValue={value ? otherValue : titleValue}
      options={[titleValue, ...selectOptions]}
      isSearchable={false}
      styles={selectStyle({ darkMode })}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      onMenuClose={() => setDropdownBtn(<DropdownBtn />)}
      onMenuOpen={() => setDropdownBtn(activeDropdownBtn)}
      onChange={handleOnChange}
      menuPortalTarget={document.body}
    />
  ) : (
    <Select
      defaultValue={formatOption(activeSearch)}
      options={[...selectOptions]}
      isSearchable={false}
      styles={selectStyle({ darkMode, forSearch })}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      onMenuClose={() => setDropdownBtn(<DropdownBtn />)}
      onMenuOpen={() => setDropdownBtn(activeDropdownBtn)}
      onChange={handleOnChange}
      menuPortalTarget={document.body}
    />
  );
};

Dropdown.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.any,
  darkMode: PropTypes.bool,
  verticalActiveBtn: PropTypes.bool,
  forSearch: PropTypes.bool,
  activeSearch: PropTypes.string,
  value: PropTypes.string,
};
