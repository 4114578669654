import { Col, Row } from "react-bootstrap";

import { useStore } from "../../store/store";
import { Heading5 } from "../Typography/Heading5";
import { Heading6 } from "../Typography/Heading6";
import styles from "./CountryCardRecentSearches.module.scss";

export const CountryCardRecentSearches = () => {
  const {
    recentSearches,
    country,
    resetCountry,
    setCountry,
    resetCountrySideDrawer,
  } = useStore();

  const handleCountryOnClick = country => {
    resetCountry();
    resetCountrySideDrawer();
    setCountry(country);
  };

  return (
    <>
      {!country && recentSearches.length > 0 && (
        <Row>
          <Col md={12} className="pt-3">
            <Heading6 classes={styles["recent-searches-title"]}>
              Recent searches
            </Heading6>
          </Col>

          <Row className="px-3">
            {recentSearches.map(country => (
              <span
                key={country?.countryOpportunity.countryName}
                className={styles["recent-searches-country"]}
                onClick={() => handleCountryOnClick(country)}
              >
                <Heading5>{country?.countryOpportunity.countryName}</Heading5>
              </span>
            ))}
          </Row>
        </Row>
      )}
    </>
  );
};
