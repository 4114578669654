import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./AnchorLink.module.scss";

export const AnchorLink = ({
  children,
  to,
  externalLink = false,
  linkColor,
  classes,
  onClick = () => {},
  target,
}) => {
  return (
    <>
      {externalLink ? (
        <a
          className={classNames(classes, styles["anchor-link"])}
          href={to}
          target="_blank"
          rel="noreferrer"
          style={{
            color: linkColor,
          }}
          onClick={onClick}
        >
          {children}
        </a>
      ) : (
        <Link
          className={classNames(classes, styles["anchor-link"])}
          to={to}
          onClick={onClick}
          target={target}
        >
          {children}
        </Link>
      )}
    </>
  );
};

AnchorLink.propTypes = {
  children: PropTypes.node,
  to: PropTypes.string,
  externalLink: PropTypes.bool,
  linkColor: PropTypes.string,
  classes: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
};
