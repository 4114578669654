import { useState } from "react";

import classNames from "classnames";
import { Card, Col, Row } from "react-bootstrap";

import { ReactComponent as DeadWeightBilling } from "../../assets/icons/iconDeadweightBilling.svg";
import { ReactComponent as Predict } from "../../assets/icons/iconLeafPredict.svg";
import { ReactComponent as LineHaul } from "../../assets/icons/iconLinehaul.svg";
import { ReactComponent as Plane } from "../../assets/icons/iconPlane.svg";
import { camelize } from "../../helpers/helpers";
import { useStore } from "../../store/store";
import styles from "./CountryCardDeliveryOptions.module.scss";
import useDeliveryOptions from "../../hooks/widgets/useDeliveryCards";

export const CountryCardDeliveryOptions = () => {
  const [activeLink, setActiveLink] = useState("");
  const {
    country,
    resetCountrySideDrawer,
    openCountrySideDrawer,
    isCountrySideDrawerOpen,
    setCountrySideDrawerContentType,
  } = useStore();

  const handleDeliveryOptionCardClick = (listItem, service) => {
    resetCountrySideDrawer();
    openCountrySideDrawer();
    setCountrySideDrawerContentType(camelize(service));
    setActiveLink(listItem);
  };

  const { deliveryCards } = useDeliveryOptions({
    country,
    airTransportIcon: <Plane />,
    roadTransportIcon: <LineHaul />,
    airClassicIcon: (
      <DeadWeightBilling className={styles["air-classic-icon"]} />
    ),
    roadClassicIcon: <Predict className={styles["predict-icon"]} />,
  });

  return (
    <Card.Body>
      {deliveryCards &&
        deliveryCards.map(card => (
          <>
            {card && (
              <Row
                key={`${country} - ${card.service}`}
                className={classNames(
                  "px-3 pt-3",
                  styles["country-delivery-options-card"],
                  card.serviceOption === activeLink &&
                    isCountrySideDrawerOpen &&
                    "country-card-delivery-option-selected",
                  "flex-nowrap"
                )}
                onClick={() =>
                  handleDeliveryOptionCardClick(
                    card.serviceOption,
                    card.service
                  )
                }
              >
                <Col md={2} xs={2}>
                  {card.icon}
                </Col>
                <Col xs={"auto"} className="flex-shrink-1">
                  <p
                    className={styles["country-delivery-options-card-service"]}
                  >
                    {card.serviceOption}
                  </p>
                  <p
                    className={
                      styles["country-delivery-options-card-service-option"]
                    }
                  >
                    {card.service} | {card.transitTime}
                  </p>
                </Col>
                {card.serviceIcon && (
                  <Col className={styles["service-icon"]}>
                    {card.serviceIcon}
                  </Col>
                )}
              </Row>
            )}
          </>
        ))}
    </Card.Body>
  );
};
