import classNames from "classnames";
import PropTypes from "prop-types";
import { Heading1 } from "../Heading1";

export const Header = ({ children, classes }) => {
  return (
    <Heading1 className={classNames(classes, "header")}>{children}</Heading1>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
