import classNames from "classnames";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";

import { CallToActionBtn } from "../../CallToActionBtn/CallToActionBtn";
import styles from "./ContactUsFormFooter.module.scss";

export const ContactUsFormFooter = ({
  backBtnOnClick,
  nextBtnOnClick,
  nextBtnText = "Next",
  nextBtnDisabled = false,
}) => {
  return (
    <Modal.Footer
      className={classNames(
        "border-0 px-4",
        backBtnOnClick && "justify-content-between"
      )}
    >
      {backBtnOnClick && (
        <CallToActionBtn classes="ml-3" variant="dark" onClick={backBtnOnClick}>
          <div className={styles["chevron-arrow-left"]}></div>
          Back
        </CallToActionBtn>
      )}

      {nextBtnOnClick && (
        <CallToActionBtn onClick={nextBtnOnClick} disabled={nextBtnDisabled}>
          {nextBtnText}
        </CallToActionBtn>
      )}
    </Modal.Footer>
  );
};

ContactUsFormFooter.propTypes = {
  backBtnOnClick: PropTypes.func,
  nextBtnOnClick: PropTypes.func,
  nextBtnText: PropTypes.string,
  nextBtnDisabled: PropTypes.bool,
};
