import classNames from "classnames";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { pdfUrlTracker } from "../../helpers/helpers";
import styles from "./CallToActionBtn.module.scss";

export const CallToActionBtn = ({
  children,
  classes,
  variant = "danger",
  onClick,
  btnLink = "",
  externalBtnLink = false,
  onMouseLeave,
  onMouseEnter,
  disabled = false,
}) => {
  const navigate = useNavigate();
  const tracker = useTracker();

  const navigateFn = externalBtnLink
    ? () => {
        pdfUrlTracker(tracker, btnLink);
        window.open(btnLink);
      }
    : () => {
        pdfUrlTracker(tracker, btnLink);
        navigate(btnLink);
      };

  let btnClass = styles["call-to-action-btn"];
  if (variant === "TGE") {
    btnClass = styles["green-btn"];
  }

  return (
    <Button
      className={classNames(classes, btnClass)}
      variant={variant}
      onClick={btnLink ? navigateFn : onClick}
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};

CallToActionBtn.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  variant: PropTypes.string,
  onClick: PropTypes.func,
  btnLink: PropTypes.string,
  externalBtnLink: PropTypes.bool,
  style: PropTypes.any,
  onMouseLeave: PropTypes.func,
  onMouseEnter: PropTypes.func,
  disabled: PropTypes.bool,
};
