import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import { ReactComponent as CustomsIcon } from "../../assets/icons/iconCustomsOffical.svg";
import { ReactComponent as ParcelWingsIcons } from "../../assets/icons/iconParcelWings.svg";
import { ANIMATIONS } from "../../constants/constants";
import { CountryIcons } from "../CountryIcons/CountryIcons";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading4 } from "../Typography/Heading4";

export const AirClassicDeliveryOption = () => {
  const icons = [
    {
      icon: <ParcelWingsIcons />,
      title: "",
      body: "Discover a whole new dimension with our deadweight billing option - Transporting goods by air can be costly when volumetric conversions are applied. With Air Classic, no matter where in the world you need to ship, we deadweight billing options available so you can enjoy a more competitive edge.",
    },
    {
      icon: <CustomsIcon />,
      title: "",
      body: "We’ve developed a user-friendly leading-edge solution for the payment of duties and taxes for the best experience for your recipient; but If you want to cover the duty and tax charges, then no problem, duty paid is also available - the choice is yours!",
    },
  ];

  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container>
          <Col md={12}>
            <Heading3>Air Classic</Heading3>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              More destinations, more control, and all at the right price with
              great service for you and your customers
            </Heading4>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              An extension to our leading European road service, Air Classic
              connects you to over 200 countries worldwide. Air Classic is the
              perfect service if you don’t want to compromise the quality or
              speed of the delivery experience but want more control or even
              better, to reduce your international shipping costs.
            </Body>
          </Col>

          <CountryIcons icons={icons} />
        </Container>
      </div>
    </ScrollAnimation>
  );
};
