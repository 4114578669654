import { useCallback, useState } from "react";
import {
  DESTINATION_ERROR,
  ORIGIN_ERROR,
  POSTCODE_ERROR,
} from "../constants/strings";

const useLeadTime = (
  onCalculateLeadTime,
  initialLeadTimeData,
  initialLeadTimeValues
) => {
  const [leadTimeValues, setLeadTimeValues] = useState({
    originCountry: "United Kingdom",
    originPostalCode: "",
    destinationCountry: "",
    destinationPostalCode: "",
    shippingDate: null,
    ...initialLeadTimeValues,
  });
  const [leadTimeData, setLeadTimeData] = useState(initialLeadTimeData || null);

  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOriginError, setShowOriginError] = useState(false);
  const [showDestinationError, setShowDestinationError] = useState(false);

  const isFormFilled = useCallback(() => {
    // Shipping date is optional so don't check it
    const valuesToCheck = {
      originCountry: leadTimeValues.originCountry,
      originPostalCode: leadTimeValues.originPostalCode,
      destinationCountry: leadTimeValues.destinationCountry,
      destinationPostalCode: leadTimeValues.destinationPostalCode,
    };

    return Object.values(valuesToCheck).every(val => val !== "");
  }, [leadTimeValues]);

  const resetErrors = useCallback(() => {
    setShowError(false);
    setErrorMessage("");
    setShowOriginError(false);
    setShowDestinationError(false);
  }, []);

  const handleOnSubmit = useCallback(async () => {
    setLeadTimeData(null);

    const allValuesNotEmpty = Object.values(leadTimeValues).every(
      val => val !== ""
    );

    if (allValuesNotEmpty) {
      resetErrors();

      try {
        await onCalculateLeadTime(leadTimeValues);
      } catch (error) {
        const errorMessage = error.errors[0].message;

        const showPostcodeErrors = errorMessage.includes(POSTCODE_ERROR);
        const showOriginError = errorMessage.includes(ORIGIN_ERROR);
        const showDestinationError = errorMessage.includes(DESTINATION_ERROR);

        if (showPostcodeErrors) {
          setShowOriginError(true);
          setShowDestinationError(true);
        }

        if (showOriginError) {
          setShowOriginError(true);
        }

        if (showDestinationError) {
          setShowDestinationError(true);
        }

        // This means that leadtime api is down
        if (!showPostcodeErrors && !showOriginError && !showDestinationError) {
          setShowError(true);
          setErrorMessage(errorMessage);
        }
      }
    }
  }, [leadTimeValues]);

  const isValidResponse = useCallback(() => {
    if (
      showError ||
      !leadTimeData ||
      !leadTimeData?.leadTime ||
      !leadTimeData?.cutOffTimeSendingDepot
    ) {
      return false;
    }

    return true;
  }, [leadTimeData, showError]);

  return {
    isFormFilled,
    setLeadTimeValues,
    setLeadTimeData,
    errorMessage,
    showOriginError,
    showDestinationError,
    handleOnSubmit,
    isValidResponse,
    leadTimeData,
    leadTimeValues,
    showError,
  };
};

export default useLeadTime;
