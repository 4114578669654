import PropTypes from "prop-types";

import styles from "./YoutubeEmbed.module.scss";

export const YoutubeEmbed = ({ embedId, className }) => {
  return (
    <div className={className}>
      <iframe
        className={styles["youtube-embed"]}
        src={`https://www.youtube.com/embed/${embedId}`}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string,
  className: PropTypes.string,
};
