import React from "react";

import { AirClassicDeliveryOption } from "../components/AirClassicDeliveryOption/AirClassicDeliveryOption";
import { AirExpressDeliveryOption } from "../components/AirExpressDeliveryOption/AirExpressDeliveryOption";
import { CountryCustomsData } from "../components/CountryCustomsData/CountryCustomsData";
import { CountryDutiesTaxes } from "../components/CountryDutiesTaxes/CountryDutiesTaxes";
import { CountryGoods } from "../components/CountryGoods/CountryGoods";
import { CountryOpportunity } from "../components/CountryOpportunity/CountryOpportunity";
import { DpdClassicDeliveryOption } from "../components/DpdClassicDeliveryOption/DpdClassicDeliveryOption";
import { DpdDirectDeliveryOption } from "../components/DpdDirectDeliveryOption/DpdDirectDeliveryOption";
import { StrategicLocationInfo } from "../components/StrategicLocationInfo/StrategicLocationInfo";
import {
  DELIVERY_OPTIONS_CONTENT_TYPES,
  EXPORT_INFO_CONTENT_TYPES,
  MARKET_INFO_CONTENT_TYPES,
} from "../constants/constants";
import { EU_COUNTRIES, REST_OF_WORLD_COUNTRIES } from "../data/country";
import DpdDirectLiteDeliveryOption from "../components/DpdDirectLiteDeliveryOption/DpdDirectLiteDeliveryOption";

export const camelize = str => {
  return str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
};

export const camelCaseToWords = str => {
  const result = str.replace(/([A-Z])/g, " $1");
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  return finalResult;
};

export const getCountrySideDrawerContentComponent =
  countrySideDrawerContentType => {
    switch (countrySideDrawerContentType) {
      case MARKET_INFO_CONTENT_TYPES.COUNTRY_OPPORTUNITIES:
        return CountryOpportunity;
      case EXPORT_INFO_CONTENT_TYPES.CUSTOMS_DATA:
        return CountryCustomsData;
      case EXPORT_INFO_CONTENT_TYPES.DPD_GOODS:
        return CountryGoods;
      case EXPORT_INFO_CONTENT_TYPES.DUTIES_TAXES:
        return CountryDutiesTaxes;
      case DELIVERY_OPTIONS_CONTENT_TYPES.AIR_EXPRESS:
        return AirExpressDeliveryOption;
      case DELIVERY_OPTIONS_CONTENT_TYPES.AIR_CLASSIC:
        return AirClassicDeliveryOption;
      case DELIVERY_OPTIONS_CONTENT_TYPES.DPD_DIRECT:
        return DpdDirectDeliveryOption;
      case DELIVERY_OPTIONS_CONTENT_TYPES.ROAD_CLASSIC:
        return DpdClassicDeliveryOption;
      case "strategicLocation":
        return StrategicLocationInfo;
      case DELIVERY_OPTIONS_CONTENT_TYPES.DPD_DIRECT_LITE:
        return DpdDirectLiteDeliveryOption;
      default:
        return React.Fragment;
    }
  };

export const getCountryImgUrl = imagePath => {
  if (!imagePath) {
    return "";
  }

  return imagePath;
};

export const isCountryEu = country => {
  return EU_COUNTRIES.includes(country);
};

export const isCountryRestOfWorld = country => {
  return REST_OF_WORLD_COUNTRIES.includes(country);
};

export const pdfUrlTracker = (tracker, url) => {
  if (url.match(/.pdf/g)) {
    tracker.setContext({
      url,
      userAgent: navigator.userAgent,
    });

    tracker.logEvent();
  }
};

export const convert24HourTo12Hour = time => {
  const timeArr = time.split(":");
  let hours = parseInt(timeArr[0]);
  const minutes = timeArr[1];
  const timeSuffix = hours >= 12 ? "pm" : "am";

  hours = hours % 12;
  hours = hours || 12; // if hours is 0, set it to 12

  return `${hours}:${minutes} ${timeSuffix}`;
};

export const getRecentSuggested = () => {
  // eslint-disable-next-line
  const items = localStorage.getItem("recent");

  return JSON.parse(items) || [];
};

export const addRecentSuggested = (itemToAdd, items) => {
  if (items.length >= 5) {
    items.shift();
  }
  items.push(itemToAdd);

  const jsonArray = JSON.stringify(items);

  // eslint-disable-next-line
  return localStorage.setItem("recent", jsonArray);
};

export const clearRecentSuggested = () => {
  const items = [];

  const jsonArray = JSON.stringify(items);

  // eslint-disable-next-line
  return localStorage.setItem("recent", jsonArray);
};

export const getMargin = width => {
  const slope = (68 - 0) / (767 - 631);
  const margin = (32 + slope * (width - 631)) * -1;
  return margin;
};

const getMonthAbbreviation = monthName => {
  const monthNames = {
    January: "Jan",
    February: "Feb",
    March: "Mar",
    April: "Apr",
    May: "May",
    June: "Jun",
    July: "Jul",
    August: "Aug",
    September: "Sep",
    October: "Oct",
    November: "Nov",
    December: "Dec",
  };

  return monthNames[monthName] || monthName;
};

export const getFormattedDate = text => {
  const dateSplit = text.split(" ");
  dateSplit[1] = getMonthAbbreviation(dateSplit[1]);
  return dateSplit.join(" ");
};
