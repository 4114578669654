import { useEffect, useState } from "react";

import classNames from "classnames";
import { Button, Col, Form } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { getCountryData } from "../../apis/api";
import { ReactComponent as Search } from "../../assets/icons/search.svg";
import { HOME } from "../../constants/actions";
import { WORLD_MAP } from "../../router/constants";
import { useStore } from "../../store/store";
import { CountrySearchError } from "../CountrySearchError/CountrySearchError";
import { Heading5 } from "../Typography/Heading5";
import styles from "./CountrySearch.module.scss";

export const CountrySearch = () => {
  const [countryValue, setCountryValue] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const {
    country,
    setCountry,
    setIsLoading,
    recentSearches,
    setRecentSearches,
    setErrorCountry,
  } = useStore();
  const tracker = useTracker();

  useEffect(() => {
    if (countryValue === "") {
      setError("");
    }
  }, [countryValue]);

  const handleSubmitForm = async e => {
    e.preventDefault();
    setError("");
    tracker.logEvent(HOME.ON_CLICK_SEARCH);

    if (
      countryValue.trim() === country?.marketInfo.countryName.trim() ||
      !countryValue
    ) {
      return;
    }

    const searchAlreadyExists = recentSearches.find(
      search =>
        search?.marketInfo.countryName.toLowerCase() ===
        countryValue.toLowerCase()
    );

    if (searchAlreadyExists) {
      setCountry(searchAlreadyExists);
      navigate(WORLD_MAP);
      return;
    }

    setIsLoading(true);

    try {
      const { data } = await getCountryData(countryValue);
      const {
        deliveryOptions,
        exportInfo,
        marketInfo,
        opportunities,
        imagePath,
      } = data;

      const countryData = {
        marketInfo,
        countryOpportunity: opportunities,
        exportInfo,
        deliveryOptions,
        imagePath, // Set the imagePath from the API response
      };

      if (countryData) {
        setIsLoading(false);
        setCountry(countryData);
        setRecentSearches(countryData);
        navigate(WORLD_MAP);
      }
    } catch (error) {
      setIsLoading(false);
      setErrorCountry(countryValue);
      navigate(WORLD_MAP);
    }
  };

  return (
    <div className={styles["country-search-container"]}>
      <Form
        className={classNames("d-flex", styles["country-search-form"])}
        onSubmit={handleSubmitForm}
      >
        <Col md={3}>
          <Heading5>Which Country?</Heading5>
        </Col>
        <Col md={8} className="mt-2">
          <input
            className={classNames("w-100 p-2", styles["country-search-input"])}
            placeholder={
              isMobile
                ? "Enter destination country"
                : "Enter destination country for our services and insights"
            }
            value={countryValue}
            onChange={e => setCountryValue(e.target.value)}
          />
        </Col>
        <Col md={1} className={styles["country-search-button"]}>
          <Button type="submit" variant="danger">
            <Search />
          </Button>
        </Col>
      </Form>
      {error && <CountrySearchError />}
    </div>
  );
};
