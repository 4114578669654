import classNames from "classnames";
import PropTypes from "prop-types";

export const Bold = ({ children, classes }) => {
  return    <span className={classNames(classes, "Bold")}>{children}</span>

};

Bold.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
