import { useMemo } from "react";
import { EU_COUNTRIES, REST_OF_WORLD_COUNTRIES } from "../../data/country";
import { ReactComponent as FamilyIcon } from "../../assets/icons/family.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/globe.svg";
import { ReactComponent as LaptopIcon } from "../../assets/icons/laptop.svg";
import { ReactComponent as PaymentIcon } from "../../assets/icons/payment.svg";

import { ReactComponent as FamilyIconWhite } from "../../assets/pages/chat/widget/iconFamily.svg";
import { ReactComponent as LaptopIconWhite } from "../../assets/pages/chat/widget/iconLaptop.svg";

const redThemeIcons = {
  family: <FamilyIcon />,
  globe: <GlobeIcon />,
  laptop: <LaptopIcon />,
  payment: <PaymentIcon />,
};

const whiteThemeIcons = {
  family: <FamilyIconWhite />,
  globe: <GlobeIcon />,
  laptop: <LaptopIconWhite />,
  payment: <PaymentIcon />,
};

const useOpportunities = ({ country, theme }) => {
  const icons = useMemo(
    () => (theme === "white" ? whiteThemeIcons : redThemeIcons),
    []
  );

  const sources = useMemo(() => {
    if (country && Array.isArray(country.marketInfo.sources)) {
      return country.marketInfo.sources
        .sort((a, b) =>
          a.linkText > b.linkText ? 1 : b.linkText > a.linkText ? -1 : 0
        )
        .filter(a => a.linkText !== "great.gov.uk");
    }

    return [];
  }, []);

  const currency = useMemo(() => {
    let countryCurrency = "€";
    if (country) {
      countryCurrency = EU_COUNTRIES.includes(
        country?.countryOpportunity.countryName
      )
        ? "€"
        : REST_OF_WORLD_COUNTRIES.includes(
            country?.countryOpportunity.countryName
          )
        ? "$"
        : "€";
    }

    return countryCurrency;
  }, [country]);

  const keyStats = [
    {
      icon: icons.family,
      value: country?.countryOpportunity.onlineShoppersNumber,
      prependValue: null,
      appendValue: country?.countryOpportunity.onlineShoppersDenominationShort,
      valueDecimalPlace: 1,
      text: "Online Shoppers",
    },
    {
      icon: icons.laptop,
      value: country?.countryOpportunity.percentShopOnline,
      prependValue: null,
      appendValue: "%",
      valueDecimalPlace: 0,
      text: "Shop Online",
    },
    {
      icon: icons.payment,
      value: country?.countryOpportunity.annualSpendPP,
      prependValue: currency,
      appendValue: null,
      valueDecimalPlace: 0,
      text: "Annual spend per person",
    },
  ];

  const consumerStats = [
    {
      icon: icons.payment,
      value: country?.countryOpportunity.avgPurchaseAmountPerMonth,
      prependValue: currency,
      appendValue: null,
      valueDecimalPlace: 0,
      text: "Avg. purchase amount per month",
    },
    {
      icon: icons.globe,
      value: country?.countryOpportunity.percentInternetUsers,
      prependValue: null,
      appendValue: "%",
      valueDecimalPlace: 0,
      text: "Internet users",
    },
  ];

  return {
    sources,
    keyStats,
    consumerStats,
  };
};

export default useOpportunities;
