import { useCallback, useMemo } from "react";
import { MARKET_INFO_CONTENT_TYPES } from "../../constants/constants";
import { useStore } from "../../store/store";

const useMarketInfo = ({ country }) => {
  const { setShowContactUsModal } = useStore();

  const sources = useMemo(() => {
    if (country && Array.isArray(country.marketInfo.sources)) {
      return country.marketInfo.sources.filter(
        a => a.linkText === "great.gov.uk"
      );
    }

    return [];
  }, [country]);

  const marketInfoHeadings = [
    {
      heading: "Capital",
      value: country && country.marketInfo.capitalCity,
    },
    {
      heading: "Time Zone",
      value: country && country.marketInfo.timezone,
    },
    {
      heading: "Language",
      value: country && country.marketInfo.language,
    },
    {
      heading: "Currency",
      value: country && country.marketInfo.currency,
    },
  ];

  const marketInfoList = [
    {
      contentType: MARKET_INFO_CONTENT_TYPES.INTERNATIONAL_DELIVERY_EXPERTS,
      content: "Get in touch with our International Delivery Experts",
    },
  ];

  const onMarketInfoListItemClick = useCallback(
    type => {
      if (type === MARKET_INFO_CONTENT_TYPES.INTERNATIONAL_DELIVERY_EXPERTS) {
        setShowContactUsModal(true);
      }
    },
    [setShowContactUsModal]
  );

  return {
    sources,
    marketInfoHeadings,
    marketInfoList,
    onMarketInfoListItemClick,
  };
};

export default useMarketInfo;
