import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/jumbotron-bg.png";
import section3Bg from "../../../assets/backgrounds/jumbotron-purple-right-bg.png";
import section2Bg from "../../../assets/backgrounds/purple-left-bg.png";
import jumbotronImg from "../../../assets/pages/our-services/innovations/jumbotron-img.png";
import section1Bg from "../../../assets/pages/our-services/innovations/section-1-bg.png";
import section2Img from "../../../assets/pages/our-services/innovations/section-2-img.png";
import section3Img from "../../../assets/pages/our-services/innovations/section-3-img.png";
import section4Img from "../../../assets/pages/our-services/innovations/section-4-img.png";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentHotPink } from "../../../components/Typography/AccentHotPink";
import { AccentMint } from "../../../components/Typography/AccentMint";
import { AccentOrange } from "../../../components/Typography/AccentOrange";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { INNOVATIONS } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../constants/constants";

export const Innovations = () => {
  return (
    <Trackable
      interfaceId={INNOVATIONS.INTERFACE_ID}
      loadId={INNOVATIONS.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <ImgContainer imgs={jumbotronImg} columnSpan={12} />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>Innovations</Heading5>
            <Heading1>
              It’s innovation that{" "}
              <AccentHotPink>
                makes <br /> us different
              </AccentHotPink>
            </Heading1>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              European{" "}
              <AccentOrange>consolidated customs clearance </AccentOrange>
              and direct injection
            </Heading1>
            <Body>
              Now that the UK has left the EU many businesses have had to
              rethink their supply chain and delivery solutions. At DPD we
              understand that flexibility is key when helping our customers
              reach Europe, which is why we offer both single and bulk parcel
              clearance. For EU registered businesses, bulk clearance of parcels
              can be particularly cost effective.
            </Body>
            <Body>
              Our consolidated clearance injection solution allows you to clear
              your goods with the customs broker of your choice and still access
              all the benefits, quality and the reliability of the Geopost
              network, across Europe.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section2Bg}>
        <ImgContainer imgs={section2Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Cash on Delivery</Heading1>
            <Heading4>
              Cross-border e-commerce presents{" "}
              <AccentMint>attractive growth opportunities,</AccentMint> but
              preferred payment methods vary across the world with credit cards
              still remaining the only truly global electronic payment option.
            </Heading4>
            <Body>
              The issue however is that uptake of credit cards is still low in
              many countries - particularly in those emerging, fastest growing
              markets like the Middle East. The secret to breaking through this
              barrier to checkout is to extend your payment options to allow a
              cash on delivery (COD) sale. With DPD you can extend your Air
              Express or Air Classic service to include COD transactions to key
              destinations to unlock this demand, improve cash flow and
              encourage larger basket sizes.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Import into the UK with DPD:</Heading1>
            <Heading3>
              <AccentOrange>Your Trusted Partner</AccentOrange>
            </Heading3>
            <Body>
              Our experienced customs team ensures compliant and reliable
              customs activities, with your parcel delivered next-day after
              customs clearance. As part of Geopost, we have the strongest road
              network in Europe, making it easy to import parcels to the UK from
              across the continent. Our exceptional last-mile experience offers
              a range of delivery options to give your customers the ultimate
              choice in convenience. That&apos;s not all – we&apos;re committed
              to sustainability and are on track to be net zero by 2040.
            </Body>
            <Body>
              DPD UK is the ideal partner if you&apos;re looking for a reliable,
              sustainable, and efficient import solution.
            </Body>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={section3Img} />
      </Section>

      <Section bgImg={section2Bg}>
        <ImgContainer imgs={section4Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>DPD Flex</Heading1>
            <Heading3>
              DPD Flex offers you ultimate flexibility and agility in accessing
              all of DPD’s global services without the complexities and time
              constraints of developing new barcodes or integrating new
              services.
            </Heading3>
            <Body>
              We can take any label and provide any international service you
              require, taking care of all your global shipping needs, with no
              additional IT work for you.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
