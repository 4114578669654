import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/jumbotron-bg.png";
import section5Bg from "../../../assets/backgrounds/purple-left-bg.png";
import { ReactComponent as BulletIcon } from "../../../assets/icons/iconArrowUpRight.svg";
import cardDuties from "../../../assets/pages/international/export-fundamentals/cardDuties.png";
import cardHowTo from "../../../assets/pages/international/export-fundamentals/cardHowTo.png";
import cardShippingEU from "../../../assets/pages/international/export-fundamentals/cardShippingEU.png";
import cardTipExport from "../../../assets/pages/international/export-fundamentals/cardTipsExport.png";
import cardTrade from "../../../assets/pages/international/export-fundamentals/cardTrade.png";
import jumbotronImg from "../../../assets/pages/international/export-fundamentals/jumbotron-img.png";
import section6Bg from "../../../assets/pages/international/export-fundamentals/section-6-bg.png";
import { AnchorLink } from "../../../components/AnchorLink/AnchorLink";
import { BulletList } from "../../../components/BulletList/BulletList";
import { CallToActionBtn } from "../../../components/CallToActionBtn/CallToActionBtn";
import { CardContainer } from "../../../components/CardContainer/CardContainer";
import { DownloadBtn } from "../../../components/DownloadBtn/DownloadBtn";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { EXPORT_FUNDAMENTALS } from "../../../constants/actions";
import cardExportControls from "../../../assets/pages/international/export-controls/cardExportControls.png";

import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";
import {
  COUNTRY_FLAGS,
  COUNTRY_SPECIFIC_PROHIBITED_GOODS_PDFS,
} from "../../../data/country";
import { camelCaseToWords } from "../../../helpers/helpers";
import * as routes from "../../../router/constants";

import styles from "./ExportFundamentals.module.scss";

export const ExportFundamentals = () => {
  const tracker = useTracker();

  const countries = [...Object.keys(COUNTRY_FLAGS)];

  const countryCards = countries.map(country => {
    let coutryName = camelCaseToWords(country);

    if (country === "usa") {
      coutryName = "United States of America";
    }

    const downloadLink = COUNTRY_SPECIFIC_PROHIBITED_GOODS_PDFS[country];

    return {
      cardTitle: COUNTRY_FLAGS[country],
      cardText: <Body classes="text-center">{coutryName}</Body>,
      cardBackTitle: <Body classes="text-center">{coutryName}</Body>,
      cardBackText: (
        <DownloadBtn downloadLink={downloadLink}>Download</DownloadBtn>
      ),
      cardHoverFn: () =>
        tracker.logEvent(EXPORT_FUNDAMENTALS.ON_HOVER_INTERNATIONAL_FLAG),
    };
  });

  return (
    <Trackable
      interfaceId={EXPORT_FUNDAMENTALS.INTERFACE_ID}
      loadId={EXPORT_FUNDAMENTALS.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <ImgContainer imgs={jumbotronImg} columnSpan={12} />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>International Uncovered</Heading5>
            <Heading1>Export Fundamentals</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.ZOOM_IN}
            delay={600}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardBg: cardHowTo,
                  cardTitle: (
                    <Heading4>
                      The how-to of exporting goods and the role of customs
                    </Heading4>
                  ),
                  cardBtnTxt: "Learn how to export",
                  cardBtnLink: routes.HOW_TO_EXPORT,
                  columnSpan: 6,
                },
                {
                  cardBg: cardTipExport,
                  cardTitle: (
                    <Heading4>
                      Tips for Export Success and taking the complexity out of
                      compliance
                    </Heading4>
                  ),
                  cardBtnTxt: "Discover our tips for success",
                  cardBtnLink: routes.TIPS_FOR_EXPORT_SUCCESS,
                  columnSpan: 6,
                },
                {
                  cardBg: cardDuties,
                  cardTitle: (
                    <Heading4>Your guide to Duties and Taxes</Heading4>
                  ),
                  cardBtnTxt: "Get started with duties and taxes",
                  cardBtnLink: routes.YOUR_GUIDE_DUTIES,
                  columnSpan: 6,
                },
                {
                  cardBg: cardTrade,
                  cardTitle: <Heading4>The UK’s trade agreements</Heading4>,
                  cardBtnTxt: "What is our trade agreement?",
                  cardBtnLink: routes.UK_TRADE_AGREEMENT,
                  columnSpan: 6,
                },
                {
                  cardBg: cardShippingEU,
                  cardTitle: <Heading4>Shipping to the EU</Heading4>,
                  cardBtnTxt: "Find Out what's involved",
                  cardBtnLink: routes.SHIPPING_TO_EU,
                  columnSpan: 6,
                },
                {
                  cardBg: cardExportControls,
                  cardTitle: <Heading4>Export Controls</Heading4>,
                  cardBtnTxt: "Learn about Export Controls",
                  cardBtnLink: routes.EXPORT_CONTROLS,
                  columnSpan: 6,
                  cardBgClassName: styles["position-center"],
                },
              ]}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section5Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>International destination specific guidance</Heading1>
            <Heading3>
              We&apos;ve produced some handy cue cards to help you with
              exporting to these popular destinations.
            </Heading3>
            <CardContainer
              cards={countryCards}
              columnSpan={3}
              cardFlip={true}
            />
          </ScrollAnimation>
        </Col>
      </Section>
      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Useful Links and Resources</Heading1>
            <Heading4>
              We’ve curated a handy list of links and resources, including
              advice and support from our partners such as the Department for
              International Trade as well as Government tools and services for
              checking commodity codes, tariff rates and general export
              requirements.
            </Heading4>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3 classes="text-center">
              Access UK Government guidance on <br /> exporting with these handy
              links:
            </Heading3>
            <CardContainer
              cards={[
                {
                  cardText: (
                    <BulletList
                      listItems={[
                        {
                          text: (
                            <AnchorLink
                              externalLink={true}
                              to={routes.HOW_TO_EXPORT_USING_HM_SERVICE}
                            >
                              Check how to export goods using HM Government
                              online service
                            </AnchorLink>
                          ),
                        },
                        {
                          text: (
                            <AnchorLink
                              externalLink={true}
                              to={routes.APPLY_TO_GET_EORI_NUMBER}
                            >
                              Apply to get an EORI number to move goods between
                              Great Britain, Northern Ireland or the Isle of Man
                              and other countries
                            </AnchorLink>
                          ),
                        },
                        {
                          text: (
                            <AnchorLink
                              externalLink={true}
                              to={routes.LOOK_UP_COMMIDITY_CODES}
                            >
                              Look up commodity codes, duty and tax rates
                            </AnchorLink>
                          ),
                        },
                        {
                          text: (
                            <AnchorLink
                              externalLink={true}
                              to={routes.FIND_COMMIDITY_CODES}
                            >
                              Find commodity codes for exporting out of and
                              importing into the UK
                            </AnchorLink>
                          ),
                        },
                        {
                          text: (
                            <AnchorLink
                              externalLink={true}
                              to={routes.GUIDANCE_ON_RULES_ORIGIN}
                            >
                              Read guidance on rules of origin
                            </AnchorLink>
                          ),
                        },
                        {
                          text: (
                            <AnchorLink
                              externalLink={true}
                              to={routes.TRADING_IN_OUT_NORTHERN_IRELAND}
                            >
                              Find out more about trading and moving goods into
                              and out of Northern Ireland{" "}
                            </AnchorLink>
                          ),
                        },
                      ]}
                      listIcon={<BulletIcon />}
                    />
                  ),
                },
              ]}
              columnSpan={12}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section6Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Got another question?</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={routes.FAQS}>
              Visit our FAQs
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
