import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import section1Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import { ReactComponent as DocumentIcon } from "../../../../assets/icons/iconDocumentForm.svg";
import { ReactComponent as LightBulbIcon } from "../../../../assets/icons/iconLightBulb.svg";
import { ReactComponent as PaymentIcon } from "../../../../assets/icons/iconPayment.svg";
import { ReactComponent as QuestionIcon } from "../../../../assets/icons/iconQuestion.svg";
import jumbotronBg from "../../../../assets/pages/international/guide-to-dutites/jumbotron-bg.png";
import { BulletList } from "../../../../components/BulletList/BulletList";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import variables from "../../../../styles/colors.scss";

export const YourGuideToDutiesTaxes = () => {
  return (
    <>
      <Section bgImg={jumbotronBg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader headerText="Your guide to Duties and Taxes" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              When it comes to sending parcels to international destinations, it
              is easy to be confused by taxes, duties and tariffs. As an
              exporter, it’s important to know what they mean and how they are
              calculated, since they will contribute to the total cost of your
              products
            </Heading3>
            <Heading4>
              You’ll also need to decide who will be paying these charges to
              ensure the best delivery experience and avoid unnecessary delays.
              Our overview of duties and taxes can help you with this topic.
            </Heading4>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              When sending parcels to an international destination, the goods
              may be subject to Duties and Taxes
            </Heading2>
            <Heading4>
              The term duties and taxes refers to two separate charges and if
              you are exporting goods these additional charges will contribute
              to the overall cost of your goods.
            </Heading4>
            <Row className="px-5 pb-3">
              <BulletList
                listItems={[
                  {
                    text: (
                      <Body>
                        Duty is often referred to as import duty and is based on
                        the trade tariff negotiated by trade partners
                        (Governments). This is a tax for goods entering another
                        country.
                      </Body>
                    ),
                    icon: <PaymentIcon />,
                  },
                  {
                    text: (
                      <Body>
                        VAT is a consumption tax (another form of tax) and is
                        charged on all purchases. VAT is charged based on the
                        total invoice value declared in the customs data you
                        have supplied and varies by destination. Therefore, It’s
                        important to know that the VAT rate applicable will
                        depend on which destination the goods have been imported
                        to.
                      </Body>
                    ),
                    icon: <DocumentIcon />,
                  },
                ]}
              />
            </Row>

            <CardContainer
              cards={[
                {
                  cardText: (
                    <Row className="px-2">
                      <Col
                        md={1}
                        className="d-flex flex-column align-items-center justify-content-center pr-0"
                      >
                        <LightBulbIcon />
                      </Col>
                      <Col md={11}>
                        <Body>
                          Top tip: DPD will declare all the information provided
                          in the electronic customs data to the relevant customs
                          authorities. It’s important that this information is
                          accurate as this is used by customs to assess the
                          level of duty & taxes applicable. Inaccurate or
                          missing information will cause delays, potential
                          seizure, fines or the return of the shipment.
                        </Body>
                      </Col>
                    </Row>
                  ),
                  columnSpan: 12,
                },
              ]}
              cardsBgColor={variables.darkShade2}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Calculating Duties and Taxes</Heading3>
            <Body>
              Customs authorities determine the amount of duties and taxes due
              based on the information you supply in the electronic customs
              data.
            </Body>
            <Body>
              The key data used to calculate duty is the commodity code and
              country of origin. You can check the percentage rate of duty for
              the importing country by using the online trade tariff tools. The
              UK Government has a handy commodity code checker. If the United
              Kingdom has a Trade Agreement with the country you are importing
              the goods into, then for certain commodities preferential duty
              rates may apply.
            </Body>
            <ExternalLinkBtn btnLink={"https://www.gov.uk/trade-tariff"}>
              Commodity Code Checker
            </ExternalLinkBtn>
            <Body>
              VAT is normally calculated based on the sum of the goods value,
              import duty, transport or shipping costs and insurance multiplied
              by the importing country’s VAT rate percentage.
            </Body>

            <Row>
              <CardContainer
                cards={[
                  {
                    cardText: (
                      <Row className="px-2">
                        <Col
                          md={1}
                          className="d-flex flex-column align-items-center justify-content-center pr-0"
                        >
                          <QuestionIcon />
                        </Col>
                        <Col md={11}>
                          <Body>
                            Did you know? The de minimis is the value threshold
                            defined by a country to levy customs duties and tax
                            rates on goods when they are imported. If the value
                            of the products in a consignment declared to customs
                            is below the de minimis, then duties and taxes may
                            not apply. In the EU for example, VAT is applied
                            regardless of value but duty is only charged to
                            goods valued over €150.
                          </Body>
                        </Col>
                      </Row>
                    ),
                    columnSpan: 12,
                  },
                ]}
              />
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>INCOTERMS ®</Heading3>
            <Body>
              INCOTERMS® or International Commercial Terms are a set of rules
              produced by the International Chambers of Commerce and define the
              terms of trade for the global sale of goods. They provide guidance
              for establishing the relationship of the buyer and the seller in
              respect of responsibilities and risks. There are 11 commonly-used
              INCOTERMS ® and are identified as three-letter terms.
            </Body>

            <ExternalLinkBtn
              btnLink={
                "https://iccwbo.org/publication/incoterms-2020-introduction/"
              }
            >
              Find out more about INCOTERMS®
            </ExternalLinkBtn>
            <Body>
              VAT is normally calculated based on the sum of the goods value,
              import duty, transport or shipping costs and insurance multiplied
              by the importing country’s VAT rate percentage.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Flexible Duty and Tax Payment Solutions </Heading3>
            <Body>
              Duties and Taxes need to be paid before the goods can be released
              from Customs. At DPD, we’ve developed a number of flexible and
              secure duty and tax payment options that have been designed in a
              way to suit your business needs, so we can charge the recipient or
              bill these back to you as the shipper if you prefer your customer
              not to be contacted for any payment of these charges. We can also
              cater for you if you are registered for example with the EU’s
              import one stop shop ( or IOSS). Best of all, our expedited
              customs clearance solution has been designed to start work
              ‘in-flight’ to speed up the process to get your goods quicker to
              your customer.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </>
  );
};
