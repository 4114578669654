import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";
import ReactPlayer from "react-player";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../assets/backgrounds/jumbotron-bg.png";
import cardAirClassic from "../../assets/pages/our-services/cardAirClassic.png";
import cardDpdDirect from "../../assets/pages/our-services/cardDirect.png";
import cardDpdClassic from "../../assets/pages/our-services/cardDpdClassic.png";
import cardAirExpress from "../../assets/pages/our-services/cardExpress.png";
import jumbotronImg from "../../assets/pages/our-services/jumbotron-img.png";
import section1Bg from "../../assets/pages/our-services/section-1-bg.png";
import section1Img from "../../assets/pages/our-services/section-1-img.png";
import section2Bg from "../../assets/pages/our-services/section-2-bg.png";
import { CallToActionBtn } from "../../components/CallToActionBtn/CallToActionBtn";
import { CardContainer } from "../../components/CardContainer/CardContainer";
import { GetInTouch } from "../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../components/ImgContainer/ImgContainer";
import { Section } from "../../components/Section/Section";
import { Body } from "../../components/Typography/Body";
import { Heading1 } from "../../components/Typography/Heading1";
import { Heading3 } from "../../components/Typography/Heading3";
import { Heading4 } from "../../components/Typography/Heading4";
import { Heading5 } from "../../components/Typography/Heading5";
import { OUR_SERVICES } from "../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../constants/constants";
import * as routes from "../../router/constants";

export const OurServices = () => {
  return (
    <Trackable
      interfaceId={OUR_SERVICES.INTERFACE_ID}
      loadId={OUR_SERVICES.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <ImgContainer imgs={jumbotronImg} columnSpan={12} />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={1000}
            animateOnce={true}
          >
            <Heading5>Our Services</Heading5>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={1500}
            animateOnce={true}
          >
            <Heading1>Your international delivery experts</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={2000}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardBg: cardDpdClassic,
                  cardTitle: <Heading4>DPD Classic</Heading4>,
                  cardText: (
                    <Body>
                      One of the fastest, most reliable delivery services in
                      Europe
                    </Body>
                  ),
                  cardBtnTxt: "Learn More",
                  cardBtnLink: routes.DPD_CLASSIC,
                },
                {
                  cardBg: cardAirExpress,
                  cardTitle: <Heading4>Air Express</Heading4>,
                  cardText: (
                    <Body>
                      DPD&apos;s global express network offers an unrivalled
                      service to more than 200 countries worldwide
                    </Body>
                  ),
                  cardBtnTxt: "Learn More",
                  cardBtnLink: routes.AIR_EXPRESS,
                },
                {
                  cardBg: cardAirClassic,
                  cardTitle: <Heading4>Air Classic</Heading4>,
                  cardText: (
                    <Body>
                      A great international service for those non-time sensitive
                      shipments that still require full tracking
                    </Body>
                  ),
                  cardBtnTxt: "Learn More",
                  cardBtnLink: routes.AIR_CLASSIC,
                },
                {
                  cardBg: cardDpdDirect,
                  cardTitle: <Heading4>DPD Direct & DPD Direct Lite</Heading4>,
                  cardText: (
                    <Body>
                      Introducing our innovative international delivery solution
                      which has been developed specifically for online retailers
                    </Body>
                  ),
                  cardBtnTxt: "Learn More",
                  cardBtnLink: routes.DPD_DIRECT,
                },
              ]}
              columnSpan={6}
            />
            <CardContainer
              cards={[
                {
                  cardText: (
                    <>
                      <Heading3>
                        Learn more about DPD network and services
                      </Heading3>
                      <Body>{"Don't let borders limit your business"}</Body>
                      <ReactPlayer
                        url={"https://vimeo.com/788939886/172951a666"}
                        width="100%"
                        controls={true}
                      />
                    </>
                  ),
                },
              ]}
              columnSpan={12}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>We bring the world closer to you</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={routes.WORLD_MAP}>
              Discover More
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={section1Img} columnSpan={6} />
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Innovations</Heading1>
            <Heading3>
              Forging new paths, finding new routes, enabling e-commerce
              acceleration and overcoming obstacles.
            </Heading3>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={routes.INNOVATIONS}>
              See what’s new
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section2Bg} height={{ SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              Discover a world of delivery possibilities with DPD&apos;s new
              International Service Brochure packed with valuable information on
              our international shipping services.
            </Heading3>
            <Heading4>
              Download it now and unlock a world of delivery possibilities with
              DPD.
            </Heading4>
            <CallToActionBtn
              btnLink={routes.DPD_INTERNATIONAL_BROCHURE}
              externalBtnLink={true}
            >
              Download Now
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
