import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import { ANIMATIONS } from "../../constants/constants";
import { useStore } from "../../store/store";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading5 } from "../Typography/Heading5";

export const StrategicLocationInfo = () => {
  const { selectedStrategicLocation } = useStore();

  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container className={"pt-3 px-3"}>
          <Col md={12}>
            <Heading3>{selectedStrategicLocation?.depotName}</Heading3>
          </Col>

          <Col md={12}>
            <Heading5>{selectedStrategicLocation?.country}</Heading5>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              {selectedStrategicLocation?.description}
            </Body>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
};
