import classNames from "classnames";
import PropTypes from "prop-types";

export const Annotation = ({ children, classes }) => {
  return <p className={classNames(classes, "annotation")}>{children}</p>;
};

Annotation.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
