import { useEffect, useState } from "react";

import { useInView } from "react-intersection-observer";

export const useAnimation = () => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const [animate, setAnimate] = useState(false);
  const [ref, inView] = useInView();

  useEffect(() => {
    if (inView && !hasAnimated) {
      setAnimate(true);
      setHasAnimated(true);
    }
  }, [inView]);

  return [ref, animate];
};
