import create from "zustand";
import { devtools } from "zustand/middleware";

const initialState = {
  country: null,
  isCountrySideDrawerOpen: false,
  countrySideDrawerContentType: "",
  isLoading: false,
  recentSearches: [],
  selectedPin: null,
  showContactUsModal: false,
  locations: {
    hubLocations: [],
    strategicLocations: [],
  },
  selectedStrategicLocation: null,
  errorCountry: null,
  shouldFly: true,
  isLeadTimeCalculatorEnabled: false,
};

const store = set => ({
  country: initialState.country,
  setCountry: country => set({ country }),
  resetCountry: () =>
    set({
      country: initialState.country,
      isCountrySideDrawerOpen: initialState.isCountrySideDrawerOpen,
      countrySideDrawerContentType: initialState.countrySideDrawerContentType,
      selectedPin: initialState.selectedPin,
      selectedStrategicLocation: initialState.selectedStrategicLocation,
      errorCountry: initialState.errorCountry,
      shouldFly: initialState.shouldFly,
    }),
  isCountrySideDrawerOpen: initialState.isCountrySideDrawerOpen,
  setIsCountrySideDrawerOpen: open => set({ isCountrySideDrawerOpen: open }),
  countrySideDrawerContentType: initialState.countrySideDrawerContentType,
  setCountrySideDrawerContentType: contentType =>
    set({ countrySideDrawerContentType: contentType }),
  resetCountrySideDrawer: () => {
    set({
      isCountrySideDrawerOpen: initialState.isCountrySideDrawerOpen,
      countrySideDrawerContentType: initialState.countrySideDrawerContentType,
      selectedPin: initialState.selectedPin,
      selectedStrategicLocation: initialState.selectedStrategicLocation,
      errorCountry: initialState.errorCountry,
    });
  },
  openCountrySideDrawer: () => {
    setTimeout(() => {
      set({ isCountrySideDrawerOpen: true });
    }, 500);
  },
  closeCountrySideDrawer: () => {
    setTimeout(() => {
      set({ isCountrySideDrawerOpen: false });
    }, 500);
  },
  isLoading: initialState.isLoading,
  setIsLoading: loading => set({ isLoading: loading }),
  recentSearches: initialState.recentSearches,
  setRecentSearches: country => {
    set(state => {
      const currentRecentSearches = [...state.recentSearches];
      const searchAlreadyExists = currentRecentSearches.find(
        search =>
          search?.marketInfo.countryName === country?.marketInfo.countryName
      );

      if (!searchAlreadyExists) {
        currentRecentSearches.unshift(country);
      }

      if (currentRecentSearches.length > 4) {
        currentRecentSearches.pop();
      }

      return {
        recentSearches: [...currentRecentSearches],
      };
    });
  },
  selectedPin: initialState.selectedPin,
  setSelectedPin: place => set({ selectedPin: place }),
  showContactUsModal: initialState.showContactUsModal,
  setShowContactUsModal: open => set({ showContactUsModal: open }),
  locations: initialState.locations,
  setLocations: locations => set({ locations }),
  selectedStrategicLocation: initialState.selectedStrategicLocation,
  setSelectedStrategicLocation: location =>
    set({ selectedStrategicLocation: location }),
  errorCountry: initialState.errorCountry,
  setErrorCountry: country => set({ errorCountry: country }),
  shouldFly: initialState.shouldFly,
  setShouldFly: shouldFly => set({ shouldFly }),
  isLeadTimeCalculatorEnabled: initialState.isLeadTimeCalculatorEnabled,
  setIsLeadTimeCalculatorEnabled: leadTimeCalculatorEnabled =>
    set({ isLeadTimeCalculatorEnabled: leadTimeCalculatorEnabled }),
});

export const useStore = create(devtools(store));
