import { useMemo, useRef, useState } from "react";

import classNames from "classnames";
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from "react-accessible-accordion";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/pages/support/jumbotron-bg.png";
import { Dropdown } from "../../../components/Dropdown/Dropdown";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../components/Section/Section";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading5 } from "../../../components/Typography/Heading5";
import { FAQS, SUPPORT_FAQS } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../constants/constants";
import { FAQ_DATA, FAQ_OPTION_ICONS, FAQ_OPTIONS } from "../../../data/faqs";
import styles from "./Faqs.module.scss";

const Faqs = () => {
  const [faqOptions, setFaqOptions] = useState(FAQ_OPTIONS);
  const [activeFaq, setActiveFaq] = useState(FAQ_OPTIONS[0]);
  const faqCategoriesRefs = useRef([]);
  const faqRefs = useRef([]);
  const tracker = useTracker();

  const faqs = FAQ_DATA;
  const FaqDropdownTitle = "Select Topic";

  const faqItems = useMemo(() => {
    return faqs.filter(({ category }) => activeFaq === category);
  }, [activeFaq, faqs]);

  const handleFaqOnChange = value => {
    if (value === FaqDropdownTitle) {
      setFaqOptions(FAQ_OPTIONS);
      setActiveFaq(FAQ_OPTIONS[0]);
    } else {
      const filteredFaqs = FAQ_OPTIONS.filter(faq => faq === value);
      setFaqOptions(filteredFaqs);
      setActiveFaq(filteredFaqs[0]);
    }

    tracker.logEvent(SUPPORT_FAQS.ON_CLICK_SELECT_TOPIC_DROPDOWN);
  };

  const handleScrollToOnClick = el => {
    setTimeout(() => {
      el.scrollIntoView({
        behavior: "smooth",
      });
    }, 200);
  };

  return (
    <Trackable interfaceId={FAQS.INTERFACE_ID}>
      <Section bgImg={jumbotronBg} shouldAnimate={false}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>FAQs</Heading5>
            <Heading1>Do you have a question?</Heading1>
            <Heading3>We’ve got you covered</Heading3>
            <Body>Select a topic to browse our frequently asked questions</Body>

            <Row>
              <Col md={6}>
                <Dropdown
                  title={FaqDropdownTitle}
                  options={FAQ_OPTIONS}
                  onChange={handleFaqOnChange}
                />
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <ScrollAnimation
          animateIn={ANIMATIONS.FADE_IN}
          delay={DEFAULT_ANIMATION_DELAY}
          animateOnce={true}
        >
          <div>
            {faqOptions.map((faq, index) => (
              <Row
                key={faq}
                className={styles["faq-container"]}
                ref={element => {
                  faqCategoriesRefs.current[index] = element;
                }}
              >
                <Col
                  md={4}
                  xs={12}
                  onClick={() => {
                    if (faqCategoriesRefs && faqCategoriesRefs.current[index]) {
                      const el = faqCategoriesRefs.current[index];
                      handleScrollToOnClick(el);
                    }
                    tracker.logEvent(SUPPORT_FAQS.ON_CLICK_TOPIC);
                  }}
                >
                  <p
                    className={classNames(
                      styles["faq-option"],
                      faq === activeFaq && styles.active
                    )}
                    onClick={() => {
                      setActiveFaq(faq);
                      tracker.logEvent(SUPPORT_FAQS.ON_CLICK_FAQS);
                    }}
                    data-faq-section
                  >
                    <span className="mr-3">{FAQ_OPTION_ICONS[faq]}</span>

                    {faq}
                  </p>
                </Col>
                <Col md={8} xs={12}>
                  {faq === activeFaq && (
                    <Accordion
                      allowZeroExpanded={true}
                      className={styles.accordion}
                    >
                      {faqItems.map(({ question, answer }, index) => (
                        <div
                          key={`${question}-${answer}`}
                          ref={element => {
                            faqRefs.current[index] = element;
                          }}
                        >
                          <AccordionItem
                            className={styles["accordion-item"]}
                            onClick={() => {
                              if (faqRefs && faqRefs.current[index]) {
                                const el = faqRefs.current[index];
                                handleScrollToOnClick(el);
                              }
                              tracker.logEvent(
                                SUPPORT_FAQS.ON_CLICK_TOPIC_QUESTION
                              );
                            }}
                          >
                            <AccordionItemHeading>
                              <AccordionItemButton
                                className={styles["accordion-button"]}
                              >
                                <span data-faq-question>{question}</span>
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel
                              className={styles["accordion-panel"]}
                            >
                              <p
                                className={styles["accordion-text"]}
                                data-faq-answer
                              >
                                {answer}
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </div>
                      ))}
                    </Accordion>
                  )}
                </Col>
              </Row>
            ))}
          </div>
        </ScrollAnimation>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};

export default Faqs;
