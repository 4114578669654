// MESSAGES
export const SALESFORCE_SUCCESS =
  "Your message has been sent, thank you for your enquiry";

// ERRORS
export const LEAD_TIME_ERROR =
  "We can’t calculate your lead-time request right now – please try again later.";

export const SALESFORCE_ERROR = "Unable to submit form, please try Again";

export const COUNTRY_NOT_FOUND = "Country not found";

export const POSTCODE_ERROR =
  "invalid origin and destination country postcodes";
export const ORIGIN_ERROR = "invalid origin country postcode";
export const DESTINATION_ERROR = "invalid destination country postcode";
export const SEARCH_RESULTS = (searchQuery, length) => {
  return (
    <p className="header-h2" style={{ margin: 0 }}>
      Your search for{" "}
      <span style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
        {searchQuery}
      </span>{" "}
      returned{" "}
      <span style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
        {length}
      </span>{" "}
      results
    </p>
  );
};

export const HELP_US_TO_IMPROVE = "Help us improve";
export const GIVE_US_DETAILS =
  "We are always trying to improve our DPD chat service. Please give us details on how we can improve our results.";
