import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";
import ReactPlayer from "react-player";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import section2Bg from "../../../assets/backgrounds/faded-purple-left-bg.png";
import section3Bg from "../../../assets/backgrounds/jumbotron-purple-right-bg.png";
import section4Bg from "../../../assets/backgrounds/purple-left-bg.png";
import calendarImg from "../../../assets/icons/change-date.png";
import calculatorImg from "../../../assets/icons/iconCalc.png";
import dpdPhoneImg from "../../../assets/icons/phone.png";
import dpdPinImg from "../../../assets/icons/pin.png";
import plantImg from "../../../assets/icons/plant.png";
import cardFMPReveal from "../../../assets/pages/our-services/dpd-classic/cardFMPReveal.png";
import video from "../../../assets/pages/our-services/dpd-classic/dpd_classic_2022.mp4";
import jumbotronBg from "../../../assets/pages/our-services/dpd-classic/jumbotron-bg.png";
import jumbotronImg from "../../../assets/pages/our-services/dpd-classic/jumbotron-img.png";
import section2Img from "../../../assets/pages/our-services/dpd-classic/section-2-img.png";
import section3Img from "../../../assets/pages/our-services/dpd-classic/section-3-img.png";
import section4Img from "../../../assets/pages/our-services/dpd-classic/section-4-img.png";
import { CardContainer } from "../../../components/CardContainer/CardContainer";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { LeadTimeCalculator } from "../../../components/LeadTimeCalculator/LeadTimeCalculator";
import { Section } from "../../../components/Section/Section";
import { AccentHotPink } from "../../../components/Typography/AccentHotPink";
import { AccentLavenderIndigo } from "../../../components/Typography/AccentLavenderIndigo";
import { AccentMint } from "../../../components/Typography/AccentMint";
import { AccentOrange } from "../../../components/Typography/AccentOrange";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { Heading6 } from "../../../components/Typography/Heading6";
import { DPD_CLASSIC } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../constants/constants";

export const DpdClassic = () => {
  const tracker = useTracker();

  return (
    <Trackable
      interfaceId={DPD_CLASSIC.INTERFACE_ID}
      loadId={DPD_CLASSIC.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>DPD Classic</Heading5>
            <Heading1>
              Giving you access to the
              <AccentOrange> largest</AccentOrange> parcel delivery network in
              Europe
            </Heading1>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={jumbotronImg} columnSpan={6} />
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              DPD Classic gives you access to one of the{" "}
              <AccentOrange> best connected</AccentOrange> and most reliable
              delivery services in Europe.
            </Heading2>
            <Body>
              Our high quality service also includes Predict notifications
              across Europe so that your customers get the best delivery
              service, wherever they are based.
            </Body>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.ZOOM_IN}
            delay={600}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardImg: dpdPhoneImg,
                  cardTitle: <Heading3>Predict</Heading3>,
                },
                {
                  cardBg: cardFMPReveal,
                  cardText: (
                    <Body>
                      The best home delivery experience for your customers
                      across Europe
                    </Body>
                  ),
                },
                {
                  cardImg: dpdPinImg,
                  cardTitle: <Heading3>In flight options</Heading3>,
                },
              ]}
              columnSpan={4}
            />
            <Col md={12}>
              <ReactPlayer
                url={video}
                width="100%"
                controls={true}
                onPlay={() => tracker.logEvent(DPD_CLASSIC.ON_CLICK_PLAY_VIDEO)}
              />
            </Col>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section2Bg}>
        <ImgContainer imgs={section2Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>DPD Classic</Heading5>
            <Heading1>
              Make your customers’ life{" "}
              <AccentLavenderIndigo> easier</AccentLavenderIndigo> anywhere
              across Europe.
            </Heading1>
            <Body>
              We’ll tell them the exact hour we’re coming, in their local
              language, giving your customers the ultimate choice and
              convenience. Keeping your customers informed is key to first-time
              delivery success no matter where they are in the world.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>In flight</Heading1>
            <Heading3>
              Put your customers
              <AccentMint> in control</AccentMint> with our in-flight delivery
              options.
            </Heading3>
            <Body>
              Preferred delivery choices vary by country, and at DPD we’ve got
              you covered. Your customers can use the flexible in-flight options
              to change the delivery day, reroute the parcel to a local Pickup
              shop or arrange for it to be left in a safe place.
            </Body>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={section3Img} columnSpan={6} />
      </Section>

      <Section bgImg={section4Bg}>
        <ImgContainer imgs={section4Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Live Tracking</Heading1>
            <Heading3>
              The coverage of our famous
              <AccentHotPink> Follow My Parcel</AccentHotPink> solution has also
              extended into Europe, with 20 countries now offering a local
              equivalent of Follow My Parcel.
            </Heading3>
            <Body>
              Live Tracking offers consumers a 15-30 minute countdown to their
              deliveries and allows them to follow the driver on a live map -
              yet another enhancement to the visibility of their order tracking.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Open your business to Europe with DPD Classic</Heading1>
            <Col md={12}>
              <ReactPlayer
                url={"https://vimeo.com/799081349/b4c2efb089"}
                width="100%"
                controls={true}
              />
            </Col>
            <Heading4>
              With our DPD Classic service, we’ve enhanced the door-to-door
              customer experience to connect you seamlessly to Europe.
            </Heading4>
            <Body>
              Our competitive lead-times, leading technology and great pricing
              mean that you don’t need to let borders limit your business.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardImg: calculatorImg,
                  cardTitle: (
                    <Heading4 classes="mt-0">
                      Accurate <br /> lead-time calculation
                    </Heading4>
                  ),
                },
                {
                  cardImg: calendarImg,
                  cardTitle: (
                    <Heading4 classes="mt-0">
                      Optimised <br /> network <br /> schedules
                    </Heading4>
                  ),
                },
                {
                  cardImg: plantImg,

                  cardText: (
                    <>
                      <Heading6>
                        All of this comes with deliveries that have been
                      </Heading6>
                      <Heading4 classes="mt-0">
                        Carbon-neutral since 2012
                      </Heading4>
                    </>
                  ),
                },
              ]}
              columnSpan={4}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <LeadTimeCalculator />

      <GetInTouch />
    </Trackable>
  );
};
