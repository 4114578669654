import { useEffect, useState } from "react";

import classNames from "classnames";
import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { postContactUsSalesForceData } from "../../apis/api";
import { ReactComponent as Close } from "../../assets/icons/iconCross.svg";
import {
  ACCOUNT_DETAILS,
  CONTACT_DETAILS,
  CONTACT_US,
  CUSTOMER_ENQUIRY,
  GET_IN_TOUCH,
  THANK_YOU,
} from "../../constants/actions";
import {
  CONTACT_DETAIL_FIELDS,
  CONTACT_US_FORM_STEPS,
  DO_NOT_USE_DPD_ENQUIRY_OPTIONS,
  USE_DPD_ENQUIRY_OPTIONS,
} from "../../constants/constants";
import { SALESFORCE_ERROR, SALESFORCE_SUCCESS } from "../../constants/strings";
import { useStore } from "../../store/store";
import styles from "./ContactUsForm.module.scss";
import { ContactUsFormBody } from "./ContactUsFormBody/ContactUsFormBody";
import { ContactUsFormFooter } from "./ContactUsFormFooter/ContactUsFormFooter";
import { isEmpty } from "../../utils/string";

export const ContactUsForm = () => {
  const [formStep, setFormStep] = useState(CONTACT_US_FORM_STEPS.INITIAL_STEP);
  const [formTitle, setFormTitle] = useState("Get in touch");
  const [successStepTitle, setSuccessStepTitle] = useState("");
  const [enquiryRadioOptions, setEnquiryRadioOptions] = useState([
    {
      name: "",
      value: "",
    },
  ]);
  const tracker = useTracker();

  const { showContactUsModal, setShowContactUsModal } = useStore();
  const { control, register, reset, getValues, setValue, watch } = useForm({
    defaultValues: {
      useDpd: "",
      account: "",
      companyTradingName: "",
      enquiry: "",
      name: "",
      phoneNumber: "",
      emailAddress: "",
      positionInCompany: "",
      company: "",
      receiveInformationAbout: "",
      confirm: "",
    },
  });
  const {
    useDpd,
    enquiry,
    confirm,
    emailAddress,
    phoneNumber,
    companyTradingName,
    positionInCompany,
    furtherInformation,
    name,
  } = watch();

  useEffect(() => {
    if (useDpd === "Yes") {
      setEnquiryRadioOptions(USE_DPD_ENQUIRY_OPTIONS);
    } else if (useDpd === "No") {
      setEnquiryRadioOptions(DO_NOT_USE_DPD_ENQUIRY_OPTIONS);
    }
  }, [useDpd]);

  const handleClose = () => {
    setShowContactUsModal(false);

    let onClickCloseActionId = GET_IN_TOUCH.ON_CLICK_CLOSE;
    if (formStep === CONTACT_US_FORM_STEPS.USE_DPD_STEP) {
      onClickCloseActionId = ACCOUNT_DETAILS.ON_CLICK_CLOSE;
    } else if (formStep === CONTACT_US_FORM_STEPS.ENQUIRY_STEP) {
      onClickCloseActionId = CUSTOMER_ENQUIRY.ON_CLICK_CLOSE;
    } else if (formStep === CONTACT_US_FORM_STEPS.CURRENT_DELIVERY_STEP) {
      onClickCloseActionId = CONTACT_US.ON_CLICK_CLOSE;
    } else if (formStep === CONTACT_US_FORM_STEPS.CONTACT_DETAILS_STEP) {
      onClickCloseActionId = CONTACT_DETAILS.ON_CLICK_CLOSE;
    } else if (formStep === CONTACT_US_FORM_STEPS.SUCCESS_STEP) {
      onClickCloseActionId = THANK_YOU.ON_CLICK_CLOSE;
    } else if (
      formStep === CONTACT_US_FORM_STEPS.QUOTE_PRODUCTS_SERVICES_STEP
    ) {
      onClickCloseActionId = CONTACT_DETAILS.ON_CLICK_CLOSE;
    }

    tracker.logEvent(onClickCloseActionId);

    // Wait for fade animation to finish before resetting state
    setTimeout(() => {
      reset();
      setFormTitle("Get in touch");
      setFormStep(CONTACT_US_FORM_STEPS.INITIAL_STEP);
    }, 200);
  };

  const handleFormSubmit = async () => {
    const values = getValues();

    const salesForceReq = {
      orgid: "00D20000000MS7w",
      retURL: "",
      "00N2X000003vXBh": values.account,
      company: values.companyTradingName,
      enquiryRadioButton: "furtherInformation",
      name: values.name,
      phone: values.phoneNumber,
      email: values.emailAddress,
      "00N4L00000A98QZ": values.positionInCompany,
      "00N4J00000IW2J9": values.receiveInformationAbout,
      "00N4L00000A98QU": values.companyTradingName,
      "00NJ5000000Nm4W": values.furtherInformation,
      confirm: values.confirm,
      submit: "Submit",
      status: "New",
      subject:
        "International contact form - Request for more information about international services",
      recordType: "0124L000000gV7P",
      type: "Other",
      "00Nw0000008pr5Q": "International Contact Form Query",
      origin: "International Contact Form",
      priority: "International Contact Form",
      "00Nw0000003ji7j": values.postcode,
      "00N2X000003vXBn": values.destination,
      "00N4J00000IVzP9": values.volume,
    };

    tracker.logEvent(CONTACT_DETAILS.ON_CLICK_SUBMIT);

    try {
      const { data } = await postContactUsSalesForceData(salesForceReq);
      setFormStep(CONTACT_US_FORM_STEPS.SUCCESS_STEP);

      if (data.success) {
        setFormTitle("Thank You");
        setSuccessStepTitle(SALESFORCE_SUCCESS);
      }
    } catch (error) {
      setFormTitle("Error");
      setSuccessStepTitle(SALESFORCE_ERROR);
    }
  };

  const disableSubmitBtn = (uniqueForm = false, fieldsParam) => {
    const isFieldEmpty = field => isEmpty(field);
    const isDpdYesAndFieldEmpty = field =>
      useDpd === "Yes" && isFieldEmpty(field);

    const defaultFields = [emailAddress, phoneNumber, furtherInformation, name];
    const dpdFields = [positionInCompany];

    const fields = uniqueForm ? fieldsParam : defaultFields;

    const isAnyFieldEmpty = fields.some(isFieldEmpty);
    const isAnyDpdFieldEmpty = dpdFields.some(isDpdYesAndFieldEmpty);

    return !confirm || isAnyFieldEmpty || isAnyDpdFieldEmpty;
  };

  return (
    <Modal
      className={styles["contact-us-form"]}
      show={showContactUsModal}
      onHide={handleClose}
      centered
    >
      <form onSubmit={handleFormSubmit}>
        <Modal.Header className="border-0 py-1">
          <h2
            className={classNames(
              "px-4 mt-5 mb-3",
              styles["contact-us-form-title"]
            )}
          >
            {formTitle}
          </h2>

          <Close
            className={classNames("mt-2", styles.close)}
            onClick={handleClose}
          />
        </Modal.Header>
        <input type="hidden" name="orgid" value="00D20000000MS7w" />
        <input type="hidden" name="retURL" value="" />

        {formStep === CONTACT_US_FORM_STEPS.INITIAL_STEP && (
          <Trackable
            interfaceId={GET_IN_TOUCH.INTERFACE_ID}
            loadId={GET_IN_TOUCH.ON_LOAD}
          >
            <ContactUsFormBody title="Do you currently use DPD for your international shipments?">
              {["Yes", "No"].map(radioOption => (
                <label
                  htmlFor={radioOption}
                  className={styles["radio-btn"]}
                  key={radioOption}
                >
                  <input
                    {...register("useDpd", { required: true })}
                    type="radio"
                    name={"useDpd"}
                    id={radioOption}
                    value={radioOption}
                    onClick={() =>
                      tracker.logEvent(GET_IN_TOUCH.ON_CLICK_RADIO_BUTTON)
                    }
                  />
                  <span>{radioOption}</span>
                </label>
              ))}
            </ContactUsFormBody>
            <ContactUsFormFooter
              nextBtnOnClick={() => {
                const stepValue =
                  useDpd === "Yes"
                    ? CONTACT_US_FORM_STEPS.USE_DPD_STEP
                    : CONTACT_US_FORM_STEPS.ENQUIRY_STEP;
                setFormStep(stepValue);
                tracker.logEvent(GET_IN_TOUCH.ON_CLICK_NEXT);
              }}
              nextBtnDisabled={!useDpd}
            />
          </Trackable>
        )}

        {formStep === CONTACT_US_FORM_STEPS.USE_DPD_STEP && (
          <Trackable
            interfaceId={ACCOUNT_DETAILS.INTERFACE_ID}
            loadId={ACCOUNT_DETAILS.ON_LOAD}
          >
            <ContactUsFormBody title="Please provide account number and/or trading name">
              <Form.Group className="mb-3">
                <Form.Label>Account Number</Form.Label>
                <Controller
                  control={control}
                  name="account"
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      onChange={onChange}
                      onClick={() =>
                        tracker.logEvent(ACCOUNT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                      value={value}
                      ref={ref}
                    />
                  )}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Company Trading Name*</Form.Label>
                <Controller
                  control={control}
                  name="companyTradingName"
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Control
                      onChange={onChange}
                      onClick={() =>
                        tracker.logEvent(ACCOUNT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                      value={value}
                      ref={ref}
                    />
                  )}
                />
              </Form.Group>
            </ContactUsFormBody>
            <ContactUsFormFooter
              backBtnOnClick={() => {
                setFormStep(CONTACT_US_FORM_STEPS.INITIAL_STEP);
                tracker.logEvent(ACCOUNT_DETAILS.ON_CLICK_BACK);
              }}
              nextBtnOnClick={() => {
                if (!isEmpty(companyTradingName)) {
                  setFormStep(CONTACT_US_FORM_STEPS.ENQUIRY_STEP);
                  tracker.logEvent(ACCOUNT_DETAILS.ON_CLICK_NEXT);
                }
              }}
              nextBtnDisabled={isEmpty(companyTradingName)}
            />
          </Trackable>
        )}

        {formStep === CONTACT_US_FORM_STEPS.ENQUIRY_STEP && (
          <Trackable
            interfaceId={CUSTOMER_ENQUIRY.INTERFACE_ID}
            loadId={CUSTOMER_ENQUIRY.ON_LOAD}
          >
            <ContactUsFormBody title="What is your enquiry about?">
              {enquiryRadioOptions.map(({ name, value }) => (
                <label
                  key={name}
                  htmlFor={name}
                  className={styles["radio-btn"]}
                >
                  <input
                    {...register("enquiry", { required: true })}
                    type="radio"
                    name={"enquiry"}
                    id={name}
                    value={value}
                    onClick={() =>
                      tracker.logEvent(CUSTOMER_ENQUIRY.ON_CLICK_RADIO_BUTTON)
                    }
                  />
                  <span>{name}</span>
                </label>
              ))}
            </ContactUsFormBody>

            <ContactUsFormFooter
              backBtnOnClick={() => {
                const stepValue =
                  useDpd === "Yes"
                    ? CONTACT_US_FORM_STEPS.USE_DPD_STEP
                    : CONTACT_US_FORM_STEPS.INITIAL_STEP;
                setFormStep(stepValue);
                setValue("enquiry", "");
              }}
              nextBtnOnClick={() => {
                if (enquiry === "currentCollectionDelivery") {
                  setFormStep(CONTACT_US_FORM_STEPS.CURRENT_DELIVERY_STEP);
                } else if (enquiry === "bookingShipment") {
                  window.open("https://www.dpdlocal-online.co.uk/", "_blank");
                } else if (enquiry === "quoteProductsServices") {
                  setFormStep(
                    CONTACT_US_FORM_STEPS.QUOTE_PRODUCTS_SERVICES_STEP
                  );
                } else {
                  setFormStep(CONTACT_US_FORM_STEPS.CONTACT_DETAILS_STEP);
                }

                tracker.logEvent(CUSTOMER_ENQUIRY.ON_CLICK_NEXT);
              }}
              nextBtnDisabled={!enquiry}
            />
          </Trackable>
        )}

        {formStep === CONTACT_US_FORM_STEPS.CURRENT_DELIVERY_STEP && (
          <Trackable
            interfaceId={CONTACT_US.INTERFACE_ID}
            loadId={CONTACT_US.ON_LOAD}
          >
            <ContactUsFormBody title="If you want to discuss a collection or delivery that is currently in progress then please call 0121 336 4691" />
            <ContactUsFormFooter
              backBtnOnClick={() => {
                setFormStep(CONTACT_US_FORM_STEPS.ENQUIRY_STEP);
                tracker.logEvent(CONTACT_US.ON_CLICK_BACK);
              }}
            />
          </Trackable>
        )}

        {formStep === CONTACT_US_FORM_STEPS.QUOTE_PRODUCTS_SERVICES_STEP && (
          <Trackable
            interfaceId={CONTACT_DETAILS.INTERFACE_ID}
            loadId={CONTACT_DETAILS.ON_LOAD}
          >
            <ContactUsFormBody title="Please provide your contact details">
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />
              <Controller
                control={control}
                name="emailAddress"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      type="email"
                      pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i"
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />
              <Controller
                control={control}
                name="postcode"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Collection Postcode*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />

              <Controller
                control={control}
                name="destination"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>International Destination*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />

              <Controller
                control={control}
                name="volume"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Volume per month*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />

              <Controller
                control={control}
                name="furtherInformation"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Products Shipped and Any other information*
                    </Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />

              <Controller
                control={control}
                name="confirm"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Check
                    type={"checkbox"}
                    label={
                      "By checking this box you confirm that you are happy for DPD to contact you in regards to our shipping services"
                    }
                    onChange={onChange}
                    value={value}
                    ref={ref}
                    onClick={() =>
                      tracker.logEvent(CONTACT_DETAILS.ON_CLICK_CHECKBOX)
                    }
                  />
                )}
              />
            </ContactUsFormBody>
            <ContactUsFormFooter
              backBtnOnClick={() => {
                setFormStep(CONTACT_US_FORM_STEPS.ENQUIRY_STEP);
                reset({
                  ...CONTACT_DETAIL_FIELDS.reduce(
                    (acc, field) => ({ ...acc, [field]: "" }),
                    {}
                  ),
                });
                tracker.logEvent(CONTACT_DETAILS.ON_CLICK_BACK);
              }}
              nextBtnOnClick={handleFormSubmit}
              nextBtnText="Submit"
              nextBtnDisabled={disableSubmitBtn(true, [
                getValues("name"),
                getValues("emailAddress"),
                getValues("postcode"),
                getValues("destination"),
                getValues("volume"),
                getValues("furtherInformation"),
              ])}
            />
          </Trackable>
        )}

        {formStep === CONTACT_US_FORM_STEPS.CONTACT_DETAILS_STEP && (
          <Trackable
            interfaceId={CONTACT_DETAILS.INTERFACE_ID}
            loadId={CONTACT_DETAILS.ON_LOAD}
          >
            <ContactUsFormBody title="Please provide your contact details">
              <Controller
                control={control}
                name="name"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Name*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />
              <Controller
                control={control}
                name="phoneNumber"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Phone Number*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />
              <Controller
                control={control}
                name="emailAddress"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>Email Address*</Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      type="email"
                      pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i"
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />
              {useDpd === "Yes" && (
                <Controller
                  control={control}
                  name="positionInCompany"
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Group className="mb-3">
                      <Form.Label>Position in Company*</Form.Label>
                      <Form.Control
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        onClick={() =>
                          tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                        }
                      />
                    </Form.Group>
                  )}
                />
              )}

              {useDpd === "No" && (
                <Controller
                  control={control}
                  name="companyTradingName"
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Group className="mb-3">
                      <Form.Label>Company</Form.Label>
                      <Form.Control
                        onChange={onChange}
                        value={value}
                        ref={ref}
                        onClick={() =>
                          tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                        }
                      />
                    </Form.Group>
                  )}
                />
              )}
              <Controller
                control={control}
                name="furtherInformation"
                defaultValue=""
                render={({ field: { onChange, value, ref } }) => (
                  <Form.Group className="mb-3">
                    <Form.Label>
                      Please specify what you would like more information on*
                    </Form.Label>
                    <Form.Control
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_TEXT_FIELD)
                      }
                    />
                  </Form.Group>
                )}
              />
              {formStep === CONTACT_US_FORM_STEPS.CONTACT_DETAILS_STEP && (
                <Controller
                  control={control}
                  name="confirm"
                  defaultValue=""
                  render={({ field: { onChange, value, ref } }) => (
                    <Form.Check
                      type={"checkbox"}
                      label={
                        "By checking this box you confirm that you are happy for DPD to contact you in regards to our shipping services"
                      }
                      onChange={onChange}
                      value={value}
                      ref={ref}
                      onClick={() =>
                        tracker.logEvent(CONTACT_DETAILS.ON_CLICK_CHECKBOX)
                      }
                    />
                  )}
                />
              )}
            </ContactUsFormBody>
            <ContactUsFormFooter
              backBtnOnClick={() => {
                setFormStep(CONTACT_US_FORM_STEPS.ENQUIRY_STEP);
                reset({
                  ...CONTACT_DETAIL_FIELDS.reduce(
                    (acc, field) => ({ ...acc, [field]: "" }),
                    {}
                  ),
                });
                tracker.logEvent(CONTACT_DETAILS.ON_CLICK_BACK);
              }}
              nextBtnOnClick={handleFormSubmit}
              nextBtnText="Submit"
              nextBtnDisabled={disableSubmitBtn()}
            />
          </Trackable>
        )}

        {formStep === CONTACT_US_FORM_STEPS.SUCCESS_STEP && (
          <Trackable
            interfaceId={THANK_YOU.INTERFACE_ID}
            loadId={THANK_YOU.ON_LOAD}
          >
            <ContactUsFormBody title={successStepTitle} />
          </Trackable>
        )}
      </form>
    </Modal>
  );
};
