import classNames from "classnames";
import PropTypes from "prop-types";

export const Header3 = ({ children, classes }) => {
  return <h3 className={classNames(classes, "header-h3")}>{children}</h3>;
};

Header3.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
