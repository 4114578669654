import { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Card } from "react-bootstrap";
import ReactCardFlip from "react-card-flip";

import variables from "../../styles/colors.scss";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import styles from "./InfoCard.module.scss";

export const InfoCard = ({
  cardImg,
  cardTitle,
  cardText,
  cardBackTitle,
  cardBackText,
  cardBtnTxt,
  cardBtnLink,
  externalLink = false,
  cardBg,
  cardBgColor = variables.darkShade1,
  imgHeight = "75px",
  imgWidth = "75px",
  shouldFlip = false,
  dpdRedButton,
  cardHoverFn = () => {},
  cardBtnOnClick = () => {},
  cardBgClassName,
}) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const [externalLinkIconFill, setExternalLinkIconFill] = useState("#f8f9fa");

  const background = cardBg ? `url(${cardBg}) no-repeat` : cardBgColor;
  const cardStyle = {
    background,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const card = (
    <>
      {cardImg && (
        <div className="mt-4">
          <Card.Img
            className={classNames("mx-auto", styles["info-card-img"])}
            src={cardImg}
            style={{ height: imgHeight, width: imgWidth }}
          />
        </div>
      )}
      <Card.Body
        className={classNames(
          "d-flex",
          "flex-column",
          "align-items-center",
          "justify-content-between",
          cardBgClassName
        )}
        style={cardStyle}
      >
        {cardTitle}
        {cardText}
        {cardBtnTxt && (
          <CallToActionBtn
            variant={dpdRedButton ? undefined : "outline-light"}
            btnLink={cardBtnLink}
            externalBtnLink={externalLink}
            onMouseLeave={() => setExternalLinkIconFill("#f8f9fa")}
            onMouseEnter={() => setExternalLinkIconFill("#212529")}
            onClick={cardBtnOnClick}
          >
            {cardBtnTxt}
            {externalLink && (
              <span className={"ml-2"}>
                <svg
                  width="17"
                  height="18"
                  viewBox="0 0 17 18"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.0156 11.6221C12.5757 11.6221 12.2188 11.979 12.2188 12.4189V16.1377C12.2188 16.284 12.0994 16.4033 11.9531 16.4033H1.85938C1.71308 16.4033 1.59375 16.284 1.59375 16.1377V6.04395C1.59375 5.89765 1.71308 5.77832 1.85938 5.77832H5.57812C6.01973 5.77832 6.375 5.42305 6.375 4.98145C6.375 4.53984 6.01973 4.18457 5.57812 4.18457H1.85938C0.834395 4.18457 0 5.01797 0 6.04395V16.1377C0 17.1637 0.834395 17.9971 1.85938 17.9971H11.9531C12.9784 17.9971 13.8125 17.163 13.8125 16.1377V12.4189C13.8125 11.9807 13.4572 11.6221 13.0156 11.6221ZM16.2031 0.99707H10.625C10.1851 0.99707 9.82812 1.354 9.82812 1.79395C9.82812 2.23389 10.1867 2.59082 10.625 2.59082H14.2807L6.07617 10.792C5.76489 11.1033 5.76489 11.6075 6.07617 11.9189C6.23223 12.077 6.43477 12.1533 6.64062 12.1533C6.84648 12.1533 7.04836 12.0755 7.20408 11.9199L15.4062 3.71773V7.37207C15.4062 7.81201 15.7632 8.16895 16.2031 8.16895C16.6431 8.16895 17 7.81367 17 7.37207V1.79395C17 1.354 16.6447 0.99707 16.2031 0.99707Z"
                    fill={externalLinkIconFill}
                  />
                </svg>
              </span>
            )}
          </CallToActionBtn>
        )}
      </Card.Body>
    </>
  );

  return (
    <Card
      className={classNames("h-100 w-100", styles["info-card"])}
      style={{
        background,
      }}
      onMouseEnter={() => {
        setIsFlipped(true);
        cardHoverFn();
      }}
      onMouseLeave={() => setIsFlipped(false)}
    >
      {shouldFlip && (
        <ReactCardFlip isFlipped={isFlipped} flipDirection="horizontal">
          <div>{card}</div>
          <Card.Body
            className={classNames(
              "d-flex",
              "flex-column",
              "align-items-center",
              "justify-content-center",
              cardBgClassName
            )}
            style={cardStyle}
          >
            {cardBackTitle}
            {cardBackText}
          </Card.Body>
        </ReactCardFlip>
      )}
      {!shouldFlip && card}
    </Card>
  );
};

InfoCard.propTypes = {
  cardTitle: PropTypes.any,
  cardText: PropTypes.any,
  cardBackTitle: PropTypes.any,
  cardBackText: PropTypes.any,
  cardImg: PropTypes.string,
  cardBtnTxt: PropTypes.string,
  cardBtnLink: PropTypes.string,
  externalLink: PropTypes.bool,
  cardBg: PropTypes.string,
  cardBgColor: PropTypes.string,
  imgHeight: PropTypes.string,
  imgWidth: PropTypes.string,
  shouldFlip: PropTypes.bool,
  dpdRedButton: PropTypes.bool,

  listItems: PropTypes.arrayOf(PropTypes.any),
  listIcon: PropTypes.node,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  cardHoverFn: PropTypes.func,
  cardBtnOnClick: PropTypes.func,
  cardBgClassName: PropTypes.string,
};
