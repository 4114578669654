import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useTracker } from "@dpdgroupuk/react-event-tracker";
import { getCountryData } from "../../apis/api";
import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { ReactComponent as Search } from "../../assets/icons/search-red.svg";
import { WORLD_MAP } from "../../constants/actions";
import { COUNTRY_NOT_FOUND } from "../../constants/strings";
import { useStore } from "../../store/store";
import { CountryCardRecentSearches } from "../CountryCardRecentSearches/CountryCardRecentSearches";
import { CountrySearchError } from "../CountrySearchError/CountrySearchError";
import styles from "./CountryCardInput.module.scss";

export const CountryCardInput = ({ setInputFocused }) => {
  const {
    country,
    setCountry,
    resetCountry,
    setIsLoading,
    recentSearches,
    setRecentSearches,
    resetCountrySideDrawer,
    errorCountry,
    setErrorCountry,
  } = useStore();
  const [countryValue, setCountryValue] = useState("");
  const [error, setError] = useState("");
  const tracker = useTracker();

  useEffect(() => {
    if (country) {
      setCountryValue(country?.marketInfo.countryName);
    } else {
      setCountryValue("");
    }
  }, [country]);

  useEffect(() => {
    if (countryValue === "") {
      setError("");
    }
  }, [countryValue]);

  useEffect(() => {
    if (error) {
      setErrorCountry(countryValue);
    }
  }, [error]);

  const handleResetForm = () => {
    setCountryValue("");
    setError("");

    if (country) {
      resetCountry();
      setIsLoading(false);
    }
  };

  const handleSubmitForm = async e => {
    e.preventDefault();
    setError("");
    resetCountrySideDrawer();

    if (
      countryValue.trim() === country?.marketInfo.countryName.trim() ||
      !countryValue
    ) {
      return;
    }

    const searchAlreadyExists = recentSearches.find(
      search =>
        search?.marketInfo.countryName.toLowerCase() ===
        countryValue.toLowerCase()
    );

    if (searchAlreadyExists) {
      setCountry(searchAlreadyExists);
      return;
    }

    resetCountry();
    setIsLoading(true);

    try {
      const { data } = await getCountryData(countryValue);
      const {
        deliveryOptions,
        exportInfo,
        marketInfo,
        opportunities,
        imagePath,
      } = data;

      const countryData = {
        marketInfo,
        countryOpportunity: opportunities,
        exportInfo,
        deliveryOptions,
        imagePath,
      };

      if (countryData) {
        setCountry(countryData);
        setRecentSearches(countryData);
        setIsLoading(false);
      }
    } catch (error) {
      setError(COUNTRY_NOT_FOUND);
      setIsLoading(false);
    }
  };

  const onEnter = e => {
    if (e.key === "Enter") {
      e.target.blur();
    }
  };

  return (
    <Container className="mt-3">
      <Form onSubmit={handleSubmitForm}>
        <Row>
          <Col md={10} xs={10}>
            <input
              className={styles["country-search-input"]}
              value={countryValue}
              onChange={e => setCountryValue(e.target.value)}
              placeholder="Enter destination country"
              onKeyUp={onEnter}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              onClick={() => tracker.logEvent(WORLD_MAP.ON_CLICK_SEARCH_BOX)}
            />
            <div className={styles["country-search-input-btn"]}>
              <Search onClick={handleSubmitForm} />
            </div>
          </Col>
          <Col md={2} xs={2}>
            <div className="d-flex align-items-center justify-content-end h-100">
              <Button
                variant="light"
                className="btn-circle"
                onClick={handleResetForm}
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <Close />
              </Button>
            </div>
          </Col>
        </Row>
      </Form>

      {(error || errorCountry) && !country && <CountrySearchError />}

      {!country && <CountryCardRecentSearches />}
    </Container>
  );
};

CountryCardInput.propTypes = {
  setInputFocused: PropTypes.func,
};
