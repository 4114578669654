import { useState } from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Card, Container } from "react-bootstrap";

import { COUNTRY_CARD_NAV_LINK_TEXT } from "../../constants/constants";
import { getCountryImgUrl } from "../../helpers/helpers";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import { useStore } from "../../store/store";
import { CountryCardDeliveryOptions } from "../CountryCardDeliveryOptions/CountryCardDeliveryOptions";
import { CountryCardExportInfo } from "../CountryCardExportInfo/CountryCardExportInfo";
import { CountryCardInput } from "../CountryCardInput/CountryCardInput";
import { CountryCardMarketInfo } from "../CountryCardMarketInfo/CountryCardMarketInfo";
import { CountryCardNav } from "../CountryCardNav/CountryCardNav";
import styles from "./CountryCard.module.scss";

export const CountryCard = ({ mapLoaded, inputFocused, setInputFocused }) => {
  const [activeLink, setActiveLink] = useState("Delivery Options");
  const { country, isCountrySideDrawerOpen } = useStore();
  const isMobileDevice = useMediaQuery("(max-width: 480px)");

  const bgUrl = country && getCountryImgUrl(country.imagePath);

  const inputBg = country ? `url(${bgUrl})` : "";
  const inputHeight = country ? "250px" : "";

  // TODO: Remove this once all countries have images
  const inputColor = country
    ? country.imagePath
      ? ""
      : "rgba(57, 67, 79, 0.5)"
    : "";

  return (
    <>
      {mapLoaded && (
        <div
          className={classNames(
            styles["country-container"],
            "animate__animated animate__fadeInUp"
          )}
          style={{
            marginTop:
              isMobileDevice && !inputFocused
                ? "450px"
                : isMobileDevice && inputFocused
                ? "300px"
                : "20px",
            marginBottom:
              isMobileDevice && isCountrySideDrawerOpen ? "150px" : "",
            height: isMobileDevice && isCountrySideDrawerOpen ? "700px" : "",
          }}
        >
          <Card className={styles["country-card"]}>
            <div
              className={styles["country-card-input-bg"]}
              style={{
                backgroundColor: inputColor,
                backgroundImage: inputBg,
                height: inputHeight,
              }}
            >
              <Container>
                <CountryCardInput setInputFocused={setInputFocused} />
              </Container>
            </div>
            <Container
              className={classNames("mt-3", styles["country-card-content"])}
            >
              {country && (
                <>
                  <CountryCardNav
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                  />

                  {activeLink ===
                    COUNTRY_CARD_NAV_LINK_TEXT.DELIVERY_OPTIONS && (
                    <CountryCardDeliveryOptions />
                  )}

                  {activeLink === COUNTRY_CARD_NAV_LINK_TEXT.MARKET_INFO && (
                    <CountryCardMarketInfo />
                  )}

                  {activeLink === COUNTRY_CARD_NAV_LINK_TEXT.EXPORT_INFO && (
                    <CountryCardExportInfo />
                  )}
                </>
              )}
            </Container>
          </Card>
        </div>
      )}
    </>
  );
};

CountryCard.propTypes = {
  mapLoaded: PropTypes.bool,
  inputFocused: PropTypes.bool,
  setInputFocused: PropTypes.func,
};
