import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";

import styles from "./CountryIcons.module.scss";

export const CountryIcons = ({ icons }) => {
  return (
    <Row className="pt-2">
      {icons.map(({ icon, title, body }, index) => (
        <React.Fragment key={`${icon} - ${index}`}>
          <Col md={2}>{icon}</Col>
          <Col md={10} className="pb-2">
            <h4 className={classNames(styles.icon)}>{title}</h4>
            <p className={classNames(styles["icon-text"])}>{body}</p>
          </Col>
        </React.Fragment>
      ))}
    </Row>
  );
};

CountryIcons.propTypes = {
  icons: PropTypes.any,
};
