import { useMemo } from "react";

import { Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { NAV_LINKS } from "../../router/constants";
import { NavBarLink } from "../NavBarLink/NavBarLink";
import styles from "./SecondaryNarBar.module.scss";

export const SecondaryNarBar = () => {
  const { pathname } = useLocation();

  const secondaryLinks = useMemo(() => {
    const baseUrl = `/${pathname.split("/")[1]}`;
    const currentNavLink = NAV_LINKS.filter(link => link.href === baseUrl)[0];

    if (currentNavLink && "secondaryLinks" in currentNavLink) {
      const { secondaryLinks } = currentNavLink;
      return secondaryLinks;
    }

    return [];
  }, [pathname]);

  return (
    <Navbar className={styles["mydpd-secondary-menu"]}>
      <Nav
        className={
          "mydpd-primary-nav mx-auto justify-content-between mydpd-nav navbar-nav"
        }
      >
        {secondaryLinks &&
          secondaryLinks.map(({ href, name, actionId }) => (
            <NavBarLink
              key={href}
              href={href}
              name={name}
              actionId={actionId}
            />
          ))}
      </Nav>
    </Navbar>
  );
};
