import { isMobile } from "react-device-detect";
import truckIcon from "../assets/pages/chat/widget/local-shipping.svg";
import cubeIcon from "../assets/pages/chat/widget/logo-white.svg";
import personIcon from "../assets/pages/chat/widget/person-play.svg";
import video from "../assets/pages/international/how-to-export/dpd_customs_clearance_v1.mp4";
import * as routes from "../router/constants";

export const ALIGNMENT = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right",
};

// WORLD MAP
export const LONDON_COORDINATES = [-0.091998, 51.515618];
export const ORIGIN_LAYER_COLOR = "#919090";
export const DESTINATION_LAYER_COLOR = "#DDE2E6";
export const STRATEGIC_LOCATION_LAYER_COLOR = "#DEE2E6";

// COUNTRY CARD
export const COUNTRY_CARD_NAV_LINK_TEXT = {
  DELIVERY_OPTIONS: "Delivery Options",
  EXPORT_INFO: "Export Info",
  MARKET_INFO: "Market Info",
};

export const COUNTRY_CARD_NAV_LINKS = [
  COUNTRY_CARD_NAV_LINK_TEXT.DELIVERY_OPTIONS,
  COUNTRY_CARD_NAV_LINK_TEXT.EXPORT_INFO,
  COUNTRY_CARD_NAV_LINK_TEXT.MARKET_INFO,
];

// UI
export const SECTION_HEIGHT = "900px";
export const DEFAULT_ANIMATION_DELAY = !isMobile ? 300 : 50;

// WORLD MAP UI
export const DELIVERY_OPTIONS_CONTENT_TYPES = {
  AIR_EXPRESS: "airExpress",
  AIR_CLASSIC: "airClassic",
  DPD_DIRECT: "dpdDirect",
  ROAD_CLASSIC: "roadClassic",
  DPD_DIRECT_LITE: "dpdDirectLite",
};

export const EXPORT_INFO_CONTENT_TYPES = {
  CUSTOMS_DATA: "customsData",
  DPD_GOODS: "dpdGoods",
  DUTIES_TAXES: "dutiesTaxes",
};

export const MARKET_INFO_CONTENT_TYPES = {
  COUNTRY_OPPORTUNITIES: "countryOpportunity",
  INTERNATIONAL_DELIVERY_EXPERTS: "internationalDeliveryExperts",
};

// ANIMATIONS
export const ANIMATIONS = {
  FADE_IN: "fadeIn",
  FADE_IN_IMAGE: "fadeInImage",
  FADE_IN_UP: "fadeInUp",
  FADE_IN_LEFT: "fadeInLeft",
  FADE_IN_RIGHT: "fadeInRight",
  ZOOM_IN: "zoomIn",
};

export const FADE_IN = "fadeIn";
export const FADE_IN_UP = "fadeInUp";
export const FADE_IN_LEFT = "fadeInLeft";
export const FADE_IN_RIGHT = "fadeInRight";
export const ZOOM_IN = "zoomIn";

export const CURRENCY = {
  EURO: "€",
};

export const FILTER_DEFAULT_VALUES = {
  COUNTRY: "Region / Country",
  CATEGORY: "Category",
  DATE: "Date Published",
};

// CONTACT US FORM
export const CONTACT_US_FORM_STEPS = {
  INITIAL_STEP: "initial",
  USE_DPD_STEP: "useDpd",
  ENQUIRY_STEP: "enquiry",
  CURRENT_DELIVERY_STEP: "currentDelivery",
  CONTACT_DETAILS_STEP: "contactDetails",
  SUCCESS_STEP: "success",
};

export const USE_DPD_ENQUIRY_OPTIONS = [
  {
    name: "A current collection or delivery",
    value: "currentCollectionDelivery",
  },
  {
    name: "Further information about our international services?",
    value: "furtherInformation",
  },
  {
    name: "Booking a shipment",
    value: "bookingShipment",
  },
];

export const DO_NOT_USE_DPD_ENQUIRY_OPTIONS = [
  {
    name: "Further information about our international services?",
    value: "furtherInformation",
  },
  {
    name: "Help understanding customs clearance",
    value: "helpUnderstaindCustoms",
  },
  {
    name: "A quote on DPD international products and services?",
    value: "quoteProductsServices",
  },
  {
    name: "Send a one-off international shipment",
    value: "bookingShipment",
  },
  {
    name: "Support on international shipping",
    value: "supportAdvise",
  },
];

export const CONTACT_DETAIL_FIELDS = [
  "name",
  "phoneNumber",
  "emailAddress",
  "positionInCompany",
  "company",
  "receiveInformationAbout",
];

export const CONTACT_US_SALEFORCE_IDS = {
  orgid: "00D20000000MS7w",
  account: "00N2X000003vXBh",
  positionInCompany: "00N4L00000A98QZ",
  company: "00N4L00000A98QU",
  receiveInformationAbout: "00N4J00000IW2J9",
};

export const LEAD_TIME_COUNTRIES = [
  "Austria",
  "Belgium",
  "Bosnia",
  "Bulgaria",
  "Croatia",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Romania",
  "Serbia",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
];

export const FILTERS = {
  ALL: "all",
  ABOUT: "about",
  SERVICES: "services",
  INSIGHT: "international",
  SUPPORT: "support",
};

export const FILTER_ITEMS = [
  "All",
  "About",
  "Our Services",
  "Insight & Info",
  "Support",
];

export const DPD_WORD_LIST = ["geopost", "dpd", "asendia"];

export const MOST_POPULAR = [
  {
    value: "Export",
    link: "/international/export-fundamentals",
  },
  {
    value: "Duties & Tax",
    link: "/international/export-fundamentals/your-guide-to-duties",
  },
  {
    value: "Our Services",
    link: "/services",
  },
  {
    value: "Commodity Codes",
    link: "/international/export-fundamentals/your-guide-to-duties?scrollTo=Calculating Duties and Taxes",
  },
  {
    value: "Packaging",
    link: "/international/export-fundamentals/how-to-export?scrollTo=Packaging your products",
  },
  {
    value: "Commercial Invoice",
    link: "/international/export-fundamentals/how-to-export?scrollTo=What is Electronic Customs Data and a Commercial Invoice?",
  },
];

export const MAX_MESSAGES_LENGTH = 10;
export const MAX_QUESTION_LENGTH = 256;

export const FEEDBACK_TYPE = {
  NONE: 0,
  LIKE: 1,
  DISLIKE: 2,
};

export const MIN_USER_MESSAGE_LENGTH = 2;

export const CARD_TYPES = {
  FMP: "FMP",
  SUGGESTION: "SUGGESTION",
  INFO: "INFO",
  MAP: "MAP",
  ERROR: "ERROR",
  COMMODITY: "COMMODITY",
  EORI: "EORI",
  SERVICES: "SERVICES",
  VAT: "VAT",
  LEAD_TIME: "LEAD_TIME",
  ABOUT_US: "ABOUT_US",
  DUTIES_TAX: "DUTIES_TAX",
  ELECTRONIC_CUSTOMS_DATA: "ELECTRONIC_CUSTOMS_DATA",
};

export const TAB_NAMES = {
  [CARD_TYPES.FMP]: "Follow My Parcel",
  GENERAL: "General",
};

export const SUGGESTIONS = [
  {
    title: "Parcel tracking",
    icon: truckIcon,
    suggestions: ["Where’s my parcel?", "How much to ship to America?"],
  },
  {
    title: "About DPD",
    icon: cubeIcon,
    suggestions: ["Tell me about DPD UK", "Tell me about Geopost"],
  },
  {
    title: "General questions",
    icon: personIcon,
    suggestions: ["What is the fastest animal?", "Who won the world cup?"],
  },
];

export const MAP_CARD_TABS = {
  SERVICES: "Services",
  EXPORT: "Export",
  MARKET: "Market",
};

export const MAP_CARD_TABS_NAMES = [
  MAP_CARD_TABS.SERVICES,
  MAP_CARD_TABS.EXPORT,
  MAP_CARD_TABS.MARKET,
];
export const CHAT_ROLES = {
  USER: "user",
  ASSISTANT: "assistant",
};

export const SERVICES_CARD_CONTENT = {
  title: "Our services",
  links: [
    {
      title: "DPD Classic",
      description:
        "One of the fastest, most reliable delivery services in Europe",
      url: routes.DPD_CLASSIC,
    },
    {
      title: "DPD Direct",
      description:
        "Our global express network offering an unrivalled service to more than 200 countries worldwide",
      url: routes.DPD_DIRECT,
    },
    {
      title: "Air Classic",
      description:
        "Our international service for those non-time sensitive shipments that still require full tracking",
      url: routes.AIR_CLASSIC,
    },
    {
      title: "Air Express",
      description:
        "DPD's global express network offers an unrivalled service to more than 200 countries worldwide",
      url: routes.AIR_EXPRESS,
    },
  ],
};

export const ELECTRONIC_CUSTOMS_CARD_CONTENT = {
  title: "Electronic customs data",
  description:
    "Watch our short video about customs clearance or read our guide on Electronic Customs Data",
  media: {
    url: video,
  },
  links: [
    {
      title: "Electronic Customs Data Guide",
      description: "Start download (1.24mb)",
      type: "pdf",
      url: routes.CUSTOM_DETAILS,
    },
  ],
};

export const VAT_WIDGET_CONTENT = {
  title: "VAT information",
  links: [
    {
      title: "European Union VAT rates ",
      description: "Start download (2.6mb)",
      type: "pdf",
      url: "https://ec.europa.eu/taxation_customs/system/files/2020-10/vat_rates_en.pdf",
    },
    {
      title: "HMRC online services for VAT import refunds",
      url: "https://www.gov.uk/log-in-register-hmrc-online-services",
    },
  ],
};

export const ABOUT_US_CONTENT = {
  title: "About us",
  media: {
    url: "RQ5xqAEtl6o",
    type: "youtubeEmbed",
  },
};

export const DUTIES_TAX_CONTENT = {
  title: "Duties tax information",
  links: [
    {
      title: "Trade Tariff: look up commodity codes, duty and VAT rates",
      url: "https://www.gov.uk/trade-tariff",
    },
  ],
};
