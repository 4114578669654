import ScrollAnimation from "react-animate-on-scroll";
import { ANIMATIONS } from "../../constants/constants";
import { Col, Container } from "react-bootstrap";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading4 } from "../Typography/Heading4";
import { ImgContainer } from "../ImgContainer/ImgContainer";
import asendiaImg from "../../assets/pages/our-services/dpd-direct/asendia.png";

function DpdDirectLiteDeliveryOption() {
  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container>
          <Col md={12}>
            <Heading3>DPD Direct Lite</Heading3>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              Expand your online business and accelerate your growth with our
              international e-commerce delivery solution, delivered in
              partnership with Asendia
            </Heading4>
            <ImgContainer imgs={asendiaImg} columnSpan={6} />
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              DPD Direct Lite is our most budget-friendly option for shipping
              packages internationally, ideal for Customers shipping low-value
              parcels.
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              This service carried out by the local postal operator, ensuring
              both reliability and excellent value for your money, DPD Direct
              Lite provides a simple solution, available worldwide, and with
              tracking for most destinations.
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Take advantage of our tracked postal options. Owned by La Poste
              one of the world’s largest and leading postal authorities, Geopost
              enjoys direct access to the global postal solutions, so you know
              you are in safe hands.
            </Body>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
}

export default DpdDirectLiteDeliveryOption;
