import ScrollAnimation from "react-animate-on-scroll";
import { CardContainer } from "../../../components/CardContainer/CardContainer";

import { Trackable } from "@dpdgroupuk/react-event-tracker";
import { Container } from "react-bootstrap";

import ourPartnersBg from "../../../assets/pages/international/our-partners/our-partners-bg.webp";
import ourGroupPartnerships from "../../../assets/pages/international/our-partners/our-group-partnerships-bg.webp";
import ourTradePartnerships from "../../../assets/pages/international/our-partners/our-trade-partnerships-bg.webp";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../components/Section/Section";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading4 } from "../../../components/Typography/Heading4";
import { OUR_PARTNERS } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../constants/constants";

import * as routes from "../../../router/constants";
import { Body } from "../../../components/Typography/Body";
import { Bold } from "../../../components/Typography/BlogArticle/Bold";

import styles from "./OurPartners.Module.scss";

export const OurPartners = () => {
  return (
    <Trackable
      interfaceId={OUR_PARTNERS.INTERFACE_ID}
      loadId={OUR_PARTNERS.ON_LOAD}
    >
      <Section bgImg={ourPartnersBg} className={styles.ourPartnersBg}>
        <ScrollAnimation
          animateIn={ANIMATIONS.FADE_IN_LEFT}
          delay={DEFAULT_ANIMATION_DELAY}
          animateOnce={true}
        >
          <Heading2>
            <Bold>Our Partners</Bold>
          </Heading2>
          <Heading2>
            DPD is dedicated to providing our customers with the best possible
            experience and support in reaching global markets. Our strategic
            partnerships are a key part of that commitment. By working together
            with our partners, we are able to offer our customers{" "}
            <b>more value, innovation, and service</b>
          </Heading2>
          <Container className={styles.padding} />
          <CardContainer
            cards={[
              {
                cardBg: ourGroupPartnerships,
                cardTitle: (
                  <Heading4>
                    <Bold>Our Group Partnerships</Bold>
                  </Heading4>
                ),
                cardText: (
                  <Body>
                    We are proud to collaborate with a diverse network of
                    partners, leveraging collective expertise to expand our
                    reach, enhance services, and drive business growth
                  </Body>
                ),
                cardBtnTxt: "Learn More",
                cardBtnLink: routes.OUR_GROUP_PARTNERSHIPS,
              },
              {
                cardBg: ourTradePartnerships,
                cardTitle: (
                  <Heading4>
                    <Bold>Our Trade Partnerships</Bold>
                  </Heading4>
                ),
                cardText: (
                  <Body>
                    At DPD we recognise the power of collaboration to achieve
                    international trade success. Partnering with government
                    organisations allows us to stay abreast of the latest
                    international trade developments and best practices,
                    ensuring your business remains ahead of the curve
                  </Body>
                ),
                cardBtnTxt: "Learn More",
                cardBtnLink: routes.OUR_TRADE_PARTNERSHIPS,
              },
            ]}
            columnSpan={6}
            dpdRedButton
          />
        </ScrollAnimation>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
