import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";
import ReactPlayer from "react-player";

import video from "../../assets/pages/our-services/world-map/dpd_customs_clearance_v1.mp4";
import { ANIMATIONS } from "../../constants/constants";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading5 } from "../Typography/Heading5";

export const CountryCustomsData = () => {
  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container>
          <Col md={12}>
            <Heading3>
              We&apos;re making customs clearance simple for our customers...
            </Heading3>
          </Col>

          <Col
            md={12}
            style={{
              height: "230px",
            }}
          >
            <ReactPlayer
              url={video}
              width="100%"
              height="230px"
              controls={true}
            />
          </Col>

          <Col md={12}>
            <Heading5 classes={"country-sidedrawer-data-title"}>
              What is Electronic Customs Data?
            </Heading5>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Electronic Customs Data is the additional mandatory information,
              which tells customs authorities where the parcel is going to what
              is inside the parcel and who is the exporter, importer and/or
              recipient.
            </Body>
            <Body classes={"country-sidedrawer-data-text"}>
              The digital transmission of this data facilitates and speeds up
              customs clearance as well as streamlining your despatch process -
              no need to print paper copies upon shipping, which also reduces
              paper usage.
            </Body>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
};
