import { useMemo } from "react";

const useDeliveryCards = ({
  country,
  airTransportIcon,
  roadTransportIcon,
  airClassicIcon,
  roadClassicIcon,
}) => {
  const deliveryCards = useMemo(() => {
    const deliveryOptions = country?.deliveryOptions.services.map(service => {
      const icon =
        service.modeOfTransport === "Air"
          ? airTransportIcon
          : roadTransportIcon;

      let serviceIcon = <></>;

      if (service.service === "Air Classic") {
        serviceIcon = airClassicIcon;
      } else if (service.service === "Road Classic") {
        serviceIcon = roadClassicIcon;
      }

      return {
        ...service,
        icon,
        serviceIcon,
      };
    });

    const fastest = deliveryOptions?.find(
      option => option.serviceOption === "Fastest"
    );
    const classic = deliveryOptions?.find(
      option => option.serviceOption === "Classic"
    );
    const premiumEconomy = deliveryOptions?.find(
      option => option.serviceOption === "Premium Economy"
    );
    const economy = deliveryOptions?.find(
      option => option.serviceOption === "Economy"
    );

    const availableDeliveryOptions = [
      fastest,
      classic,
      premiumEconomy,
      economy,
    ].filter(option => !!option);

    return availableDeliveryOptions;
  }, [country]);

  return {
    deliveryCards,
  };
};

export default useDeliveryCards;
