import { useEffect, useState } from "react";

import styles from "./ScrollBackToTopBtn.module.scss";

export const ScrollBackToTopBtn = () => {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.pageYOffset > 300) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    });
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {showButton && (
        <svg
          className={styles.container}
          onClick={scrollToTop}
          width="50"
          height="50"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M30 0C13.4587 0 0 13.458 0 30C0 46.542 13.4587 60 30 60C46.5413 60 60 46.5413 60 30C60 13.458 46.542 0 30 0ZM30 57.8773C14.628 57.8773 2.12267 45.372 2.12267 30C2.12267 14.628 14.628 2.12267 30 2.12267C45.372 2.12267 57.8773 14.628 57.8773 30C57.8773 45.372 45.372 57.8773 30 57.8773Z"
            fill="#808285"
            fillOpacity="0.54"
          ></path>
          <path
            d="M31.2054 21.3972C30.5407 20.7325 29.4601 20.7325 28.7954 21.3972L16.4961 33.6972C16.0814 34.1118 16.0814 34.7838 16.4961 35.1978C16.9101 35.6125 17.5827 35.6125 17.9967 35.1978L29.7734 23.4212C29.8987 23.2958 30.1021 23.2958 30.2281 23.4212L42.0041 35.1978C42.4187 35.6125 43.0914 35.6125 43.5054 35.1978C43.9201 34.7838 43.9201 34.1118 43.5054 33.6972L31.2054 21.3972Z"
            fill="#808285"
            fillOpacity="0.54"
          ></path>
        </svg>
      )}
    </>
  );
};
