import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { Body } from "../Typography/Body";
import { ReactComponent as ChevronLeft } from "../../assets/icons/chevron-left.svg";
import { ReactComponent as ChevronRight } from "../../assets/icons/chevron-right.svg";

import styles from "./Pagination.module.scss";

export const Pagination = ({ items, itemsPerPage }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handleNext = () => {
    if (currentPage < Math.ceil(items.length / itemsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const totalPages = Math.ceil(items.length / itemsPerPage);

  const startingIndex = (currentPage - 1) * itemsPerPage;
  const endingIndex = Math.min(startingIndex + itemsPerPage, items.length);
  const currentItems = items.slice(startingIndex, endingIndex);

  useEffect(() => {
    setCurrentPage(1);
  }, [items]);

  const getPageNumbers = () => {
    const windowSize = 3;
    const startIndex =
      Math.floor((currentPage - 1) / windowSize) * windowSize + 1;

    const constrainedStartIndex = Math.min(Math.max(startIndex, 1), totalPages);

    const maxPagesToShow = Math.min(
      windowSize,
      totalPages - constrainedStartIndex + 1
    );

    return [...Array(maxPagesToShow)].map((x, i) => i + constrainedStartIndex);
  };

  const pageNumbers = getPageNumbers();

  return (
    <Container>
      <Row>{currentItems}</Row>
      {currentItems && currentItems.length > 0 && (
        <Col md={12}>
          <Container className={styles.pagination}>
            <ChevronLeft
              onClick={handlePrevious}
              disabled={currentPage === 1}
              className={styles.chevron}
            />
            {pageNumbers.map(pageNumber => (
              <Button
                key={pageNumber}
                onClick={() => setCurrentPage(pageNumber)}
                variant={styles.pagination}
                className={pageNumber === currentPage ? styles.active : null}
              >
                {pageNumber}
              </Button>
            ))}
            <ChevronRight
              onClick={handleNext}
              disabled={currentPage === totalPages}
              className={styles.chevron}
            />
          </Container>
        </Col>
      )}
      {currentItems && currentItems.length === 0 && (
        <Body>No articles found, please reset filters</Body>
      )}
    </Container>
  );
};

Pagination.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
  itemsPerPage: PropTypes.number.isRequired,
};
