import classNames from "classnames";
import PropTypes from "prop-types";
import { Card, Container, Row } from "react-bootstrap";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { DPD_NEWS_FEED_CATEGORY_LIST } from "../../data/blog";
import { INSIGHT_INFO_BLOG } from "../../constants/actions";
import { getFormattedDate } from "../../helpers/helpers";
import { Picks } from "../Picks/Picks";
import { AnchorLink } from "../AnchorLink/AnchorLink";
import { ReactComponent as GoBtn } from "../../assets/icons/chevron-right.svg";

import styles from "./BlogArticleCard.scss";

export const BlogArticleCard = ({
  img,
  text,
  category,
  title,
  url,
  externalLink = false,
}) => {
  const tracker = useTracker();

  return (
    <Card className={classNames(styles.card, "h-100 w-100")}>
      <Card.Img className={styles["card-img"]} src={img} />
      <Card.Body>
        <Container className="d-flex">
          <Card.Text className="date-title">{getFormattedDate(text)}</Card.Text>
          <Card.Text className="mx-1">&bull;</Card.Text>
          <Picks
            className="pick-title"
            title={category}
            colour={DPD_NEWS_FEED_CATEGORY_LIST[category]}
          />
        </Container>

        <Card.Title className={styles["card-title"]}>{title}</Card.Title>
        <Row className="justify-content-end mr-1">
          <AnchorLink
            to={url}
            onClick={() =>
              tracker.logEvent(INSIGHT_INFO_BLOG.ON_CLICK_GROUP_FEED_NEWS)
            }
            externalLink={externalLink}
          >
            <GoBtn style={{ position: "absolute", bottom: 15, right: 15 }} />
          </AnchorLink>
        </Row>
      </Card.Body>
    </Card>
  );
};

BlogArticleCard.propTypes = {
  img: PropTypes.string,
  text: PropTypes.string,
  category: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  externalLink: PropTypes.bool,
};
