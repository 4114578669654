import { useEffect } from "react";

import { BrowserRouter } from "react-router-dom";

import { TrackerProvider } from "@dpdgroupuk/react-event-tracker";

import {
  getHubLocations,
  getLeadTimeAvailabilty,
  getStrategicLocations,
} from "./apis/api";
import { Header } from "./components/Header/Header";
import { Layout } from "./components/Layout/Layout";
import { Loader } from "./components/Loader/Loader";
import { Router } from "./router/Router";
import { useStore } from "./store/store";
import tracker from "./utils/analytics";

export const App = () => {
  const { isLoading, setLocations, setIsLeadTimeCalculatorEnabled } =
    useStore();

  useEffect(() => {
    const loadLocations = async () => {
      try {
        const [strategicLocations, hubLocations] = await Promise.all([
          getStrategicLocations(),
          getHubLocations(),
        ]);

        const data = {
          strategicLocations: strategicLocations.data,
          hubLocations: hubLocations.data,
        };

        if (data) {
          setLocations(data);
        }
      } catch (error) {
        setLocations({
          hubLocations: [],
          strategicLocations: [],
        });
      }
    };

    loadLocations();
  }, []);

  useEffect(() => {
    const getLeadTimeAvailability = async () => {
      try {
        const { data } = await getLeadTimeAvailabilty();
        if (data) {
          setIsLeadTimeCalculatorEnabled(data.success);
        }
      } catch (error) {
        setIsLeadTimeCalculatorEnabled(false);
      }
    };

    getLeadTimeAvailability();
  }, []);

  return (
    <TrackerProvider tracker={tracker}>
      <BrowserRouter>
        <Loader isActive={isLoading}>
          <Header />
          <Layout />
          <Router />
        </Loader>
      </BrowserRouter>
    </TrackerProvider>
  );
};
