import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import section1Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import jumbotronBg from "../../../../assets/pages/international/tips-for-export-success/jumbotron-bg.png";
import section2Bg from "../../../../assets/pages/international/tips-for-export-success/section-2-bg.png";
import section2Img from "../../../../assets/pages/international/tips-for-export-success/section-2-img.png";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import * as router from "../../../../router/constants";

export const TipsForExportSuccess = () => {
  return (
    <>
      <Section bgImg={jumbotronBg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader headerText="Tips for Export Success and taking the complexity out of compliance" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              To help you make the most of the export potential for your
              business success, we’ve created a handy checklist to navigate you
              around the common obstacles and barriers you can face when
              shipping internationally.
            </Heading2>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section2Bg}>
        <ImgContainer imgs={section2Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Customs Compliance is crucial</Heading3>
            <Body>
              Goods presented to customs are subject to checks and in some cases
              more detailed inspections. These checks involve reviewing the
              declarations and information you have made in the electronic
              customs data. Therefore, making sure that the information you
              provide is accurate and compliant with the legal requirements,
              rules and regulations is extremely important.{" "}
            </Body>
            <Body>
              Whether you know it or not, missing or inaccurate information can
              lead to delays and extra costs. This can also prevent customs
              clearance from being completed, as well as resulting in fines and
              penalties being levied to you. Good customs compliance therefore
              is a vital element to ensure a smooth transit, problem-free
              customs clearance and the best experience for your customer.
            </Body>
            <Body>
              Even though DPD may carry and clear the goods on your behalf, as
              the exporter it is still your responsibility to comply with the
              customs regulations. Although this may be a complex and daunting
              topic, it is a necessity that requires care and attention.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <ScrollAnimation
          animateIn={ANIMATIONS.FADE_IN}
          delay={DEFAULT_ANIMATION_DELAY}
          animateOnce={true}
        >
          <Col md={12}>
            <Row>
              <Heading2>Avoiding common compliance risks</Heading2>
              <Body>
                In this section, we have prepared a handy guide to some of the
                key areas to be aware of to remain compliant and adhere to
                international rules and regulations.
              </Body>
            </Row>

            <Row>
              <Heading3>Declaring the correct value of goods</Heading3>
              <Body>
                The value you declare in the customs data is used by the
                authorities to calculate the applicable duties and taxes. If
                your goods are sold as part of a commercial transaction you must
                declare the amount the goods were sold for, not the cost or a
                lower value. Undervaluing goods is a common but serious issue
                since this may result in lower duties and taxes being levied
                than should be due to customs. If this is suspected then customs
                may also ask for proof of the value of the goods sold by
                requesting a copy of the invoice sent to your customer. When
                undervaluation is identified, fines and penalties may be
                applied. If the goods are not being shipped as part of a
                commercial sale or offered as free samples for example, you must
                declare the intrinsic value of the goods, that is a fair and
                market cost value for the item shipped.
              </Body>

              <div className="my-2">
                <ExternalLinkBtn btnLink={router.WTO_RULES}>
                  The WTO rules for customs valuation can be found here
                </ExternalLinkBtn>
              </div>

              <div className="mt-4">
                <CardContainer
                  cards={[
                    {
                      cardText: (
                        <Body classes="p-2">
                          Top Tip: The product values you declare must always be
                          exclusive of any other costs such as VAT and shipping
                          charges and always ensure you clearly state the
                          currency such as GBP, EUR or USD.
                        </Body>
                      ),
                      columnSpan: 12,
                    },
                  ]}
                />
              </div>
            </Row>

            <Row>
              <Heading3>
                Provide full, accurate and detailed descriptions of your
                products
              </Heading3>
              <Body>
                When listing a description of the products in your parcel, avoid
                ambiguous and generic terms like gifts, merchandise, accessories
                or tools. Whilst you may know what a shorthand or abbreviated
                name is in your merchandising system, customs won’t! These poor
                descriptions may lead to clearance delays so be as specific as
                possible so that it is clear from the description what the goods
                are, what it’s for and what it’s made of. For example instead of
                &lsquo;clothing&lsquo;, use &lsquo;men&lsquo;s cotton suits’.
              </Body>
            </Row>

            <Row>
              <Heading3>Correctly declare the origin of your goods</Heading3>
              <Body>
                Country of Origin (COO) represents the country or countries of
                manufacture where an article or product comes from, not where
                the goods are shipped from. Rules that determine the origin of
                the product can be complex but getting it right is important to
                ensure there are no issues with customs clearance. Where a trade
                agreement exists between the UK and the importing country, goods
                may qualify for zero or reduced duty tariffs.
              </Body>
            </Row>

            <Row>
              <div className="my-2">
                <ExternalLinkBtn btnLink={router.CHECK_IF_GOODS_MEET_RULES}>
                  Check if your goods meet the rules of origin here
                </ExternalLinkBtn>
              </div>

              <Body>
                Once you have established if your goods qualify you may need to
                provide proof of this.
              </Body>

              <div className="my-2">
                <ExternalLinkBtn btnLink={router.GOVERNMENT_GUIDANCE}>
                  Visit the Government guidance for more information.
                </ExternalLinkBtn>
              </div>
            </Row>

            <Row>
              <Heading3>Assign the correct commodity codes</Heading3>
              <Body>
                Commodity codes are used by customs to determine duty tariff
                rates and whether any restrictions apply so it is important you
                make sure the commodity code you have declared for your product
                matches your description and accurately reflects the items in
                your parcel. Errors and incorrect classifications will lead to
                delays and in some cases more detailed inspections of the goods
                which may result in a fine or penalty. HMRC can help you
                classify your goods if you have a query.
              </Body>

              <div className="my-2">
                <ExternalLinkBtn btnLink={router.MORE_INFO}>
                  Visit the Government guidance for more information.
                </ExternalLinkBtn>
              </div>
            </Row>

            <Row>
              <Heading3>Be aware of counterfeit goods</Heading3>
              <Body>
                Replica and fake branded goods are prohibited from DPD’s terms
                and conditions of carriage. Oftentimes, these goods are of poor
                quality, sold without the required licensing permission from the
                brand owner and do not meet the required safety standards.
                Always ensure you are not shipping counterfeit goods.
              </Body>
            </Row>
          </Col>
        </ScrollAnimation>
      </Section>

      <GetInTouch
        cardTitle="We’re here to help"
        cardText="Although customs compliance can be a tricky topic, at DPD we have an experienced, dedicated compliance team who can help you with your questions. "
      />
    </>
  );
};
