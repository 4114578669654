import { useLocation } from "react-router-dom";

import { ContactUsForm } from "../ContactUsForm/ContactUsForm";
import { MessageBtn } from "../MessageBtn/MessageBtn";
import { ScrollBackToTopBtn } from "../ScrollBackToTopBtn/ScrollBackToTopBtn";
import { ScrollToTop } from "../ScrollToTop/ScrollToTop";
import { HOME } from "../../router/constants";

export const Layout = () => {
  const location = useLocation();

  if (location.pathname === HOME) {
    return (
      <>
        <ContactUsForm />
      </>
    );
  }

  return (
    <>
      <ScrollBackToTopBtn />
      <ContactUsForm />
      <ScrollToTop />
      <MessageBtn />
    </>
  );
};
