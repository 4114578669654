import AnimatedNumber from "animated-number-react";
import classNames from "classnames";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import { ANIMATIONS } from "../../constants/constants";
import { useStore } from "../../store/store";
import { CountrySources } from "../CountrySources/CountrySources";
import { Heading4 } from "../Typography/Heading4";
import { Heading6 } from "../Typography/Heading6";
import useOpportunities from "../../hooks/widgets/useOpportunities";

export const CountryOpportunity = () => {
  const { country } = useStore();

  const { sources, keyStats, consumerStats } = useOpportunities({
    country,
  });

  const createStats = stats => {
    return stats.map(country => {
      return (
        <Col key={`${country.value}-${country.text}`} md={4}>
          <Heading4 classes={"country-sidedrawer-data-title"}>
            <div>{country.icon}</div>
            {country.prependValue}
            <AnimatedNumber
              value={country.value}
              formatValue={v => v.toFixed(country.valueDecimalPlace)}
              delay={1000}
            />
            {country.appendValue}
          </Heading4>
          <Heading6 classes={"country-sidedrawer-data-text"}>
            {country.text}
          </Heading6>
        </Col>
      );
    });
  };

  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={classNames("dark-scrollbar", "country-sidedrawer-data")}>
        <Container>
          <Col md={12}>
            <Heading4>{country?.countryOpportunity.countryName}</Heading4>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              Opportunities for online sellers
            </Heading4>
            <Heading6 classes={"country-sidedrawer-data-text"}>
              For UK businesses offering innovative, quality products, the
              country offers export market opportunities.
            </Heading6>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              Key Stats
            </Heading4>
          </Col>

          <div className="d-flex">{createStats(keyStats)}</div>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              Percentage of e-commerce consumers that have shopped from abroad
            </Heading4>
          </Col>

          <div className="d-flex">{createStats(consumerStats)}</div>

          <CountrySources sources={sources} />
        </Container>
      </div>
    </ScrollAnimation>
  );
};
