import classNames from "classnames";
import PropTypes from "prop-types";

import { ReactComponent as Download } from "../../assets/icons/download.svg";
import { ReactComponent as Pdf } from "../../assets/icons/pdf.svg";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import styles from "./DownloadBtn.module.scss";

export const DownloadBtn = ({
  children,
  onClick,
  variant = "outline-primary",
  classes,
  downloadLink,
}) => {
  return (
    <CallToActionBtn
      variant={variant}
      classes={classNames(classes, styles["download-btn"])}
      onClick={onClick}
      btnLink={downloadLink}
      externalBtnLink={true}
    >
      <span className={styles["btn-label"]}>
        <Pdf />
      </span>
      {children}
      <span className={classNames("mx-2", styles["download-icon"])}>
        <Download />
      </span>
    </CallToActionBtn>
  );
};

DownloadBtn.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  classes: PropTypes.string,
  downloadLink: PropTypes.string,
};
