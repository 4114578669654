import { useMemo } from "react";
import { EXPORT_INFO_CONTENT_TYPES } from "../../constants/constants";
import { isCountryEu, isCountryRestOfWorld } from "../../helpers/helpers";

const useExportCards = ({ country }) => {
  const exportInfoList = useMemo(() => {
    const list = [
      {
        contentType: EXPORT_INFO_CONTENT_TYPES.CUSTOMS_DATA,
        content: country?.exportInfo.link1 ? country?.exportInfo.link1 : "",
      },
    ];

    // Control what is shown for 2nd export info option
    if (country && isCountryEu(country?.marketInfo.countryName)) {
      list.push({
        contentType: EXPORT_INFO_CONTENT_TYPES.DPD_GOODS,
        content: "What Goods can DPD ship to the EU?",
      });
    } else if (
      country &&
      isCountryRestOfWorld(country?.marketInfo.countryName)
    ) {
      list.push({
        contentType: EXPORT_INFO_CONTENT_TYPES.DPD_GOODS,
        content: "Check if your goods are subject to restrictions",
      });
    }

    // Only show 3rd option if Rest of World country
    if (country && isCountryRestOfWorld(country?.marketInfo.countryName)) {
      list.push({
        contentType: EXPORT_INFO_CONTENT_TYPES.DUTIES_TAXES,
        content: "What are Duties and Taxes?",
      });
    }

    return list;
  }, [country]);

  return { exportInfoList };
};

export default useExportCards;
