import { useEffect, useState } from "react";

import { Container, Nav, Navbar } from "react-bootstrap";
import { useLocation } from "react-router-dom";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as HamburgerIcon } from "../../assets/icons/hamburger-icon.svg";
import { MENU } from "../../constants/actions";
import { NAV_LINKS } from "../../router/constants";
import { Brand } from "../Brand/Brand";
import { NavBarLink } from "../NavBarLink/NavBarLink";
import { SearchInput } from "../SearchInput/SearchInput";
import styles from "./PrimaryNavBar.module.scss";
import { useWindowSize } from "../../hooks/useWindowSize";

export const PrimaryNavBar = () => {
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const windowSize = useWindowSize().width;

  useEffect(() => {
    // Close the hamburger menu whenever url changes
    setExpanded(false);
  }, [location]);

  return (
    <Trackable loadId={MENU.ON_LOAD}>
      <Navbar expand="lg" expanded={expanded}>
        <Container className={styles["mydpd-navbar-container"]}>
          {windowSize <= 992 ? (
            <div className={styles.inputContainer}>
              <Brand />
              <SearchInput isMobile />
            </div>
          ) : (
            <Brand />
          )}
          <Navbar.Toggle
            aria-controls="basic-navbar-nav"
            onClick={() => setExpanded(!expanded)}
          >
            <HamburgerIcon />
          </Navbar.Toggle>
          <Navbar.Collapse
            className={styles["mydpd-navbar-collapse"]}
            style={{ position: "relative" }}
          >
            <Nav
              className={
                "mydpd-primary-nav mx-auto justify-content-between mydpd-nav navbar-nav"
              }
            >
              {NAV_LINKS.map(({ href, name, actionId }) => (
                <NavBarLink
                  key={href}
                  href={href}
                  name={name}
                  actionId={actionId}
                />
              ))}
            </Nav>
            {windowSize > 992 && <SearchInput />}
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Trackable>
  );
};
