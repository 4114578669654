import classNames from "classnames";
import PropTypes from "prop-types";

export const Heading4 = ({ children, classes }) => {
  return <h4 className={classNames(classes, "heading-h4")}>{children}</h4>;
};

Heading4.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
