import { ReactComponent as EuIcon } from "../assets/icons/iconEuSmall.svg";
import { ReactComponent as GlobeIcon } from "../assets/icons/iconGlobeSmall.svg";
import { ReactComponent as HelpIcon } from "../assets/icons/iconHelpSmall.svg";
import { ReactComponent as OfficerIcon } from "../assets/icons/iconOfficerSmall.svg";
import { ReactComponent as PaymentIcon } from "../assets/icons/iconPaymentSmall.svg";
import { ReactComponent as TrackingIcon } from "../assets/icons/iconTrackingSmall.svg";
import { AnchorLink } from "../components/AnchorLink/AnchorLink";
import * as routes from "../router/constants";

export const FAQS = {
  CUSTOMS_CHARGES: "Customs Charges - Duties & Taxes",
  CUSTOMS_CLEARANCE: "Customs Clearance",
  GENERAL_ACCOUNT_HELP_SUPPORT: "General Account Help and Support",
  EU_VAT_IOSS: "EU VAT and Import One Stop Shop (IOSS)",
  PARCEL_TRACKING_TRANSIT_TIMES: "Parcel Tracking and Transit Times",
  RECEIVING_PARCEL_OUTSIDE_UK:
    "Receiving a parcel from outside the United Kingdom",
};

export const FAQ_OPTIONS = [
  FAQS.CUSTOMS_CHARGES,
  FAQS.CUSTOMS_CLEARANCE,
  FAQS.GENERAL_ACCOUNT_HELP_SUPPORT,
  FAQS.EU_VAT_IOSS,
  FAQS.PARCEL_TRACKING_TRANSIT_TIMES,
  FAQS.RECEIVING_PARCEL_OUTSIDE_UK,
];

export const FAQ_OPTION_ICONS = {
  [FAQS.CUSTOMS_CHARGES]: <PaymentIcon />,
  [FAQS.CUSTOMS_CLEARANCE]: <OfficerIcon />,
  [FAQS.GENERAL_ACCOUNT_HELP_SUPPORT]: <HelpIcon />,
  [FAQS.EU_VAT_IOSS]: <EuIcon />,
  [FAQS.PARCEL_TRACKING_TRANSIT_TIMES]: <TrackingIcon />,
  [FAQS.RECEIVING_PARCEL_OUTSIDE_UK]: <GlobeIcon />,
};

const CUSTOMS_CHARGES = [
  {
    country: "EU",
    question: "What are the different VAT rates in the EU?",
    answer: (
      <p>
        The VAT Directive sets the framework for VAT rates in the EU, but it
        gives national governments freedom to set the number and level of rates
        they choose, which is why rates differ across Europe.{" "}
        <AnchorLink
          to="https://ec.europa.eu/taxation_customs/system/files/2020-10/vat_rates_en.pdf"
          externalLink={true}
        >
          The individual member states&apos; VAT rates can be found here.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite:
      "International Uncovered > Shipping to the European Union {panel 6]",
  },
  {
    country: "EU",
    question: "How does DPD collect duty and tax payments?",
    answer: (
      <>
        <p>
          Once the duties and taxes have been calculated, these charges will
          need to be paid before the parcel can continue its journey to the
          recipient.
        </p>
        <p>
          With DPD&apos;s default option, we will try and collect the amounts
          from the recipient. Whilst the parcel is still in transit to the
          clearance location, we notify the recipient by SMS and/or email. This
          notification gives your customer all the information they require and
          a link to DPD&apos;s secure payment gateway to pay these charges
          electronically. This option, sometimes known as
          &lsquo;in-flight&lsquo; clearance, aims to accelerate the payment
          process and avoid additional delays.
        </p>
        <p>
          Once charges have been paid, DPD will release the parcel and send it
          on its way. However, if the charges aren&apos;t paid before the parcel
          arrives at our clearance hub, we will hold it securely until they are
          in our Customs Clearance facility. We will make several attempts to
          remind the recipient of their need to pay. If the charges remain
          unpaid, DPD will return the parcel to you in the UK.
        </p>
        <p>
          For business-to-consumer (B2C) shipments, if you prefer a quicker
          customs clearance experience, DPD offers an additional service called
          &lsquo;Duties and Taxes Prepaid&lsquo;. Rather than contact your
          customers for payment, we can prepay these charges on your behalf and
          bill them back to your DPD account. Your customer is still listed as
          the importer of record for this service. This can be set up on a
          shipment-by-shipment basis, giving you complete flexibility. Please
          contact your Account Manager if you would like more information.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "International Uncovered > Your Guide to Duties and Taxes",
  },
  {
    country: "EU",
    question: "What are DPD's charges for Customs Clearance?",
    answer: (
      <>
        <p>
          An EU Clearance Fee of £4.75 applies to all consignments shipped on
          the DPD Classic and Republic of Ireland Two Day service. This fee
          covers customs clearance, and handling and processing costs to export
          parcels to EU destinations from the UK. This fee may be adjusted in
          the event of any substantial or material change in circumstances.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.SHIPPING_TO_EU}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "International Uncovered > Shipping to the European Union",
  },
  {
    country: "France",
    question: "What is the Import French reverse VAT change?",
    answer: (
      <p>
        Compulsory since the 1st January 2022, in France for all
        business-to-business (B2B) consignments over €150.00, VAT is charged to
        a valid French VAT number. It is therefore mandatory for all businesses
        shipping through the French cluster to have a registered French VAT
        number. Without a French VAT number, consignments will not reach the
        final destination and will be returned to the sender, at a cost to the
        sender. The French VAT number contains FR followed by 11 digits and must
        be provided in the electronic customs data.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "n/a",
  },
  {
    country: "Ireland",
    question:
      "My customer in Ireland has a TAN account. Can DPD use this for the payment of Duties and Taxes?",
    answer: (
      <p>
        If your importing business in Ireland has a Trader Account Number (TAN)
        set up with Irish Revenue then this can be used to settle any customs
        payments. In order for this to be used,{" "}
        <AnchorLink
          to="https://www.revenue.ie/en/customs-traders-and-agents/documents/electronic/customs-and-excise-clearance-agent-form.pdf"
          externalLink={true}
        >
          you will need to provide authorisation to DPD by completing this form
        </AnchorLink>{" "}
        and sending it to our International Experts team at
        internationalexperts@dpd.co.uk.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question:
      "Does DPD offer a duty paid service for parcels shipped on the rest of world air services?",
    answer: (
      <>
        <p>
          Yes, duty paid delivery options are available on the air services to
          most destinations. For more information please contact your Account
          Manager or contact our International Experts team on
          internationalexperts@dpd.co.uk.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "Air Classic / Air Express / DPD Direct",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "International Uncovered > Your Guide to Duties and Taxes",
  },
  {
    country: "EU/ROW",
    question:
      "What are the benefits of offering a duty paid delivery service for my recipient?",
    answer: (
      <>
        <p>
          Offering your recipient a landed cost at the point sale can provide a
          faster transit to destination, a streamlined customs clearance process
          and an overall better delivery experience. Contact your Account
          Manager or our team of international delivery experts for more
          information on how this can improve your international offer.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "International Uncovered > Your Guide to Duties and Taxes",
  },

  {
    question: "What is a cluster clearance location?",
    answer: (
      <>
        <p>
          DPD has strategically positioned facilities across Europe as part of
          our road service, where we undertake the import parcel clearance
          process. Our dedicated customs brokers at DPD handle all aspects of
          Customs Clearance within our own facilities. These strategically
          chosen sites are situated in various countries, including Ireland,
          France, Germany, Netherlands, Austria, Italy, and Spain. These
          locations play a crucial role in processing goods for both their
          respective destination countries as well as for other countries.
        </p>
        <p>
          Find out more about our European presence{" "}
          <AnchorLink to={routes.WHO_WE_ARE}>here.</AnchorLink>
        </p>
      </>
    ),
    country: "EU",
    applicableService: "DPD Classic",
    category: "Customs Charges - Duties & Taxes",
    pageOnSite: "",
  },
];

const CUSTOMS_CLEARANCE = [
  {
    country: "EU / ROW",
    question: "What is a commodity code / HS code?",
    answer: (
      <>
        <p>
          Harmonized System (HS) codes, also known as commodity codes, are
          commonly used throughout the export process for goods. This
          internationally recognised numbering system is used by global
          authorities to categorise products for the trade tariff to assess the
          duties and taxes to be paid. HS codes are usually made up of 8 digits:
          the first 2 digits are the chapter of classification, e.g. textiles
          and apparel. The next 2 digits classify the item, e.g. blouse or
          shirt, and the gender. The next 2 digits specify the item further,
          e.g. what it’s made of/composition. DPD requires HS codes to be
          provided in the electronic customs data, and they must be 8 digits
          long.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > The how-to of exporting goods and the role of customs",
  },
  {
    country: "EU",
    question:
      "How does customs clearance work for my goods travelling to Europe?",
    answer: (
      <>
        <p>
          Once the trailers have left the UK and passed through the ports, our
          trucks go to one of our customs clearance hubs, where the import
          clearance formalities take place.
        </p>
        <p>
          DPD has multiple strategically located sites throughout Europe, where
          we clear your parcels for import. All customs clearance processes are
          performed by DPD customs brokers in our own facilities.
        </p>
        <p>
          If you would like to know more about this, please contact your Account
          Manager or our International Experts team.
        </p>
      </>
    ),

    applicableService: "DPD Classic",
    category: "Customs Clearance",
    pageOnSite: "International Uncovered > Shipping to the European Union",
  },
  {
    country: "EU",
    question:
      "How do I know what products can be shipped on the DPD Classic service?",
    answer: (
      <p>
        <AnchorLink to={routes.EU_PROHIBITED_GOODS} externalLink={true}>
          Check which goods DPD cannot ship to the EU by downloading our guide
          here.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Customs Clearance",
    pageOnSite: "n/a",
  },
  {
    country: "EU",
    question: "What is an Importer of Record?",
    answer: (
      <p>
        An importer of record (IOR) is the entity or person in the destination
        country responsible for ensuring compliance requirements are met when
        importing goods internationally. The IOR is nominated by the shipper and
        agreed as part of the commercial transaction. When using DPD&apos;s
        services the default option is for the recipient to be declared as the
        IOR. However, it is possible to clear against a different IOR in certain
        circumstances with prior arrangement. Contact our International Experts
        team for more information at internationalexperts@dpd.co.uk.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Customs Clearance",
    pageOnSite: "n/a",
  },
  {
    country: "EU / ROW",
    question: "How do I find the commodity code / HS code for my goods?",
    answer: (
      <p>
        Commodity Codes / HS codes can be checked on the UK Government website{" "}
        <AnchorLink to="https://www.gov.uk/trade-tariff" externalLink={true}>
          https://www.gov.uk/trade-tariff.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "n/a",
  },
  {
    country: "EU / ROW",
    question: "What is an EORI Number and how can I check it?",
    answer: (
      <p>
        An Economic Operator Registration and Identification number (EORI) is a
        mandatory requirement for UK registered businesses to trade goods with
        countries outside the UK. It is recorded in the export declaration and
        used for statistical and security purposes, UK EORI numbers start with
        the letters &lsquo;GB&lsquo;. Most are then followed by a 12-digit
        number based on the trader&apos;s VAT number (e.g. GB123456789000).
        <AnchorLink
          to="https://www.gov.uk/check-eori-number"
          externalLink={true}
        >
          {" "}
          UK EORI numbers can be validated on the UK Government website.
        </AnchorLink>
        <AnchorLink
          to="https://ec.europa.eu/taxation_customs/dds2/eos/eori_validation.jsp"
          externalLink={true}
        >
          {" "}
          EU EORI numbers can be validated on the European Commission website
          here.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "n/a",
  },
  {
    country: "EU / ROW",
    question: "What qualifies as an accurate and detailed description?",
    answer: (
      <>
        <p>
          A good detailed description is required in the electronic customs
          data. This needs to be as descriptive as possible; for example, just
          providing &lsquo;Clothing&lsquo; is not acceptable. There needs to be
          detail such as what the product is, the colour, the gender if
          applicable and what the goods are made of. An example of this is
          &lsquo;Men’s Blue Shirt 100% cotton&lsquo;. If you don&apos;t provide
          a detailed description your goods may be delayed in customs.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.TIPS_FOR_EXPORT_SUCCESS}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > Tips for Export Success and taking the complexity out of compliance",
  },
  {
    country: "EU / ROW",
    question: "How should I declare the value of goods if it is a free gift?",
    answer: (
      <>
        <p>
          You should always declare the intrinsic or market value of your goods
          for the purposes of customs clearance. This is the value of the goods
          prior to the VAT or shipping costs being applied. Goods with incorrect
          values may be seized by customs authorities and returned or held.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.TIPS_FOR_EXPORT_SUCCESS}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > Tips for Export Success and taking the complexity out of compliance",
  },
  {
    country: "EU / ROW",
    question:
      "Why does my customer need to supply ID in order for their goods to clear customs?",
    answer: (
      <>
        <p>
          For B2C shipments, certain customs authorities need a copy of an
          official form of identification of the individual who is importing the
          goods. This varies by destination but the most common examples of
          where this is required are:
        </p>
        <p>
          Brazil – CPF number (also known as a TAX ID number), 11 digits long
        </p>
        <p>Canary Islands – DNI, 8 digits long with a fix letter at the end</p>
        <p>China – national ID or passport for foreign nationals</p>
        <p>India – KYC PAN ID card required, 10 digits long</p>
        <p>Indonesia – national ID, 16 digits long</p>
        <p>
          Italy – codice fiscale, Italian tax code and identification number, 16
          alphanumeric characters long
        </p>
        <p>Qatar – national ID, 11 digits long</p>
        <p>Saudi Arabia – national ID, 10 digits long</p>
        <p>South Africa – national ID, 13 digits long</p>
        <p>South Korea – PCC number, 12 digits long, prefixed with a ‘P’</p>
        <p>Spain – DNI, 8 digits long followed by a letter</p>
        <p>
          If you have this information already you can supply this securely in
          the electronic customs data records. If not, don&apos;t worry: our
          customs broker will obtain this from your customer when the goods
          undergo customs clearance.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > The How-to of exporting goods and the role of customs",
  },
  {
    country: "EU/ROW",
    question: "What is Electronic Customs Data?",
    answer: (
      <>
        <p>
          Electronic customs data is a digital version of the commercial
          invoice, which is the additional mandatory information telling customs
          authorities where the parcel is going, what is inside the parcel and
          who the exporter, importer and/or recipient are as well as the terms
          of trade.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > The How-to of exporting goods and the role of customs",
  },
  {
    country: "EU/ROW",
    question:
      "What information is required for the Electronic Customs Data record?",
    answer: (
      <>
        <p>
          The electronic customs data record should contain all the same
          information you normally declare in a commercial invoice, such as the
          exporter and importer details as well as your EORI and recipient tax
          ID, a full and detailed description of goods, along with their
          associated commodity codes, the value of goods and currency, the
          international terms of trade, as well as the shipping costs.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > The How-to of exporting goods and the role of customs",
  },
  {
    country: "EU/ROW",
    question:
      "Why have I been contacted for a copy of a commercial invoice if I have provided Electronic Customs Data?",
    answer: (
      <>
        <p>
          From time to time, the customs information you have supplied
          electronically may be subject to further review and inspection by
          customs authorities. This is normal practice when goods go through
          customs formalities and can occur not only when your parcel is in
          transit, but also after the goods have been delivered. Should your
          parcel be subject to these additional checks, our Customer Service
          team will contact you for copies of the commercial invoice or
          additional documentation that customs requests. This information must
          be supplied in a timely manner to avoid your parcel being detained or
          to avoid any customs fines or penalties. Our teams will guide you
          through what information is required and provide you with all the
          necessary support if you need it.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite:
      "International Uncovered > The How-to of exporting goods and the role of customs",
  },

  {
    question:
      "Why does my customer need to supply DNI number in order to customs clear their shipment to Spain?",
    answer: (
      <>
        <p>
          Due to local Customs requirements, DNI number is needed for shipments
          destined to Spain which are valued at over €1000.
        </p>
        <p>
          The DNI (Documento Nacional de Identidad) is the Spanish national
          identity card, which has a 9-digit long identification number.
        </p>

        <p>
          We advise that this number is provided in the ‘Receiver’s VAT/PID/EORI
          No.’ field within our manifest or on our MyDPD platform. For myDPD
          users this can be found on the Customs Information screen.
        </p>
        <p>
          Please visit our{" "}
          <AnchorLink to={routes.WORLD_MAP}>World Map</AnchorLink> page for more
          country specific information.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question:
      "Why does my customer need to supply Codice Fiscale in order to customs clear their shipment to Italy?",
    answer: (
      <>
        <p>
          From the 1st of July 2021, shipping B2C parcels under €150 without an
          IOSS number requires a Codice Fiscale for DPD UK to compliantly
          Customs clear parcels into Italy. This applies to both DAP and DT1
          parcels.
        </p>
        <p>
          Codice Fiscale is the Italian Tax Identification code which identifies
          a Taxpayer for the local Tax Authorities. This can be provided by the
          recipient via the payment notification issued via SMS and email.
        </p>
        <p>
          Codice Fiscale is not required for parcels which have an IOSS
          reference provided.
        </p>
        <p>
          Please visit our{" "}
          <AnchorLink to={routes.WORLD_MAP}>World Map</AnchorLink> page for more
          country specific information.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question:
      "Why does my customer need to supply PID number in order to customs clear their shipment to Norway?",
    answer: (
      <>
        <p>
          The PID number should be mentioned on the invoice when a shipment is
          destined for a private consignee and has a value of a 1,000 NOK or
          more. The only exception to this rule is for shipments containing
          books, where the PID has to be mentioned on the invoice for such
          shipments regardless of the value.
        </p>

        <p>
          This number is provided in the ‘Receiver’s VAT/PID/EORI No.’ field
          within the invoice / customs data or on our MyDPD platform. For myDPD
          users this can be found on the Customs Information screen.
        </p>

        <p>
          In case the invoice value is below 1,000 NOK (approx. 118 Euro), is
          addressed to a private individual and does not contain books it is not
          necessary to mention the PID on the invoice.
        </p>
        <p>
          Please visit our{" "}
          <AnchorLink to={routes.WORLD_MAP}>World Map</AnchorLink> page for more
          country specific information.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question:
      "Why does my customer need to supply POA (Power of Attorney) in order to customs clear their shipment to Germany?",
    answer: (
      <>
        <p>
          POA requests only occur for parcels that are being shipped as
          delivered at place (DAP), where the duties and taxes are the
          responsibility of the recipient.
        </p>

        <p>
          This request will be sent to the consignee, and clearance cannot take
          place, until this has been accepted/authorised. If the POA request is
          not acknowledged, then after the 5 day holding time, it will be
          returned to the recipient.
        </p>
        <p>
          Please visit our{" "}
          <AnchorLink to={routes.WORLD_MAP}>World Map</AnchorLink> page for more
          country specific information.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question: "What is an EIRCode?",
    answer: (
      <>
        <p>
          EIRCode is Ireland’s postcode system. It is alphanumeric and it is
          unique to a postal address and its geographic location.
        </p>

        <p>
          It is not mandatory, but will help delivery drivers on their route,
          and reduce address queries and misroutes.
        </p>

        <p>
          Shippers can share the consumers EIRCode in the Town Field of the
          delivery address at checkout. We do not currently accept the EIRCode
          in the postcode field.
        </p>

        <p>
          Recipients of Irish parcels can also provide it via the inflight
          notification that they will be sent prior to the delivery; in the
          notification a link will allow them to supply the EIRCode.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question:
      "What is the difference between eDAP and DAP, and how does this relate to Incoterms?",
    answer: (
      <>
        <p>
          Incoterms are terms that define the responsibility of the buyer and
          the seller relating to the movement and shipping of the goods. DPD’s
          default Incoterm is DAP, which is Delivered at Place. DAP simply means
          that the seller takes on all of the risks and costs of delivering
          goods to an agreed-upon location. Under DAP the seller is responsible
          for everything except the customs clearance formalities. eDAP is
          simply a payment facilitation service for the payment of the import
          charges such as Duty and VAT. eDAP it’s not an Incoterm and it does
          not determine or change the legal roles and responsibilities of the
          seller or the buyer.
        </p>
        <p>
          You can find out more about Incoterms{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here</AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question: "Can customers reclaim the VAT when shipping on eDAP?",
    answer: (
      <>
        <p>
          eDAP is not suitable for B2B movements. This is because importing
          businesses will expect to reclaim/be refunded any import VAT they pay
          for the goods to clear customs. In order to make the refund request,
          it must be requested by the Import of Record only (i.e. the recipient)
          with sufficient evidence (i.e. VAT receipt) through the required
          accounting process. With DPD’s eDAP solution there is no VAT receipt
          (DPD pays the duties and taxes and bill back the UK Customer). This
          means that the UK Customer is not the Importer of Record and therefore
          will not be able to reclaim the VAT.
        </p>
        <p>
          You can find out more about Incoterms{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here</AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question: "What is the difference between eDAP and DDP?",
    answer: (
      <>
        <p>
          DDP or ‘Delivered Duties Paid’ is an Incoterm whereby the seller is
          both the Exporter and the Importer of Record and bears all the risks
          and costs of moving the goods. DPD’s default Incoterm is DAP
          (Delivered at Place). DAP simply means that the seller takes on all of
          the risks and costs of delivering goods to an agreed-upon location.
          Under DAP the seller is responsible for everything, including
          packaging, documentation, export approval, loading charges, and
          ultimate delivery. The buyer, however, takes over the risk and
          responsibility of unloading the goods and clearing them for import.
        </p>
        <p>
          You can find out more about Incoterms{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here</AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question:
      "Why do I need to supply the FDA Prior Notice in order to clear my shipment to the United States?",
    answer: (
      <>
        <p>
          The Food and Drugs Administration require prior notice when shipping
          one of the below commodities to USA:
        </p>

        <p>
          - Food products (Chocolate, Coffee, Fruits, Honey, Juices, Nuts, Tea,
          Spices)
        </p>
        <p>- Plants (Cocoa leaves, Herbs, Natural products)</p>
        <p>- Pharmaceutical items (Vitamins)</p>
        <p>- Personal products (Cosmetics, Perfumes, Toiletries)</p>

        <p>
          The prior notice form must be filled electronically via the FDA
          website{" "}
          <AnchorLink to="https://www.fda.gov/food/importing-food-products-united-states/prior-notice-system-interface-pnsi-step-step-instructions-food-articles-sent-international-mail">
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question:
      "Why does my customer need to supply a Receiver ID Number when shipping to Saudi Arabia?",
    answer: (
      <>
        <p>
          Due to local Customs requirements, Receiver ID Number is needed for
          all shipments destined to Saudi Arabia which are valued at 1000 SAR or
          above.
        </p>
        <p>
          We advise that this number is provided in the ‘Receiver’s VAT/PID/EORI
          No.’ field within our manifest or on our MyDPD platform. For myDPD
          users this can be found on the Customs Information screen.
        </p>

        <p>
          To find our more country specific guidance visit our page{" "}
          <AnchorLink
            to={routes.EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS}
          >
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question: "What is in-flight customs clearance?",
    answer: (
      <>
        <p>
          When shipping to the USA, Switzerland and Canada DPD Suppliers will
          send over an email to retrieve Duties and Taxes in transit, rather
          than waiting for the parcel to arrive in the destination country. This
          quickens up the process of payment and prevents the parcel being held
          in Customs. The email sent by the Supplier contains a link to an
          online payment portal through which Duties and Taxes can be paid
          securely.
        </p>

        <p>
          To find our more country specific guidance visit our page{" "}
          <AnchorLink
            to={routes.EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS}
          >
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question:
      "My parcel to the United States received Confirmation of Delivery only.",
    answer: (
      <>
        <p>
          In the USA, it is a common practice for the consumer packages to be
          delivered at the front door of the residence. We have the capability
          to arrange a signature service request to the USA, subject to an
          additional fee.
        </p>

        <p>
          To find our more country specific guidance visit our page{" "}
          <AnchorLink
            to={routes.EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS}
          >
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question: "What is the GST in Australia?",
    answer: (
      <>
        <p>
          Goods and Services Tax (GST) is a broad-based tax of 10% on the sale
          of most goods, services and anything else consumed in Australia.
          Not-for-profit organisations with a turnover of $150,000 or more must
          register for GST. If your organisation has a turnover of less than
          $150,000 you can choose whether or not you register for GST.
        </p>

        <p>
          To find our more country specific guidance visit our page{" "}
          <AnchorLink
            to={routes.EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS}
          >
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question: "What is the GST in New Zealand?",
    answer: (
      <>
        <p>
          GST is a tax added to the price of most goods and services, including
          imports. It is a tax for people who buy and sell goods and services.
          You might need to register for GST if you sell goods or services. GST
          is charged at a rate of 15% for parcels valued under NZD$1,000.
        </p>

        <p>
          To find our more country specific guidance visit our page{" "}
          <AnchorLink
            to={routes.EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS}
          >
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question: "What is the KYC in India?",
    answer: (
      <>
        <p>
          KYC stands for ‘Know your Customer’ - this documentation is extremely
          important when sending packages to India in order for the local
          customs authorities to confirm the Receiver’s address and identity.
          This applies to both businesses and private individuals. The receiver
          will be contacted by the phone or email in order to provide a form of
          ID and letter of authorisation for deliveries to India.
        </p>

        <p>
          To find our more country specific guidance visit our page{" "}
          <AnchorLink
            to={routes.EXPORT_FUNDAMENTALS_INTERNATIONAL_DESTINATIONS}
          >
            here
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },

  {
    question: "What are Export Controls?",
    answer: (
      <>
        <p>
          UK Export Controls restrict the transfer or disclosure of sensitive
          goods, software, and technology (know-how and other information) to
          recipients and destinations outside of the UK. Countries and
          international organisations such as the United Nations have the
          ability to impose prohibitions or restrictions on the export of
          certain products.
        </p>

        <p>
          They control the export of dual-use goods, which are goods that can be
          used for both civilian and military applications. Even very familiar
          and seemingly harmless items can be considered as dual-use goods. They
          can also adopt “international sanctions” or “restrictive measures”.
          Such sanctions are not limited to measures targeted at individuals.
          They can also consist of export bans or export restrictions applying
          to certain types of products. They do so to protect international
          security, the principles of international law and human rights and to
          prevent conflicts and extremist groups.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question:
      "Who is responsible for complying with legal obligations relating to export control?",
    answer: (
      <p>
        Any participant in an unlawful export of restricted or prohibited
        products could be held liable or co-liable and face civil and/or
        criminal penalties. However, it is the responsibility of the exporter in
        the first place to ensure the items can be lawfully exported, eg. to
        apply for an export licence if one is required.
      </p>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question:
      "What do dual-use requirements and international sanctions on goods consist in?",
    answer: (
      <p>
        These measures can consist in export prohibitions or export
        restrictions. If a product is subject to an export restriction, it can
        be lawfully exported provided the exporter holds a valid export licence
        or export authorisation and uses it in accordance with the conditions of
        the licence (validity period, nature of goods, volume, end user, etc.).
      </p>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question:
      "How can I know if the products contained in the parcel I’m sending require a licence?",
    answer: (
      <>
        <p>
          Start with the legislation of the country from which you are exporting
          the products and find a) its list of dual-use items and b) its
          sectoral sanctions (or “restrictive measures”) that apply to the
          country of destination of your parcel. Check if your products are part
          of any of these lists.
        </p>
        <p>
          Repeat this process with the legislation of the countries that have
          extraterritorial reach, such as the USA (see Export Administration
          Regulations), even if you are not exporting your parcel to or from
          these countries.
        </p>
        <p>
          If you are not the manufacturer of the products, you may be able to
          obtain useful information from them as they know the technical
          specifications of their products. This will help understand if the
          products match any of the products covered by the export control (dual
          use) or sectoral sanctions lists.
        </p>
      </>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
  {
    question:
      "I am sending a parcel from UK to another country. Should I only look into legislation to determine if prohibitions or restrictions apply to my export?",
    answer: (
      <p>
        You should always comply with the export control rules of the country
        from which you export your parcel/your goods. However, the legislation
        of certain countries - such as the United States of America - have
        extraterritorial reach, ie. their legislation can apply even when the
        goods are not exported from or to their territories.
      </p>
    ),
    country: "EU/ROW",
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Customs Clearance",
    pageOnSite: "",
  },
];

const GENERAL_ACCOUNT_HELP_SUPPORT = [
  {
    country: "EU/ROW",
    question:
      "What are the weight restrictions on DPD's international services?",
    answer: (
      <p>
        When shipping on the DPD Classic, Air Classic and Air Express services,
        the maximum weight per parcel is 31.5kg. For the DPD Direct
        international tracked postal service, the maximum is 2kg.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU",
    question: "Are there any size restrictions on the DPD Classic service?",
    answer: (
      <p>
        The maximum parcel size and weight restrictions for the DPD Classic
        service are: Weight 31.5kg, Length 175cm and Girth 300cm. (Girth is
        calculated as = Length + (2 x Height) + (2 x Width) e.g. 80cm + 60cm +
        60cm + 50cm + 50cm = 300cm).
      </p>
    ),
    applicableService: "DPD Classic",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question: "What is the volumetric calculation for goods shipped by air?",
    answer: (
      <p>
        Items consigned by air are subject to volumetric conversion and will be
        charged either by volumetric weight or actual weight, whichever is
        greater. Volumetric calculation: Length (cm) x Height (cm) x Width (cm)
        / 5000, e.g. 70 x 50 x 40 / 5000 = 28 kg.
      </p>
    ),
    applicableService: "Air Classic / Air Express",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question:
      "What notifications are available on the rest of world air services?",
    answer: (
      <p>
        Along with full tracking visibility of your parcel, DPD offers four
        types of notifications on our air services. This includes notifications
        to your recipient to advise them that their parcel has been shipped and
        exported, has arrived in the destination country, and is out for
        delivery. This may differ dependent on destination.
      </p>
    ),
    applicableService: "Air Classic / Air Express",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question: "Who should I contact if I have a tracking query with my parcel?",
    answer: (
      <p>
        All your parcels can be fully tracked via our tracking page on
        <AnchorLink
          to="https://www.dpd.co.uk/content/how-can-we-help/contact.jsp"
          externalLink={true}
        >
          {" "}
          www.dpd.co.uk
        </AnchorLink>
        . Alternatively you can call our Customer Service team on 0121 336 4691,
        where our team of advisors will be happy to help.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "https://www.dpd.co.uk/content/how-can-we-help/contact.jsp",
  },
  {
    country: "EU/ROW",
    question: "Is there any liability if my parcel is lost or damaged?",
    answer: (
      <p>
        Details regarding liability cover for our international services can be
        found within our Standard Terms and Conditions of Trade. More
        information can be found at
        <AnchorLink
          to="https://www.dpd.co.uk/standard_terms_and_conditions.jsp"
          externalLink={true}
        >
          {" "}
          www.dpd.co.uk
        </AnchorLink>
        . Alternatively please contact your Account Manager.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "https://www.dpd.co.uk/standard_terms_and_conditions.jsp",
  },
  {
    country: "EU/ROW",
    question:
      "I'm not sure how to complete all the information required for the electronic customs data/commercial invoice. Where can I get help and support for this?",
    answer: (
      <>
        <p>
          We have a dedicated team of international delivery experts who will be
          happy to guide you through the process of shipping parcels abroad and
          offer you personalised support. Contact our team at
          internationalexperts@dpd.co.uk
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite:
      "International Uncovered > The how-to of exporting goods and the role of customs",
  },
  {
    country: "EU/ROW",
    question: "What happens if my parcel can't be delivered?",
    answer: (
      <p>
        We will always make every attempt to deliver your parcel, including up
        to three delivery attempts. In the unlikely event we have been unable to
        successfully reach the recipient or carry out a delivery, we will return
        the parcel to you. Please refer to our tariff for the undeliverable
        return charges{" "}
        <AnchorLink
          to="https://www.dpd.co.uk/pdf/dpd-tariff-guide.pdf"
          externalLink={true}
        >
          here
        </AnchorLink>
        . The tracking events will keep you informed as to the status of your
        parcel as well as explain the reason why we have not been able to
        deliver.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question:
      "Are there any items DPD can't carry on the international services?",
    answer: (
      <p>
        For Health & Safety and regulatory reasons, DPD is unable to carry
        certain items in its network. Our list of goods not permitted for
        carriage is{" "}
        <AnchorLink to={routes.ROW_PROHIBITED_GOODS} externalLink={true}>
          here
        </AnchorLink>
        . For some destinations there may also be restrictions at country level.
        Access our DPD Classic restricted goods list{" "}
        <AnchorLink to={routes.EU_PROHIBITED_GOODS} externalLink={true}>
          here
        </AnchorLink>
        . If you have a specific query, please contact our International Experts
        team, who will happy to assist you at internationalexperts@dpd.co.uk.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question: "Where does DPD deliver to?",
    answer: (
      <p>
        DPD offers a wide range of global delivery solutions across more than
        200 countries. Depending on your requirements for speed, delivery
        experience, customs clearance or cost, our offer covers everything you
        need. Whether you are sending to a business or looking for a tailored
        solution for your e-Commerce orders,{" "}
        <AnchorLink to={routes.OUR_SERVICES}>
          visit our services page for more insight and inspiration.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "Our Services",
  },
  {
    country: "EU/ROW",
    question: "What are DPD's capabilities for international services?",
    answer: (
      <>
        <p>
          Geopost is our international parcel delivery network present in over
          50 countries and growing. With 122,000 delivery experts and a network
          of 83,000 Pickup points, we deliver 8.4 million parcels worldwide each
          day – 2.1 billion parcels per year – through our brands DPD,
          Chronopost, SEUR, BRT and Jadlog. Geopost posted sales of €15.6
          billion in 2022. Geopost is a holding company owned by La Poste
          Groupe. Complementing Geopost’s existing international presence are
          also a number of strategic partnerships and joint ventures that ensure
          global coverage for a complete worldwide delivery offer.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.WHO_WE_ARE}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "About us",
  },
  {
    country: "EU/ROW",
    question: "What contact details should be provided?",
    answer: (
      <p>
        It&apos;s really important that the receiver&apos;s mobile number (in
        preference to landline) and email address are provided. This will ensure
        that we can keep your recipient updated as to the status of their parcel
        as well as easily contact them if needed for customs clearance. In some
        cases, by receiving our notifications, your recipient will also be able
        to make alternative arrangements if their plans change on the scheduled
        date of delivery. Don&apos;t forget that with our European road service,
        they will also be able to enjoy a precise timeslot for delivery with our
        Predict notification.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question: "What is customs compliance and why is it important?",
    answer: (
      <>
        <p>
          Customs compliance is the practice of respecting and following all the
          necessary rules and regulations when dealing with international trade,
          such as selling and sending goods abroad. As an exporter, you are
          responsible for ensuring that the data you submit with your parcel for
          use in customs clearance is accurate, detailed and complete. Taking
          proactive steps to ensure good customs compliance will help avoid
          customs clearance delays as well as to protect you and your business
          against more serious actions such as fines and penalties. At DPD, we
          have a dedicated team to support you with any customs compliance
          queries you may have. Contact us here at
          internationalexperts@dpd.co.uk or visit our compliance section for
          helpful tips on how to avoid common compliance risks.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.TIPS_FOR_EXPORT_SUCCESS}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite:
      "International Uncovered > Tips for Export Success and taking the complexity out of compliance",
  },
  {
    country: "EU/ROW",
    question: "How are Asendia and Geopost connected?",
    answer: (
      <>
        <p>
          Asendia, the joint venture between La Poste and Swiss Post, brings
          together logistics excellence and smart technologies to support
          e-commerce businesses to grow internationally. As part of La Poste
          Groupe, Asendia and Geopost have joined forces to combine their
          extensive international logistics expertise and network connections to
          build a tailored global e-commerce solution with the DPD Direct
          service.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.DPD_DIRECT}>here</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "Our Services > DPD Direct",
  },
  {
    country: "EU/ROW",
    question: "Are there any countries DPD is unable to deliver to?",
    answer: (
      <>
        <p>
          In order to ensure the utmost security of your parcels, we have a list
          of countries we unfortunately cannot send to for the time being. These
          are:
        </p>
        <p>Belarus</p>
        <p>Cuba</p>
        <p>Guinea Bissau</p>
        <p>Iran</p>
        <p>Kiribati</p>
        <p>Myanmar</p>
        <p>Nauru</p>
        <p>Niue Island</p>
        <p>Russia Federation</p>
        <p>Sierra Leone</p>
        <p>Somalia</p>
        <p>St Barthelemy</p>
        <p>St Maarten</p>
        <p>Sudan</p>
        <p>Syria</p>
        <p>Tonga</p>
        <p>Ukraine</p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question: "Can I send to a BFPO address?",
    answer: (
      <p>
        Unfortunately we currently are unable to send items to British Forces
        Post Offices (BFPO).
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question:
      "What happens if the customs charges are refused by the recipient?",
    answer: (
      <>
        <p>
          There is a risk that the parcel will be abandoned or returned to the
          sender if the recipient refuses to pay the import charges. These
          actions may not be free of charge, and each country has different
          rules. If you have a query regarding customs clearance, please visit
          our customs clearance section or contact our International Experts
          team at internationalexperts@dpdgroup.co.uk.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.YOUR_GUIDE_DUTIES}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "International Uncovered > Your Guide to Duties and Taxes",
  },
  {
    country: "EU/ROW",
    question: "Can I still export if I don't have an EORI number?",
    answer: (
      <>
        <p>
          If you regularly export goods abroad you will need an EORI to identify
          you to relevant customs authorities and HMRC. If you are VAT
          registered you can request an EORI{" "}
          <AnchorLink
            to="https://www.tax.service.gov.uk/shortforms/form/EORIVAT?dept-name=&sub-dept-name=&location=43&origin=http://www.hmrc.gov.uk/"
            externalLink={true}
          >
            here.
          </AnchorLink>{" "}
          If you are not VAT registered you can apply for an EORI{" "}
          <AnchorLink
            to=" https://www.tax.service.gov.uk/shortforms/form/EORINonVATExport?dept-name=&sub-dept-name=&location=43&origin=http://www.hmrc.gov.uk/"
            externalLink={true}
          >
            here
          </AnchorLink>
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.HOW_TO_EXPORT}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite:
      "International Uncovered > The how-to of exporting goods and the role of customs",
  },
  {
    country: "EU/ROW",
    question: "Why choose DPD as an international delivery provider?",
    answer: (
      <p>
        Shipping goods abroad, especially now that the UK is no longer part of
        the EU single market and customs union can, at times, appear a
        complicated, frustrating and daunting experience. At DPD our goal is
        simple: we care about your business and your plans for international
        expansion, and with our global network we can guide you through the best
        possible routes to market and help you avoid the pitfalls along the way.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express / DPD Direct",
    category: "General Account Help and Support",
    pageOnSite: "",
  },
  {
    country: "EU/ROW",
    question:
      "Where can I order more stationery for my shipping needs such as labels and bags?",
    answer: (
      <p>
        Please contact our Sales Support team, who will be happy to help with
        any replacement stationery orders on 0845 9 505 505 or 0121 336 4900.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "General Account Help and Support",
    pageOnSite: "n/a",
  },
];

const EU_VAT_IOSS = [
  {
    country: "EU",
    question: "What is the Import One Stop Shop (IOSS)?",
    answer: (
      <p>
        The Import One Stop Shop (IOSS) is the electronic portal businesses have
        been able to use since 1 July 2021 to comply with their VAT e-commerce
        obligations on distance sales of imported goods. By registering for
        IOSS, no VAT will be charged when the parcels arrive at the EU customs
        facility, speeding up the release into free circulation of the goods to
        the receiving customer.{" "}
        <AnchorLink to={routes.EU_VAT_CHANGES}>
          Visit our IOSS page for more information.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic",
    category: "EU VAT and Import One Stop Shop (IOSS)",
    pageOnSite:
      "International Uncovered > Shipping to the European Union> EU VAT Changes ",
  },
  {
    country: "EU",
    question:
      "What are the benefits of registering for the Import One Stop Shop (IOSS) for online sales?",
    answer: (
      <p>
        The IOSS facilitates and simplifies the collection, declaration and
        payment of VAT for online sales to EU customers. It covers the purchase
        of goods made by a buyer within the EU and for goods valued at less than
        €150.{" "}
        <AnchorLink to={routes.EU_VAT_CHANGES}>
          Visit our IOSS page for more information.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic",
    category: "EU VAT and Import One Stop Shop (IOSS)",
    pageOnSite:
      "International Uncovered > Shipping to the European Union> EU VAT Changes ",
  },
];

const PARCEL_TRACKING_TRANSIT_TIMES = [
  {
    country: "EU",
    question:
      "What is my customer's delivery experience once the goods have cleared customs?",
    answer: (
      <>
        <p>
          Keeping your customers informed is key to first-time delivery success
          no matter where they are in the world.
        </p>
        <p>
          With the DPD Classic service, your customer receives our innovative
          delivery notification solution, Predict. It offers your customers
          unparalleled choice and convenience in their local language. Predict
          lets recipients across Europe know the exact hour their parcels will
          arrive.
        </p>
        <p>
          By sending delivery information in advance via SMS or email,
          there&apos;s no more waiting around, while with our unique in-flight
          options, your customers can change the time or date of their delivery
          or divert their parcel to a local Pickup point.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.SHIPPING_TO_EU}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic",
    category: "Parcel Tracking and Transit Times",
    pageOnSite: "International Uncovered > Shipping to the European Union",
  },
  {
    country: "EU",
    question: "What are DPD's transit times to Europe?",
    answer: (
      <>
        <p>
          Our transit times are listed{" "}
          <AnchorLink
            to="https://www.dpd.co.uk/pdf/dpd-tariff-guide.pdf"
            externalLink={true}
          >
            here
          </AnchorLink>
          . Please be aware that parcels may be subject to customs delays.
        </p>
        <p>
          To find out more click{" "}
          <AnchorLink to={routes.SHIPPING_TO_EU}>here.</AnchorLink>
        </p>
      </>
    ),
    applicableService: "DPD Classic",
    category: "Parcel Tracking and Transit Times",
    pageOnSite: "International Uncovered > Shipping to the European Union",
  },
  {
    country: "EU/ROW",
    question: "How do I track my parcel going abroad?",
    answer: (
      <p>
        You can track all of your international shipments every step of the way
        via MyDPD at{" "}
        <AnchorLink
          to="https://www.dpd.co.uk/content/how-can-we-help/contact.jsp"
          externalLink={true}
        >
          www.dpd.co.uk.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Parcel Tracking and Transit Times",
    pageOnSite: "https://www.dpd.co.uk/service/",
  },
  {
    country: "EU/ROW",
    question:
      "What happens if my parcel is delayed on its way to the recipient?",
    answer: (
      <p>
        In the unlikely event your parcel is delayed, DPD will send a pro-alert
        notification to the recipient to advise them of the delay. You can also
        keep up to speed with any service updates by selecting your destination
        in our section <AnchorLink to={routes.BLOG}>here.</AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Parcel Tracking and Transit Times",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question: "How long does it take for my parcel to clear customs?",
    answer: (
      <p>
        Once your parcel has been exported from the UK, DPD will start to
        pre-lodge your customs information for import into the destination
        country to facilitate the process and help the parcel along its way. If
        your recipient is paying the duties and taxes, we&apos;ll contact them
        whilst the parcel is in transit to maximise the opportunity for this to
        happen and secure quicker clearance. You can also help by letting your
        customers know there may be duties and taxes to pay and to look out for
        the payment notification. If the recipient hasn&apos;t paid by the time
        the parcel arrives at our clearance facility, we will hold it for a
        further period of time and send a reminder. Once the duties and taxes
        have been paid the parcel will be released for delivery.
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Parcel Tracking and Transit Times",
    pageOnSite: "n/a",
  },
  {
    country: "EU/ROW",
    question:
      "Where can I find the international delivery schedule information?",
    answer: (
      <p>
        For information regarding global bank holidays and festivals, please
        visit our service updates section{" "}
        <AnchorLink to={routes.BLOG}>here.</AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic / Air Classic / Air Express",
    category: "Parcel Tracking and Transit Times",
    pageOnSite:
      "https://www.dpd.co.uk/content/products_services/dpd-international-delivery-schedule.jsp",
  },
];

const RECEIVING_PARCEL_OUTSIDE_UK = [
  {
    country: "EU",
    question:
      "I have been contacted by you to pay duties and taxes on my parcel. What are these?",
    answer: (
      <p>
        If you are receiving a parcel from outside the UK, DPD has to declare it
        through HM Revenue and Customs (HMRC). Such goods purchased from
        overseas may be liable for import customs charges. In this case, in
        order to receive your parcel you must first pay these import charges.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question: "Why do I have to pay duties and taxes on my parcel?",
    answer: (
      <p>
        You have to pay UK VAT on gifts worth more than £39 and other goods
        worth more than £15. Please note that VAT is charged on the total value,
        which includes the price you paid for the goods, carriage costs, and
        insurance, as well as any duty you may owe. The VAT will be added to the
        amount you pay when you buy the goods if the goods have a value below
        £135, unless it is a business to business shipment and the buyer of the
        parcel has given the seller their VAT number. HMRC will calculate the
        customs duties and VAT for all goods with a value greater than £135 once
        the parcel(s) have been received by DPD UK.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question: "How are import duty and tax charges calculated?",
    answer: (
      <p>
        These import charges are normally calculated based on the type of
        product and the value that has been declared from the customs
        information supplied by the sender of the goods. HM Revenue and Customs
        has provided a useful guide on understanding more about duties and taxes
        on goods sent from abroad,{" "}
        <AnchorLink
          to="https://www.gov.uk/goods-sent-from-abroad"
          externalLink={true}
        >
          which you can access here.
        </AnchorLink>
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question: "How do I pay the duties and taxes that are due?",
    answer: (
      <>
        <p>
          DPD will request payment of these charges by email or text and they
          will detail the import duties and taxes as well as a clearance fee.
          This fee covers the cost of customs processing and the administration
          cost associated with collecting the import charges.
        </p>
        <p>We accept the following electronic payment methods:</p>
        <p>
          Visa, MasterCard, Maestro, Discover, Apple Visa, Apple MasterCard,
          Google Visa, Google MasterCard and PayPal will also be available via
          the web link
        </p>
        <p>We do not accept BACS, American Express or cheques.</p>
      </>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question:
      "As a regular importer, can I pay import charges with my duty deferment account?",
    answer: (
      <p>
        For regular importers and after the first import and payment, DPD will
        provide the details of how to set up using your deferment account.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question:
      "Once I have paid the import charges, how long will it take to receive my parcel from DPD?",
    answer: (
      <p>
        Once you have successfully paid the import charges, DPD will send you a
        Predict notification advising you of when your parcel will be delivered.
        On the actual day of delivery we will also advise you of your one-hour
        timeslot. This will give you the option to change the delivery date or
        provide alternative delivery instructions.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question:
      "What happens if I believe I have already paid import charges when I purchased the goods, or I wasn't made aware of this?",
    answer: (
      <p>
        If you feel you have already paid these charges, please contact the
        seller of the goods for further information. DPD will have carried out
        the import of the parcel based on the instructions of the sender. In the
        meantime, these charges must be paid in accordance with HMRC’s import
        regulations before the parcel can be released for delivery. Failure to
        pay may result in the parcel being returned to sender.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question:
      "I don’t require the parcel anymore. What are my options and who can I speak to if I have a query with my parcel?",
    answer: (
      <p>
        Instructions regarding your options and contact information will be
        provided within the DPD duty and tax payment notification.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    country: "EU",
    question:
      "How long does DPD hold my parcel pending the duty and tax payment?",
    answer: (
      <p>
        DPD will hold your parcel awaiting payment for 5 working days after it’s
        arrival into the UK. During this time multiple reminder emails/SMS will
        be sent requesting payment. If no payment is received within 5 working
        days of arrival into the UK, the parcel will be returned to the EU
        sender.
      </p>
    ),
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },

  {
    question: "Can I apply for Postponed VAT Accounting?",
    answer: (
      <>
        <p>
          For regular importers and after the first import and payment, DPD will
          provide the details of how to set up Postponed VAT Accounting.
        </p>
      </>
    ),
    country: "EU",
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },

  {
    question: "Why was my parcel returned without a request for payment?",
    answer: (
      <>
        <p>
          Occasionally we will receive a parcel that does not have enough
          information for HMRC to calculate the import duty and tax. This means
          that DPD UK cannot clear the shipment for delivery and must return the
          parcel.
        </p>
      </>
    ),
    country: "EU",
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },

  {
    question: "I have been told my parcel has no data. What does this mean?",
    answer: (
      <>
        <p>
          In order for HMRC to calculate the import duty and tax we need to have
          certain information about the parcel, if this data is not provided to
          us or is incomplete the parcel is referred to as a no data parcel. If
          this happens we contact the sender’s local DPD team and request the
          missing data. If the data we need is not received within 5 days of the
          parcel arriving in the UK the parcel will be returned.
        </p>
      </>
    ),
    country: "EU",
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },

  {
    question: "When will my parcel be returned so I can be refunded?",
    answer: (
      <>
        <p>
          If you decide not to pay the customs and duties the parcel will be
          sent back the next working day. If the parcel was not cleared it will
          be returned the next working day. If we failed to deliver the parcel
          we return the parcels daily in most cases. If your parcel is a no data
          parcel we return these after 14 days on a weekly basis.
        </p>

        <p>
          We do not control how long it takes the parcel to get back to the
          sender once it has left DPD UK.
        </p>
      </>
    ),
    country: "EU",
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
  {
    question:
      "I need a copy of the import documents to get a refund on VAT (C79/SAD etc). How can I get them?",
    answer: (
      <>
        <p>
          You can download a copy of the import documents on the HMRC website.
          In order to do so, you will need to set up a government gateway
          account via{" "}
          <AnchorLink
            to="https://www.gov.uk/log-in-register-hmrc-online-services"
            externalLink={true}
          >
            https://www.gov.uk/log-in-register-hmrc-online-services
          </AnchorLink>
        </p>
      </>
    ),
    country: "EU",
    applicableService: "DPD Classic",
    category: "Receiving a parcel from outside the United Kingdom",
    pageOnSite: "",
  },
];

export const FAQ_DATA = [
  ...CUSTOMS_CHARGES,
  ...CUSTOMS_CLEARANCE,
  ...GENERAL_ACCOUNT_HELP_SUPPORT,
  ...EU_VAT_IOSS,
  ...PARCEL_TRACKING_TRANSIT_TIMES,
  ...RECEIVING_PARCEL_OUTSIDE_UK,
];
