import PropTypes from "prop-types";
import { Col, Row, Spinner } from "react-bootstrap";
import { isMobile, isTablet } from "react-device-detect";
import LoadingOverlay from "react-loading-overlay";

import { ReactComponent as DpdCube } from "../../assets/icons/symbolDpdRed.svg";
import leadTime from "../../assets/pages/our-services/dpd-classic/leadTime.png";
import { convert24HourTo12Hour } from "../../helpers/helpers";
import { useStore } from "../../store/store";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import styles from "./LeadTimeResults.module.scss";

export const LeadTimeResults = ({ data, loading, showError, errorMessage }) => {
  const { setShowContactUsModal } = useStore();

  const showBgImg = data || loading || showError;

  let height = "";
  if (isMobile || isTablet) {
    if (showBgImg) {
      height = "100%";
    }
  }

  if (isMobile && !showBgImg) {
    height = "300px";
  }

  if (isTablet && !showBgImg) {
    height = "447px";
  }

  return (
    <Col
      md={4}
      className={styles["lead-time-results-container"]}
      style={{
        background: showBgImg ? "#1D2226" : `url(${leadTime})`,
        backgroundRepeat: "no-repeat",
        height,
        width: "100%",
      }}
    >
      {loading && (
        <Row className={styles["lead-time-results-loader"]}>
          <LoadingOverlay
            active={loading}
            spinner={<Spinner animation="border" variant="danger" />}
          />
        </Row>
      )}

      {showError && (
        <div className={styles["lead-time-results-error"]}>
          <DpdCube />
          <p className={styles["lead-time-results-error-title"]}>
            {errorMessage.split(". ")[0]}
          </p>
          <p className={styles["lead-time-results-error-text"]}>
            {errorMessage.split(". ")[1]}
          </p>
        </div>
      )}

      {data && (
        <>
          <Row className={styles["lead-time-results-data"]}>
            <Col md={12}>
              <h3>DPD Classic</h3>
            </Col>
            <Col md={12}>
              <p>Lead Time</p>
            </Col>
            <Col md={12}>
              <h1>{data.leadTime}</h1>
            </Col>
            <Col md={12}>
              {data.leadTime === 1 ? (
                <h4>Working Day*</h4>
              ) : (
                <h4>Working Days*</h4>
              )}
            </Col>
            <Col md={12} className={styles["lead-time-results-data-subtext"]}>
              <p>
                Based on the parcel being at the depot by{" "}
                {convert24HourTo12Hour(data.cutOffTimeSendingDepot)}.
              </p>
              <p>
                Some deliveries may take longer due to international customs
                processing times and national holidays.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={12} lg={6}>
              <p>Need further assistance? </p>
            </Col>
            <Col md={12} lg={6}>
              <CallToActionBtn onClick={() => setShowContactUsModal(true)}>
                Contact Us
              </CallToActionBtn>
            </Col>
          </Row>
        </>
      )}
    </Col>
  );
};

LeadTimeResults.propTypes = {
  data: PropTypes.any,
  loading: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
};
