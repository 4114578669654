import { NavbarBrand } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import styles from "./Brand.module.scss";
import logoImg from "../../assets/icons/dpd-logo.png";
import { MENU } from "../../constants/actions";

export const Brand = () => {
  const tracker = useTracker();

  return (
    <NavbarBrand
      as={NavLink}
      href={"/"}
      to={"/"}
      title={"Home"}
      className={styles["mydpd-navbar-brand"]}
      onClick={() => tracker.logEvent(MENU.ON_CLICK_DPD_IMAGE)}
    >
      <img src={logoImg} width="76" height="32" alt="Site Logo" />
    </NavbarBrand>
  );
};
