import classNames from "classnames";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { Card, Col, Container, Row } from "react-bootstrap";
import { useCallback } from "react";

import { Trackable, useTracker } from "@dpdgroupuk/react-event-tracker";

import { FOOTER } from "../../constants/actions";
import { ANIMATIONS, DEFAULT_ANIMATION_DELAY } from "../../constants/constants";
import { useStore } from "../../store/store";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import { Body } from "../Typography/Body";
import { Heading2 } from "../Typography/Heading2";
import styles from "./GetInTouch.module.scss";

export const GetInTouch = ({
  cardTitle = "Ready to deliver with DPD?",
  cardText = "Get in touch with our international experts.",
  callToActionBtnText = "Get in touch",
  onActionBtnClick,
  classes,
}) => {
  const { setShowContactUsModal } = useStore();
  const tracker = useTracker();
  const getInTouchAction = useCallback(() => {
    setShowContactUsModal(true);
    tracker.logEvent(FOOTER.ON_CLICK_GET_IN_TOUCH);
  }, []);

  return (
    <Trackable loadId={FOOTER.ON_LOAD}>
      <section
        className={classNames(
          styles["get-in-touch-container"],
          "section-image"
        )}
      >
        <Container className={classes}>
          <Row className="text-center h-100 align-items-center">
            <Col md="12" className="d-flex flex-column">
              <ScrollAnimation
                animateIn={ANIMATIONS.FADE_IN_UP}
                delay={DEFAULT_ANIMATION_DELAY}
                initiallyVisible={false}
                animateOnce={true}
              >
                <Card className={styles.card}>
                  <Card.Body>
                    <Card.Title className={styles["card-title"]}>
                      <Heading2>{cardTitle}</Heading2>
                    </Card.Title>
                    <Card.Text>
                      <Body classes={styles["body-text"]}>{cardText}</Body>
                    </Card.Text>
                    <CallToActionBtn
                      onClick={onActionBtnClick || getInTouchAction}
                    >
                      {callToActionBtnText}
                    </CallToActionBtn>
                  </Card.Body>
                </Card>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
    </Trackable>
  );
};

GetInTouch.propTypes = {
  cardTitle: PropTypes.string,
  cardText: PropTypes.string,
  callToActionBtnText: PropTypes.string,
  classes: PropTypes.string,
  onActionBtnClick: PropTypes.func,
};
