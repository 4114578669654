import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import section1Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import { ReactComponent as ChecklistIcon } from "../../../../assets/icons/iconChecklist.svg";
import { ReactComponent as DangerIcon } from "../../../../assets/icons/iconDanger.svg";
import { ReactComponent as FileIcon } from "../../../../assets/icons/iconFile.svg";
import jumbotronBg from "../../../../assets/pages/international/shipping-to-eu/jumbotron-bg.png";
import section2Img from "../../../../assets/pages/international/shipping-to-eu/section-2-img.png";
import section3Bg from "../../../../assets/pages/international/shipping-to-eu/section-3-bg.png";
import section7Bg from "../../../../assets/pages/international/shipping-to-eu/section-7-bg.png";
import { BulletList } from "../../../../components/BulletList/BulletList";
import { CallToActionBtn } from "../../../../components/CallToActionBtn/CallToActionBtn";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { DownloadBtn } from "../../../../components/DownloadBtn/DownloadBtn";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading1 } from "../../../../components/Typography/Heading1";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../../constants/constants";
import * as router from "../../../../router/constants";
import variables from "../../../../styles/colors.scss";
import styles from "./ShippingToEu.module.scss";

export const ShippingToEu = () => {
  return (
    <>
      <Section bgImg={jumbotronBg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader headerText="Shipping to the European Union" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              On 1 January 2021, the United Kingdom left the European Union and
              is no longer part of the Single Market and Customs Union.
            </Heading2>
            <Heading3>
              This means that the parcels you export into the EU are subject to
              customs formalities and The UK and EU Trade and Co-operation
              Agreement is in effect. DPD takes care of these processes and
              procedures for you, but in order to do so, you need to declare
              additional information in the data manifest you submit to us when
              creating parcel labels in the form of Electronic Customs Data
              (ECD).
            </Heading3>
            <Body>
              ECD is the additional, mandatory information which tells customs
              authorities where the parcel is going to, what is inside the
              parcel and who is the exporter, importer and/or recipient as well
              as the International Commercial Terms.
            </Body>
            <Body>
              Read our Customs Data guide here for the additional information
              you need to supply
            </Body>
            <DownloadBtn downloadLink={router.CUSTOM_DETAILS}>
              Customs Data Guide (1.23mb)
            </DownloadBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <Heading2>Your parcel’s journey to Europe</Heading2>
              <Heading4>
                Let us walk you through with our step by step guide
              </Heading4>
              <ImgContainer imgs={section2Img} columnSpan={12} />
            </Row>

            <Row>
              <Heading3>
                Once we’ve collected your parcel, it arrives at our
                international gateway
              </Heading3>
              <Body>
                We check and validate the data to ensure your goods can be
                exported by using state-of-the-art electronic customs software
                to produce your customs declaration in The Customs Declaration
                Service (CDS) system and everything required for the truck to
                cross the border into Europe.
              </Body>
            </Row>

            <Row>
              <Heading3>What checks are made?</Heading3>
              <Body>
                We check that the data you have provided meets all the
                requirements to produce the export declaration. This includes
                checking:
              </Body>
              <CardContainer
                cards={[
                  {
                    cardText: (
                      <BulletList
                        listItems={[
                          {
                            text: "You have provided a valid commodity code (8 digits and correct according to the HMRC Trade Tariff)",
                          },
                          {
                            text: "You have provided a full and detailed description of goods as well as a weight and value of each item",
                          },
                          { text: "The EORI numbers you have supplied" },
                          {
                            text: "The types of goods you want to ship and whether DPD can carry them",
                          },
                          {
                            text: "You have supplied the Country of Origin for the goods",
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                cardsBgColor={variables.darkShade2}
                columnSpan={12}
              />
            </Row>

            <Row>
              <Heading3>
                We export your parcel to one of our European Customs Clearance
                hubs
              </Heading3>
              <Body>
                Once the trailers have left the UK and passed through the ports,
                our trucks go to one of our customs clearance Hubs, where the
                import clearance formalities take place into the EU.
              </Body>
              <Body>
                DPD has strategically located sites throughout Europe where we
                clear your parcels for import. All customs clearance processes
                are performed by DPD customs brokers in our own facilities
              </Body>
            </Row>

            <Row>
              <Heading3>We collect duties and taxes if payable</Heading3>
              <Body>
                As part of the EU Customs Clearance process, your goods are
                assessed for import duties and taxes. This assessment is based
                on the data you have provided in the Electronic Customs Data.
              </Body>
              <Body>
                Although the UK operates under the terms of the Trade and
                Cooperation Agreement, from 1 January 2021 it is no longer part
                of the European VAT area and import VAT is due upon arrival at
                the EU border. As DPD clears your goods in one of the Customs
                Clearance hubs before sending the goods to the final
                destination, please note it is the VAT rate of the country where
                the goods are imported that applies.
              </Body>
              <Body>
                If the total declared value of the items in your parcel is over
                the value of 150 Euros then depending on the type and origin of
                these goods, they are also assessed for duty according to the EU
                tariff. The type of goods and its duty rating are determined
                from the commodity code you have supplied and the origin of
                goods is taken from the country you have declared in the
                Electronic Customs Data record.
              </Body>
            </Row>

            <Row>
              <Heading3>How does DPD collect duties and taxes?</Heading3>
              <Body>
                Once the duties and taxes have been calculated, these charges
                will need to be paid before the parcel can continue its journey
                to the recipient.
              </Body>
              <Body>
                With DPD&apos;s default option, we will try and collect the
                amounts from the recipient. Whilst the parcel is still in
                transit to the clearance location, we notify the recipient by
                SMS and/or email. This is sometimes known as
                &apos;in-flight&apos; clearance to accelerate the payment
                process and avoid additional delays. This notification gives
                your customer all the information they require and a link to
                DPD&apos;s secure payment gateway to pay these charges
                electronically.
              </Body>
              <Body>
                Once paid, DPD will release the parcel and send it on its way.
                However, if the charges aren&apos;t paid before they arrive at
                our clearance hub, we will hold it securely until they do in our
                Customs Clearance facility. We will make several attempts to
                remind the recipient of their need to pay. If the charges remain
                unpaid, DPD will return the parcel to you in the UK.
              </Body>
              <Body>
                For B2C shipments, if you prefer a quicker Customs Clearance
                experience, DPD offers an additional service called &apos;Duties
                and Taxes Prepaid&apos;. Rather than contact your customers for
                payment, we can prepay these charges and bill them back to your
                DPD account. Your customer is still listed as the importer of
                record for this service. This can be set up on a
                shipment-by-shipment basis giving you complete flexibility.
                Please contact your Account Manager if you would like more
                information.
              </Body>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Important changes to EU VAT rules for B2C Online Sellers effective
              1st July 2021
            </Heading2>
            <Body>
              On 1 July 2021 the European Union introduced a number of
              wide-ranging VAT reforms and changes covering both the distance
              sales of goods and B2C services.
            </Body>
            <Body>
              When shipping to the EU from the United Kingdom, you will need to
              be aware of how they may affect you.
            </Body>
            <Body>
              For more information about these changes access our guide here
            </Body>
            <CallToActionBtn btnLink={router.EU_VAT_CHANGES}>
              Find out more about EU VAT Changes
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              Commercial Invoices and Additional Customs Documentation
            </Heading3>
            <Body>
              From time to time, the customs information you have declared in
              the Electronic Customs data record may be subject to further
              inspection and review by EU customs authorities. This is normal
              practice in the course of moving goods across international
              borders which can occur not only when your parcel is in transit
              but also after customs clearance has been completed.
            </Body>

            <Row className="px-5 pb-3">
              <BulletList
                listItems={[
                  {
                    text: (
                      <Body>
                        Should your parcel be subject to these additional
                        checks, our customer service team will contact you for
                        copies of the commercial invoice or additional
                        documentation. In order to comply with EU authorities
                        requests, this information must be supplied in a timely
                        manner to avoid your parcel being returned or detained
                        by customs.
                      </Body>
                    ),
                    icon: (
                      <span className="pt-4">
                        <ChecklistIcon />
                      </span>
                    ),
                  },
                  {
                    text: (
                      <Body>
                        If you export goods, it is strongly recommended
                        therefore that you should always have the necessary
                        information and commercial invoice copies available and
                        be ready to immediately supply as EU customs authorities
                        require documentations to be supplied on the day of
                        requests.
                      </Body>
                    ),
                    icon: (
                      <span className="pt-4">
                        <FileIcon />
                      </span>
                    ),
                  },
                ]}
              />
            </Row>

            <Row className={styles["alert-bullet-list"]}>
              <CardContainer
                cards={[
                  {
                    cardText: (
                      <BulletList
                        listItems={[
                          {
                            text: (
                              <Body>
                                Missing or inaccurate documents or information
                                can increase risks, lead to delays and <br />{" "}
                                extra costs, or prevent customs clearance from
                                being completed. This may also result in <br />{" "}
                                your goods being returned to you.
                              </Body>
                            ),
                            icon: <DangerIcon />,
                          },
                        ]}
                      />
                    ),
                  },
                ]}
                cardsBgColor="#E5444B"
                columnSpan={12}
              />
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Goods Checker</Heading3>
            <Body>
              Check which services are available for the types of goods you send
              with our handy goods checker
            </Body>
            <ExternalLinkBtn btnLink={router.EU_PROHIBITED_GOODS}>
              Goods Checker
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>VAT rates in Europe</Heading3>
            <Body>
              The framework for VAT in Europe is set by the EU VAT Directive,
              however each EU Member State has the freedom to set their own
              national VAT rates. VAT rates across Europe therefore vary.
            </Body>
            <ExternalLinkBtn btnLink={router.REVIEW_VAT_RATES}>
              Review the individual Member State VAT rates, published by the EU
              Commission here
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section7Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Want to know more?</Heading1>
            <Body>
              Let us answer your frequently asked questions when shipping to
              Europe in our FAQ section
            </Body>
            <CallToActionBtn btnLink={router.FAQS}>
              Visit our FAQs
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </>
  );
};
