import { Col, Row } from "react-bootstrap";

import * as routes from "../../router/constants";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";

export const CountrySearchError = () => {
  return (
    <Row>
      <Col md={12}>
        <div className="mt-2">
          <p style={{ color: "white", fontSize: "13px" }}>
            Our worldwide range of delivery services across more than 200
            countries covers everything you need to meet your requirements for
            speed, delivery experience, customs clearance and cost
          </p>
          <CallToActionBtn classes="w-100" btnLink={routes.OUR_SERVICES}>
            For more information check out Our Services
          </CallToActionBtn>
        </div>
      </Col>
    </Row>
  );
};
