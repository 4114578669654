import { useEffect, useState } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container } from "react-bootstrap";

import { ANIMATIONS } from "../../constants/constants";
import { COUNTRY_SPECIFIC_PROHIBITED_GOODS_PDFS } from "../../data/country";
import { camelize, isCountryRestOfWorld } from "../../helpers/helpers";
import {
  EU_PROHIBITED_GOODS,
  ROW_PROHIBITED_GOODS,
} from "../../router/constants";
import { useStore } from "../../store/store";
import { DownloadBtn } from "../DownloadBtn/DownloadBtn";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading5 } from "../Typography/Heading5";

export const CountryGoods = () => {
  const [countrySpecificPdf, setCountrySpecificPdf] = useState("");
  const { country } = useStore();

  useEffect(() => {
    if (country) {
      const countryName = camelize(country?.marketInfo.countryName);

      const pdf = COUNTRY_SPECIFIC_PROHIBITED_GOODS_PDFS[countryName];
      setCountrySpecificPdf(pdf);
    }
  }, [country]);

  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div>
        <Container>
          {country && isCountryRestOfWorld(country?.marketInfo.countryName) && (
            <Col md={12}>
              <Heading3>Check what you can and can’t send abroad</Heading3>
            </Col>
          )}

          <Col md={12}>
            <Body>
              For Health & Safety and regulatory reasons, DPD is unable to carry
              certain items in its road network, similarly some goods are also
              restricted for travel by air such as dangerous, flammable,
              hazardous and counterfeit items. Our list of prohibited goods not
              permitted for carriage is here:
            </Body>
          </Col>

          <Col md={12}>
            <DownloadBtn downloadLink={ROW_PROHIBITED_GOODS}>
              Prohibited Goods
            </DownloadBtn>
          </Col>

          {country &&
          isCountryRestOfWorld(country?.marketInfo.countryName) &&
          countrySpecificPdf ? (
            <>
              <Col md={12}>
                <Body>
                  For some destinations there may also be specific restrictions
                  at country level, access our restricted goods checker here:
                </Body>
              </Col>

              <Col md={12}>
                <DownloadBtn downloadLink={countrySpecificPdf}>
                  Country Specific Prohibited Goods
                </DownloadBtn>
              </Col>
            </>
          ) : (
            <Col md={12}>
              <Body>
                For some destinations there may also be specific restrictions at
                country level, access our restricted goods checker here:
              </Body>
              <DownloadBtn downloadLink={EU_PROHIBITED_GOODS}>
                Restricted Goods Checker
              </DownloadBtn>
            </Col>
          )}

          <Col md={12}>
            <Heading5>
              If you have a specific query, please contact our international
              experts team who will be happy to assist you at
              internationalexperts@dpd.co.uk
            </Heading5>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
};
