import classNames from "classnames";
import PropTypes from "prop-types";

export const BulletPoints = ({ children, classes }) => {
  return <ul className={classNames(classes, "BulletPoints")}>{children}</ul>;
};

BulletPoints.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
