import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import purpleTopCenterBg from "../../../../assets/backgrounds/purple-top-center-bg.png";
import purpleTopLeftBg from "../../../../assets/backgrounds/purple-top-left-bg.png";
import DBT1Bg from "../../../../assets/pages/international/our-partners/our-trade-partnerships/DBT-1-bg.png";
import DBT1Img from "../../../../assets/pages/international/our-partners/our-trade-partnerships/DBT-1-img.png";
import DBT2Img from "../../../../assets/pages/international/our-partners/our-trade-partnerships/DBT-2-img.png";
import IoEAndIT1Bg from "../../../../assets/pages/international/our-partners/our-trade-partnerships/IoE&IT-1-bg.png";
import IoEAndIT1Img from "../../../../assets/pages/international/our-partners/our-trade-partnerships/IoE&IT-1-img.png";

import { AnchorLink } from "../../../../components/AnchorLink/AnchorLink";
import { BulletList } from "../../../../components/BulletList/BulletList";
import { Bold } from "../../../../components/Typography/BlogArticle/Bold";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading1 } from "../../../../components/Typography/Heading1";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import { Heading5 } from "../../../../components/Typography/Heading5";
import { AccentMint } from "../../../../components/Typography/AccentMint";
import { AccentBlue } from "../../../../components/Typography/AccentBlue";
import { CallToActionBtn } from "../../../../components/CallToActionBtn/CallToActionBtn";
import { OUR_PARTNERS } from "../../../../constants/actions";
import { ReactComponent as BtnIcon } from "../../../../assets/icons/chevron_left.svg";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import * as routes from "../../../../router/constants";
import variables from "../../../../styles/colors.scss";

export const OurTradePartnerships = () => {
  return (
    <Trackable
      interfaceId={OUR_PARTNERS.INTERFACE_ID}
      loadId={OUR_PARTNERS.ON_LOAD}
    >
      <Section bgColor={variables.darkShade1}>
        <Col sm={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <CallToActionBtn
              btnLink={routes.OUR_PARTNERS}
              externalBtnLink={false}
            >
              <BtnIcon />
              Our Partners
            </CallToActionBtn>
            <Heading2>
              <Bold>International Trade Partnerships</Bold>
            </Heading2>
            <Heading4>
              At DPD we recognize the power of collaboration to achieve
              international trade success. Partnering with government
              organizations allows us to stay abreast of the latest
              international trade developments and best practices, ensuring{" "}
              <b>
                <AccentBlue>
                  your business remains ahead of the curve.
                </AccentBlue>
              </b>
            </Heading4>
          </ScrollAnimation>
        </Col>
        <Col className="d-flex flex-column align-items-center">
          <Container className="mb-4">
            <ImgContainer imgs={DBT1Img} columnSpan={10} />
          </Container>
          <Container>
            <ImgContainer imgs={IoEAndIT1Img} columnSpan={10} />
          </Container>
        </Col>
      </Section>

      <Section bgImg={purpleTopCenterBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              Working together in partnership with the Department for Business
              and Trade
            </Heading1>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={DBT2Img} columnSpan={6} />
      </Section>

      <Section bgImg={DBT1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              The UK Government&apos;s Department for Business and Trade and DPD
              have formed a strategic partnership to help UK companies sell
              overseas
            </Heading2>
            <Heading4>
              DBT advises, supports and promotes UK businesses to help them
              export and grow, and opens up new markets by removing barriers and
              striking trade deals. DPD gives UK firms access to thousands of
              potential new customers worldwide by providing them with the
              best-possible delivery experience. Both organisations share a
              common goal to support UK businesses as they export for the first
              time or expand their existing international reach
            </Heading4>
            <Heading5>
              DBT can help your business grow in new markets. Visit{" "}
              <AnchorLink
                to="https://great.gov.uk"
                externalLink={true}
                linkColor={variables.linkColor}
              >
                great.gov.uk
              </AnchorLink>{" "}
              to:
            </Heading5>
            <CardContainer
              cards={[
                {
                  cardText: (
                    <BulletList
                      listItems={[
                        {
                          text: (
                            <Body>
                              create an export plan tailored to your business,
                            </Body>
                          ),
                        },
                        {
                          text: (
                            <Body>
                              work through learning modules to build your
                              knowledge of how to sell internationally,
                            </Body>
                          ),
                        },
                        {
                          text: (
                            <Body>
                              identify high-potential markets for your products
                              and services,
                            </Body>
                          ),
                        },
                        {
                          text: (
                            <Body>
                              learn about online marketplaces and access
                              specially negotiated deals,
                            </Body>
                          ),
                        },
                        {
                          text: (
                            <Body>
                              search and apply for live export opportunities,
                              and
                            </Body>
                          ),
                        },
                        {
                          text: (
                            <Body>
                              explore and enquire about finance and insurance
                              support from UK Export Finance
                            </Body>
                          ),
                        },
                      ]}
                    />
                  ),
                },
              ]}
              columnSpan={12}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={purpleTopLeftBg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>Other DBT support on offer includes:</Heading2>
            <Row>
              <Col md={12} className="text-center">
                <CardContainer
                  cards={[
                    {
                      cardTitle: (
                        <Heading2>
                          <AccentMint>UK Export Academy</AccentMint>
                        </Heading2>
                      ),
                      cardText: (
                        <>
                          <Body>
                            A comprehensive training offer featuring foundation
                            courses, masterclasses and events
                          </Body>
                          <ExternalLinkBtn
                            classes={"btn-mint"}
                            btnLink={routes.UK_EXPORT_ACADEMY}
                          >
                            Find Out More
                          </ExternalLinkBtn>
                        </>
                      ),
                      columnSpan: 4,
                    },
                    {
                      cardTitle: (
                        <Heading2>
                          <AccentMint>Events</AccentMint>
                        </Heading2>
                      ),
                      cardText: (
                        <>
                          <Body>
                            Register for webinars, workshops, trade shows and
                            more via the department’s events calendar
                          </Body>
                          <ExternalLinkBtn
                            classes={"btn-mint"}
                            btnLink={routes.REGISTER_FOR_EVENT}
                          >
                            Register for Events
                          </ExternalLinkBtn>
                        </>
                      ),
                      columnSpan: 4,
                    },
                    {
                      cardTitle: (
                        <Heading2>
                          <AccentMint>
                            <span style={{ whiteSpace: "nowrap" }}>
                              International
                            </span>{" "}
                            Market Support
                          </AccentMint>
                        </Heading2>
                      ),
                      cardText: (
                        <>
                          <Body>
                            UK companies can apply for help to attend, or
                            exhibit at, overseas trade shows and conferences,
                            potentially including grants to offset some costs
                          </Body>
                          <ExternalLinkBtn
                            classes={"btn-mint"}
                            btnLink={routes.UK_TRADESHOW}
                          >
                            Find Out More
                          </ExternalLinkBtn>
                        </>
                      ),
                      columnSpan: 4,
                    },
                    {
                      cardTitle: (
                        <Heading2>
                          <AccentMint>Export Support Team</AccentMint>
                        </Heading2>
                      ),
                      cardText: (
                        <>
                          <Body>
                            This is the first point of contact for UK businesses
                            to get answers to questions about exporting
                          </Body>
                          <ExternalLinkBtn
                            classes={"btn-mint"}
                            btnLink={routes.EXPERT_SUPPORT_TEAM}
                          >
                            Find Out More
                          </ExternalLinkBtn>
                        </>
                      ),
                      columnSpan: 4,
                    },
                    {
                      cardTitle: (
                        <Heading2>
                          <AccentMint>Your Local DBT Office</AccentMint>
                        </Heading2>
                      ),
                      cardText: (
                        <>
                          <Body>
                            DBT has teams around England as well as in Belfast,
                            Edinburgh and Cardiff, offering a range of export
                            support
                          </Body>
                          <ExternalLinkBtn
                            classes={"btn-mint"}
                            btnLink={routes.INTERNATIONAL_TRADE_ADVISORS}
                          >
                            Find Out More
                          </ExternalLinkBtn>
                        </>
                      ),
                      columnSpan: 4,
                    },
                    {
                      cardTitle: (
                        <Heading2>
                          <AccentMint>Free Trade Agreements</AccentMint>
                        </Heading2>
                      ),
                      cardText: (
                        <>
                          <Body>
                            If your business sells to or buys from other
                            countries, trade deals can create new opportunities
                            for you in fast-growing markets around the world
                          </Body>
                          <ExternalLinkBtn
                            classes={"btn-mint"}
                            btnLink={routes.FREE_TRADE_AGREEMENT}
                          >
                            Find Out More
                          </ExternalLinkBtn>
                        </>
                      ),
                      columnSpan: 4,
                    },
                  ]}
                />
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={purpleTopCenterBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading4>Our Partners</Heading4>
            <Heading2>
              Empowering you in{" "}
              <AccentBlue>
                <Bold>global trade</Bold>
              </AccentBlue>
            </Heading2>
            <Heading4>
              Institute of Export and International Trade is now the{" "}
              <Bold>Chartered Institute of Export & International Trade.</Bold>
            </Heading4>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={IoEAndIT1Img} />
      </Section>

      <Section
        bgImg={IoEAndIT1Bg}
        style={{
          backgroundPosition: "center",
        }}
      >
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              The Chartered Institute of Export & International Trade
            </Heading3>
            <Heading2>
              Online guides, videos and tools helping businesses learn about
              international trade
            </Heading2>
            <Body>
              The institute offers a wealth of online resources to help
              businesses, both members and non-members, navigate the
              complexities of international trade.
            </Body>
            <Body>
              Their extensive library includes guides on key topics and specific
              overseas markets. Businesses can also access recordings of
              industry-leading webinars and utilize tools designed to{" "}
              <Bold>streamline exports and imports.</Bold>
            </Body>
            <Body>
              In addition to these publicly available resources, the institute
              provides exclusive benefits and services to its members, all
              geared towards supporting their international growth.
            </Body>
            <div className="d-flex flex-column align-items-center">
              <CallToActionBtn
                btnLink="https://www.export.org.uk/membership/"
                externalBtnLink={true}
              >
                Find out more
              </CallToActionBtn>
            </div>
          </ScrollAnimation>
        </Col>
      </Section>
      <GetInTouch />
    </Trackable>
  );
};
