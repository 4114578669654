export const trimString = string => string && string.trim();
export const trimStringToMaxLength = (inputString, maxLength) => {
  if (inputString.length > maxLength) {
    return inputString.slice(0, maxLength);
  }
  return inputString;
};

export const isEmpty = item =>
  [Object, Array].includes((item || {}).constructor) &&
  !Object.entries(item || {}).length;
