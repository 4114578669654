import PropTypes from "prop-types";
import ReactPaginate from "react-paginate";

import styles from "./SearchPagination.module.scss";

export const SearchPagination = ({
  setItemOffset,
  fuzzySearchList,
  itemsPerPage,
  currentPage,
  setCurrentPage,
}) => {
  const pageCount = Math.ceil(fuzzySearchList.length / itemsPerPage);

  const handlePageClick = event => {
    const newOffset = (event.selected * itemsPerPage) % fuzzySearchList.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={">"}
      onPageChange={handlePageClick}
      forcePage={currentPage}
      pageRangeDisplayed={3}
      marginPagesDisplayed={0}
      pageCount={pageCount}
      previousLabel={"<"}
      renderOnZeroPageCount={null}
      containerClassName={styles.pagination}
      previousLinkClassName={styles["pagination-link"]}
      nextLinkClassName={styles["pagination-link"]}
      disabledClassName={styles["pagination-link-disabled"]}
      activeClassName={styles["pagination-link-active"]}
      previousClassName={styles.previousLabel}
      nextClassName={styles.nextLabel}
    />
  );
};

SearchPagination.propTypes = {
  itemOffset: PropTypes.number,
  setItemOffset: PropTypes.func,
  fuzzySearchList: PropTypes.arrayOf(PropTypes.any),
  itemsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
};
