import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import section1Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import jumbotronBg from "../../../../assets/pages/international/export-controls/cardExportControls.png";
import section7Bg from "../../../../assets/pages/international/export-controls/section-7.png";
import { BulletList } from "../../../../components/BulletList/BulletList";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../../components/Section/Section";
import { Heading1 } from "../../../../components/Typography/Heading1";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import { Heading5 } from "../../../../components/Typography/Heading5";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../../constants/constants";
import { AnchorLink } from "../../../../components/AnchorLink/AnchorLink";
import { EXPORT_CONTROLS_LINKS, FAQS } from "../../../../router/constants";
import { ReactComponent as BulletIcon } from "../../../../assets/icons/iconArrowUpRight.svg";
import variables from "../../../../styles/colors.scss";
import { Body } from "../../../../components/Typography/Body";
import { CallToActionBtn } from "../../../../components/CallToActionBtn/CallToActionBtn";
import styles from "./ExportControls.module.scss";

export const ExportControls = () => {
  return (
    <>
      <Section bgImg={jumbotronBg} classes={styles["header-image"]}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader headerText="What are Export Controls?" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              DPD UK has controls in place to monitor all Exports for the
              presence of licensable goods. Goods suspected of containing
              licensable products can experience delays and additional evidence
              may be required for goods to proceed.
            </Heading3>
            <Heading4>
              By choosing the services of DPD UK, you are choosing a reliable
              partner who is committed to compliance with international trade
              rules.
            </Heading4>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <Heading3>
                Compliance Checks on your International Shipments - Trade
                Sanctions, Embargoes and Export Controls
              </Heading3>
              <Heading5>
                DPD is committed to conducting its services according to all
                applicable laws and regulations throughout the world and, of
                course, our Standard Terms and Conditions of Carriage.
              </Heading5>

              <Body>
                Further to the Sanctions and{" "}
                <AnchorLink
                  to="https://www.legislation.gov.uk/ukpga/2018/13/contents/enacted"
                  externalLink={true}
                  linkColor={variables.linkColor}
                >
                  Anti-Money Laundering Act 2018, the Export Control Order 2008
                </AnchorLink>{" "}
                and other applicable country regulations, DPD is required to
                implement various controls. These range from verifying its
                ability to trade with all business contacts to ensuring that
                goods subject to restrictions or special export authorisations
                are exported lawfully. This is done by automated screening of
                sender and receiver names and addresses against binding trade
                sanctions, embargoes and other lists that are maintained by
                government departments worldwide. In addition, the data
                pertaining to the contents of the parcel is screened against
                lists of restricted and prohibited goods (such as dual use goods
                and goods subject to sanctions-related restrictions on certain
                destinations).
              </Body>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3 classes="text-center">
              Access useful guidance materials on Export Controls:
            </Heading3>
            <CardContainer
              cards={[
                {
                  cardText: (
                    <BulletList
                      listItems={[
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={
                                  EXPORT_CONTROLS_LINKS.UK_STRATEGIC_EXPORT_CONTROLS
                                }
                              >
                                UK Government guidance on UK Strategic Export
                                Controls
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={EXPORT_CONTROLS_LINKS.UK_SANCTIONS}
                              >
                                UK Government guidance on UK Sanctions
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={
                                  EXPORT_CONTROLS_LINKS.EXPORT_ADMINISTRATION_REGULATIONS
                                }
                              >
                                US Department of Commerce’s guidance on
                                classifying items subject to the Export
                                Administration Regulations
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={
                                  EXPORT_CONTROLS_LINKS.EXPORTING_DUAL_USE_ITEMS
                                }
                              >
                                European Commission on Exporting Dual Use items
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={EXPORT_CONTROLS_LINKS.EU_SANCTIONS_MAP}
                              >
                                EU Sanctions map
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={
                                  EXPORT_CONTROLS_LINKS.UNITED_NATIONS_SECURITY_COUNCIL
                                }
                              >
                                United Nations Security Council
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                        {
                          text: (
                            <Heading5>
                              <AnchorLink
                                externalLink={true}
                                to={
                                  EXPORT_CONTROLS_LINKS.FOREIGN_ASSETS_CONTROL
                                }
                              >
                                Office of Foreign Assets Control
                              </AnchorLink>
                            </Heading5>
                          ),
                        },
                      ]}
                      listIcon={<BulletIcon />}
                    />
                  ),
                },
              ]}
              columnSpan={12}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <Heading3>
                Ensuring we comply with the above rules may mean we will need to
                hold your parcel, to undertake more detailed checks before DPD
                UK is able to release the consignment for onward transit.
              </Heading3>
              <Heading4>
                The screening may also lead to requests for additional
                information; we would ask for your support with providing any
                further information, so that we can, if allowed, ensure the
                speedy delivery of your shipments.
              </Heading4>
              <Heading4>
                You can contribute to speeding up any necessary checks by
                providing accurate customs data (HS codes) and meaningful
                descriptions of the contents of your international parcels.
              </Heading4>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section7Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Got another question?</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={FAQS}>Visit our FAQs</CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </>
  );
};
