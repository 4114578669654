import { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { Marker } from "react-map-gl";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { fetchCountryMapboxData } from "../../apis/api";
import mapPin from "../../assets/icons/map-pin.png";
import selectedMapPin from "../../assets/icons/selected-map-pin.png";
import { WORLD_MAP } from "../../constants/actions";
import { useStore } from "../../store/store";

export const MapPin = ({
  place,
  coordinates,
  country,
  setIsoCode,
  location,
}) => {
  const [isPinStrategicLocation, setIsPinStrategicLocation] = useState(false);
  const {
    openCountrySideDrawer,
    resetCountrySideDrawer,
    resetCountry,
    selectedPin,
    setSelectedPin,
    locations,
    setSelectedStrategicLocation,
    setCountrySideDrawerContentType,
  } = useStore();

  const tracker = useTracker();

  useEffect(() => {
    if (!selectedPin) {
      setIsoCode(null);
    }
  }, [selectedPin]);

  const isStrategicLocation = () => {
    return locations.strategicLocations.find(
      ({ address, description }) => address === place && description !== ""
    );
  };

  const handleOnClick = async () => {
    if (isStrategicLocation()) {
      setIsPinStrategicLocation(true);
      resetCountrySideDrawer();
      resetCountry();
      setSelectedPin(place);
      openCountrySideDrawer();
      setSelectedStrategicLocation(location);
      setCountrySideDrawerContentType("strategicLocation");

      const { countryIsoCode } = await fetchCountryMapboxData(country);
      setIsoCode(countryIsoCode);
    }

    tracker.logEvent(WORLD_MAP.ON_CLICK_DPD_ICON);
  };

  const handleMouseEnter = () => {
    if (isStrategicLocation()) {
      setIsPinStrategicLocation(true);
    }
  };

  return (
    <Marker
      latitude={coordinates[0]}
      longitude={coordinates[1]}
      pitchAlignment="viewport"
      rotationAlignment="viewport"
    >
      <img
        src={selectedPin === place ? selectedMapPin : mapPin}
        onClick={handleOnClick}
        onMouseEnter={handleMouseEnter}
        style={{
          cursor: isPinStrategicLocation ? "pointer" : "default",
        }}
      />
    </Marker>
  );
};

MapPin.propTypes = {
  place: PropTypes.string,
  coordinates: PropTypes.arrayOf(PropTypes.number),
  country: PropTypes.string,
  setIsoCode: PropTypes.func,
  location: PropTypes.any,
};
