import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import purpleTopLeftBg from "../../../../assets/backgrounds/purple-top-left-bg.png";
import purpleCenterBg from "../../../../assets/backgrounds/purple-center-bg.png";
import asendiaLogo from "../../../../assets/pages/international/our-partners/our-group-partnerships/asendia/asendia-logo.png";
import asendia1Bg from "../../../../assets/pages/international/our-partners/our-group-partnerships/asendia/asendia-1-bg.png";
import asendia2Bg from "../../../../assets/pages/international/our-partners/our-group-partnerships/asendia/asendia-2-bg.png";
import asendia2Img from "../../../../assets/pages/international/our-partners/our-group-partnerships/asendia/asendia-2-img.png";
import asendia3Img from "../../../../assets/pages/international/our-partners/our-group-partnerships/asendia/asendia-3-img.png";
import ESWLogo from "../../../../assets/pages/international/our-partners/our-group-partnerships/esw/esw-logo.png";
import ESW1Bg from "../../../../assets/pages/international/our-partners/our-group-partnerships/esw/esw-1-bg.png";
import ESW1Img from "../../../../assets/pages/international/our-partners/our-group-partnerships/esw/esw-1-img.png";
import ESWVideo from "../../../../assets/pages/international/our-partners/our-group-partnerships/esw/ESWVideo.MP4";
import aramexLogo from "../../../../assets/pages/international/our-partners/our-group-partnerships/aramex/aramex-logo.png";
import aramex1Bg from "../../../../assets/pages/international/our-partners/our-group-partnerships/aramex/aramex-1-bg.webp";
import aramex2Bg from "../../../../assets/pages/international/our-partners/our-group-partnerships/aramex/aramex-2-bg.webp";
import DTDCLogo from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-logo.png";
import DTDCLogoWhite from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-logo-white.png";
import DTDCIcon1 from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-icon-1.png";
import DTDCIcon2 from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-icon-2.png";
import DTDCIcon3 from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-icon-3.png";
import DTDCIcon4 from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-icon-4.png";
import DTDCIcon5 from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-icon-5.png";
import DTDCIcon6 from "../../../../assets/pages/international/our-partners/our-group-partnerships/dtdc/dtdc-icon-6.png";

import { BulletList } from "../../../../components/BulletList/BulletList";
import { Bold } from "../../../../components/Typography/BlogArticle/Bold";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading1 } from "../../../../components/Typography/Heading1";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import { AccentMint } from "../../../../components/Typography/AccentMint";
import { AccentRed } from "../../../../components/Typography/AccentRed";
import { CallToActionBtn } from "../../../../components/CallToActionBtn/CallToActionBtn";
import { ReactComponent as BtnIcon } from "../../../../assets/icons/chevron_left.svg";
import { OUR_PARTNERS } from "../../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import * as routes from "../../../../router/constants";
import variables from "../../../../styles/colors.scss";

export const OurGroupPartnerships = () => {
  const DTDCIcons = [
    {
      image: DTDCIcon1,
      text: "Wide range of domestic services with pan India coverage",
    },
    { image: DTDCIcon2, text: "Serving 96% of Indian population" },
    {
      image: DTDCIcon3,
      text: "A convenient, reliable and cost-competitive international shipping experience",
    },
    {
      image: DTDCIcon4,
      text: "Integrated express logistics app and web services",
    },
    { image: DTDCIcon5, text: "With a rich history of 35+ years" },
    { image: DTDCIcon6, text: "16,000+ customer access points" },
  ];

  return (
    <Trackable
      interfaceId={OUR_PARTNERS.INTERFACE_ID}
      loadId={OUR_PARTNERS.ON_LOAD}
    >
      <Section bgImg={purpleTopLeftBg}>
        <ScrollAnimation
          animateIn={ANIMATIONS.FADE_IN_LEFT}
          delay={DEFAULT_ANIMATION_DELAY}
          animateOnce={true}
        >
          <Col>
            <CallToActionBtn
              btnLink={routes.OUR_PARTNERS}
              externalBtnLink={false}
            >
              <BtnIcon />
              Our Partners
            </CallToActionBtn>
            <Heading1>
              <AccentRed>
                <Bold>Our Group Partnerships</Bold>
              </AccentRed>
            </Heading1>
            <Heading3>
              We are proud to collaborate with a diverse network of partners,
              leveraging collective expertise to expand our reach, enhance
              services, and drive business growth
            </Heading3>
            <Heading4>
              These strategic alliances span across logistics, technology, and
              sustainability, enabling us to deliver exceptional value to our
              customers while making a positive impact on the world
            </Heading4>
          </Col>
          <Row className="col-md-6 align-items-center flex-nowrap">
            <ImgContainer imgs={asendiaLogo} />
            <ImgContainer imgs={ESWLogo} />
            <ImgContainer imgs={aramexLogo} />
            <ImgContainer imgs={DTDCLogoWhite} />
          </Row>
          {/* <Row className="col-md-6 align-items-center flex-nowrap">
            <ImgContainer imgs={TGELogo} />
          </Row> */}
        </ScrollAnimation>
      </Section>

      <Section bgImg={asendia1Bg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ImgContainer imgs={asendiaLogo} columnSpan={12} />
            <Heading1>
              Asendia, empowering businesses to grow across borders
            </Heading1>
            <Heading4>
              Asendia empowers businesses to grow across borders with a range of
              international e-commerce and mail delivery services
            </Heading4>
            <Body>
              Launched in 2012 by La Poste and Swiss Post, and with operations
              across the world, it brings together a wealth of international and
              local expertise, and a network that delivers parcels and mail to
              over 200 countries and territories
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={asendia2Bg}>
        <ImgContainer imgs={asendia2Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Today they are also <br /> at the forefront <br /> of providing{" "}
              <br /> e-commerce parcel solutions for e-tailers selling
              internationally
            </Heading2>
            <Heading4>
              Providing a number of value-added services, including returns,
              fulfillment and letter shop, Asendia have taken a stake in a
              number of companies to enhance their services
            </Heading4>
            <Body>
              This includes the acquisition of ESW, a global e-commerce software
              business, and investment in Anchanto, a platform that helps
              businesses sell on multiple online channels
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <ImgContainer imgs={asendia3Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <AccentMint>ESW POWERS CUSTOMERS GROWTH</AccentMint> FOR THE
              WORLD’S TOP APPAREL, BEAUTY, LUXURY, AND ELECTRONICS BRANDS
            </Heading3>
            <Body>
              DPD and ESW partner to create a new product and service offering
              that helps brands and retailers grow and scale their ecommerce
              revenues across borders with ease and speed
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={ESW1Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <AccentMint>
                ESW is the leading global direct-to-consumer (DTC) ecommerce
                company, empowering the world’s best-loved brands and retailers
                to expand their international reach
              </AccentMint>
            </Heading3>
            <Body>
              ESW powers DTC ecommerce through a full suite of localization,
              conversion optimization, omnichannel, and logistics features that
              helps sell anywhere in the world from their existing webstore.
              They cover the entire shopper journey across 200+ markets.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Container
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ReactPlayer url={ESWVideo} controls={true} />
        </Container>
      </Section>

      <Section>
        <CardContainer
          cards={[
            {
              cardText: (
                <>
                  <Heading3>
                    <AccentMint>
                      <Bold>
                        A CUSTOMER-CENTRIC DIGITAL JOURNEY THAT’S MET BY A
                        BEST-IN-CLASS GLOBAL SUPPLY CHAIN
                      </Bold>
                    </AccentMint>
                  </Heading3>
                  <BulletList
                    listItems={[
                      {
                        text: (
                          <Body>
                            <Bold>Go live in 4-10 weeks</Bold> in 200+ global
                            markets, reducing costs by plugging into ESW’s
                            unmatched global infrastructure - without
                            compromising the speed
                          </Body>
                        ),
                      },
                      {
                        text: (
                          <Body>
                            <Bold>
                              89% average increase in conversion with a
                              localised experience
                            </Bold>
                            , offering pricing in local currency, top payment
                            methods, and duty & tax calculations
                          </Body>
                        ),
                      },
                      {
                        text: (
                          <Body>
                            Ensure your goods can be exported securely and
                            safely with DPD in-house{" "}
                            <Bold>Customs Brokerage system</Bold>
                          </Body>
                        ),
                      },
                      {
                        text: (
                          <Body>
                            Offer best-in-class customer experience through a{" "}
                            <Bold>
                              fully branded returns & tracking portal and DPD
                              in-flight options
                            </Bold>
                          </Body>
                        ),
                      },
                    ]}
                  />
                  <div className="text-center mt-3">
                    <CallToActionBtn
                      btnLink="https://esw.com/"
                      externalBtnLink={true}
                    >
                      Learn more
                    </CallToActionBtn>
                  </div>
                </>
              ),
            },
          ]}
          columnSpan={12}
        />
      </Section>

      <Section bgImg={purpleCenterBg}>
        <Col md={7}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              <AccentMint>
                <Bold>A DTC BRAND GUIDE TO INTERNATIONAL EXPANSION </Bold>
              </AccentMint>
            </Heading3>
            <Body>
              2023 is filled with ecommerce uncertainty as well as opportunity.
              From emerging global markets to consumers who see the world as one
              unified storefront, brands and retailers can implement strategies
              that increase revenue and build brand loyalty
            </Body>
            <Body>
              ESW surveyed more than 16,000 consumers from 16 countries to gain
              insight into their shopping behaviours. We asked them about
              everything from what they buy and why they buy it to their
              anticipated spending behaviour
            </Body>
            <CallToActionBtn
              btnLink=" https://go.esw.com/gv-dtc-brand-guide?utm_source=referral+&utm_medium=partner&utm_campaign=dpd"
              externalBtnLink={true}
            >
              Discover ESW Whitepaper
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={ESW1Img} columnSpan={5} />
      </Section>

      <Section bgImg={aramex1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              Geopost and Aramex have a special partnership that has created a
              powerful global network for air freight and worldwide express
              solutions
            </Heading3>
            <Heading4>
              This collaboration leverages the complementary strengths of both
              Aramex, renowned for its innovative solutions and expertise in
              high-growth markets, and DPD, with its unparalleled reliability
              and extensive European network. The result is a seamless and
              efficient shipping experience for our valued customers
            </Heading4>
            <Heading4>
              This strategic partnership ensures access to cutting-edge
              technology, network infrastructure and unwavering commitment to
              keeping you informed throughout the entire delivery process
            </Heading4>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={aramex2Bg}>
        <Col sm={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Aramex: Our Expert Partner to the Middle East</Heading3>
            <Heading4>
              Founded in 1982, Aramex is a global leader in logistics and
              transportation throughout the Middle East, renowned for its
              innovative services. Their strategic location in the UAE helps
              ensure your customers, wherever they are based in the Middle East
              are well served by one of the biggest delivery networks in the
              region
            </Heading4>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade2}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Container className="mb-5">
              <ImgContainer imgs={DTDCLogo} columnSpan={12} />
            </Container>
            <Heading2>
              Fuelling India&apos;s commerce with cutting-edge technology and
              unparalleled reach, DTDC is at the forefront of integrated express
              logistics
            </Heading2>
            <Heading4>
              Their extensive network of customer access points and diverse
              portfolio of solutions cater to every need, ensuring a smooth and
              satisfying delivery experience for all
            </Heading4>
            <Body>
              In 2013, DTDC entered into a strategic partnership with GeoPost,
              the express logistics arm of Le Groupe La Poste (La Poste)
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Row>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Container className="mb-5">
              <ImgContainer imgs={DTDCLogo} columnSpan={12} />
            </Container>
            <Row xs={2} sm={3}>
              {DTDCIcons.map(icon => (
                <Col
                  key={icon.text}
                  className="text-center d-flex flex-column align-items-center"
                >
                  <ImgContainer imgs={icon.image} />
                  <Body>{icon.text}</Body>
                </Col>
              ))}
            </Row>
          </ScrollAnimation>
        </Row>
      </Section>
      <GetInTouch />
    </Trackable>
  );
};
