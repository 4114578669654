import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";
import ReactPlayer from "react-player";

import section1Bg from "../../../../assets/backgrounds/purple-top-bg.png";
import video from "../../../../assets/pages/international/how-to-export/dpd_customs_clearance_v1.mp4";
import jumbotronBg from "../../../../assets/pages/international/how-to-export/jumbotron-bg.png";
import section2Img from "../../../../assets/pages/international/how-to-export/section-2-img.png";
import section3Img from "../../../../assets/pages/international/how-to-export/section-3-img.png";
import { BulletList } from "../../../../components/BulletList/BulletList";
import { CallToActionBtn } from "../../../../components/CallToActionBtn/CallToActionBtn";
import { CardContainer } from "../../../../components/CardContainer/CardContainer";
import { DownloadBtn } from "../../../../components/DownloadBtn/DownloadBtn";
import { ExportFundamentalsHeader } from "../../../../components/ExportFundamentalsHeader/ExportFundamentalsHeader";
import { ExternalLinkBtn } from "../../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../../components/Section/Section";
import { Body } from "../../../../components/Typography/Body";
import { Heading2 } from "../../../../components/Typography/Heading2";
import { Heading3 } from "../../../../components/Typography/Heading3";
import { Heading4 } from "../../../../components/Typography/Heading4";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../../../constants/constants";
import * as router from "../../../../router/constants";
import variables from "../../../../styles/colors.scss";

export const HowToExport = () => {
  return (
    <>
      <Section bgImg={jumbotronBg}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <ExportFundamentalsHeader headerText="The how-to of exporting goods and the role of customs" />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Are you looking to export for the first time or new to exporting?{" "}
            </Heading2>
            <Heading3>
              Have you exported before but want to see what’s changed,
              especially regarding exporting to the EU?
            </Heading3>
            <Body>
              Exporting to international markets may seem daunting or difficult
              to understand at times, but our useful guide will give you a clear
              overview of what’s involved.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <ImgContainer imgs={section2Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading4>
              Sending goods to customers around the world is an important and
              crucial aspect of your international business.
            </Heading4>
            <Body>
              When shipping a product overseas, you must be aware of how to
              package and label your parcel and the documentation you may need
              to provide as well as thinking about customs regulations. The UK
              Government offers a handy step-by-step guide here to review before
              you begin shipping.
            </Body>
            <ExternalLinkBtn btnLink={router.CHECK_HOW_TO_EXPORT_GOODS}>
              Export goods from the UK: step by step
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading4>Packaging your products</Heading4>
            <Body>
              Properly packaging your goods for international transport will
              ensure they arrive in good condition and without delays.
            </Body>
            <Body>
              Access our international packaging guide for handy tips here
            </Body>
            <DownloadBtn downloadLink={router.INTERNATIONAL_PACKAGING_GUIDE}>
              International Packaging Guide PDF (1.84mb)
            </DownloadBtn>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={section3Img} columnSpan={6} />
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>
              What is Electronic Customs Data and a Commercial Invoice?
            </Heading3>
            <Body>
              When goods are shipped abroad, they must undergo customs clearance
              - that’s export clearance out of the UK and import clearance to
              the destination country or territory.
            </Body>
            <Body>
              At DPD, we’ll take care of these formalities and procedures,
              you’ll simply need to provide the information required for us to
              do this. This Electronic Customs Data needs to be supplied when
              you create your shipment in our shipping platform.
            </Body>
            <Body>
              Electronic Customs data is the additional, mandatory information
              which tells customs authorities where the parcel is going, what is
              inside the parcel and who the exporter, importer and/or recipient
              is.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Consignment Detail for Electronic Customs data</Heading3>

            <Row>
              <Col md={12}>
                <Heading4>Exporter Details and EORI Number</Heading4>
                <Body>
                  This is a mandatory requirement for UK registered businesses
                  to trade goods with countries outside the UK recorded in the
                  export declaration and used for statistical and security
                  purposes. Your EORI number begins with GB followed by 12
                  numbers e.g. GB123456789000
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>Reason for Export</Heading4>
                <Body>
                  Tell us the reason why you are shipping the goods to Europe,
                  for example commercial sale.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>International Terms of Delivery</Heading4>
                <Body>
                  DPD&apos;s default service uses Delivered at Place (DAP)
                  IncoTerms™, where the importing customer is declared as the
                  importer of record from whom we will attempt to collect any
                  import VAT and duties that are due. Please refer to the FAQs
                  if you would like more information about other options
                  available, or speak to your Account Manager.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>
                  Importer Details and any Relevant Importer Tax ID
                </Heading4>
                <Body>
                  It&apos;s really important you provide full and accurate
                  details for the importer/recipient of your parcel to ensure
                  correct and compliant Customs Clearance. If you are shipping
                  to a business we’ll need the importing business’ EORI and tax
                  number. If you are shipping to a consumer in Europe and you
                  are IOSS registered you must supply this in the data too to
                  avoid taxes being charged again on import. Lastly in addition
                  to the address details, customs require a contact telephone
                  and email address.
                </Body>
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgColor={variables.darkShade1}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>Product Detail for Electronic Customs data</Heading3>

            <Row>
              <Col md={12}>
                <Heading4>Goods Description</Heading4>
                <Body>
                  Provide a detailed description of all items in the parcel.
                  Avoid ambiguous terms like gifts, merchandise or tools.
                  Instead of textiles for example, use men&apos;s cotton suits.
                  Failure to provide a detailed description may lead to customs
                  delays.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>Commodity Code</Heading4>
                <Body>
                  This internationally recognised numbering system is used by
                  global authorities to categorise products for the trade tariff
                  to assess how much is to be paid in duties and taxes.
                  Commodity codes are normally 8-10 digits long. We require the
                  8 digit code to export your parcel.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>Value</Heading4>
                <Body>
                  State the value and currency of each item in the consignment.
                  If the goods are a sale, you must provide the commercial
                  value. If the goods are not of commercial value, use the
                  intrinsic value. You must make sure the values you declare are
                  exclusive of VAT. VAT is applied where appropriate on
                  importation into the EU.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>Weight</Heading4>
                <Body>
                  Clearly indicate the weight of each item in the consignment.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>Country of Origin</Heading4>
                <Body>
                  Country of Origin (COO) represents the country or countries of
                  manufacture where an article or product comes from. Please
                  note this is not the country your are shipping from, but where
                  the goods were made.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>Shipping Costs</Heading4>
                <Body>
                  State the shipping costs for the consignment. This is
                  important information that is used in the accurate calculation
                  of duties and taxes.
                </Body>
              </Col>
            </Row>

            <Row>
              <Col md={12}>
                <Heading4>
                  Watch our short video about customs clearance or read our
                  guide here on Electronic Customs Data
                </Heading4>
                <Col md={12}>
                  <ReactPlayer url={video} width="100%" controls={true} />
                </Col>

                <div className="pt-4">
                  <DownloadBtn downloadLink={router.CUSTOM_DETAILS}>
                    Electronic Customs Data Guide: (1.24Mb)
                  </DownloadBtn>
                </div>
              </Col>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading3>The role of Customs Authorities</Heading3>
            <Body>
              Customs are involved in the implementation and enforcement of
              import and export legislation and regulations for goods travelling
              into and out of the country. In relation to international trade,
              the main responsibility of customs is to:
            </Body>

            <CardContainer
              cards={[
                {
                  cardText: (
                    <BulletList
                      listItems={[
                        {
                          text: "Check and control the flow of goods to ensure they meet the legal, environmental, health, quality and regulatory requirements",
                        },
                        {
                          text: "Generate and protect revenue through duty tariffs and relevant taxes",
                        },
                        { text: "Facilitate trade and security protection" },
                      ]}
                    />
                  ),
                },
              ]}
              cardsBgColor={variables.darkShade2}
            />

            <Body>
              When it comes to international delivery, the customs process is a
              critical element of the parcel’s journey. Providing all the
              necessary information in the required format when you ship your
              parcel is key to ensure smooth customs clearance and hassle-free
              experience for you and your recipient. From time to time, customs
              authorities may inspect parcels as part of their routine processes
              or if they undertaking regulatory control measures.
            </Body>

            <Body>
              Visit our compliance section for handy tips on how to meet customs
              requirements
            </Body>

            <CallToActionBtn
              classes="mr-3 p-3"
              btnLink={router.TIPS_FOR_EXPORT_SUCCESS}
            >
              learn more about Compliance
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </>
  );
};
