import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/purple-top-right-bg.png";
import dpdDigital from "../../../assets/icons/digital.png";
import dpdEmail from "../../../assets/icons/email.png";
import dpdMap from "../../../assets/icons/map.png";
import jumbotronImg from "../../../assets/pages/our-services/air-express/jumbotron-img.png";
import section1Bg from "../../../assets/pages/our-services/air-express/section-1-bg.png";
import section3Bg from "../../../assets/pages/our-services/air-express/section-3-bg.png";
import section3Img from "../../../assets/pages/our-services/air-express/section-3-img.png";
import { CardContainer } from "../../../components/CardContainer/CardContainer";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentCoral } from "../../../components/Typography/AccentCoral";
import { AccentHotPink } from "../../../components/Typography/AccentHotPink";
import { AccentOrange } from "../../../components/Typography/AccentOrange";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { AIR_EXPRESS } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";

export const AirExpress = () => {
  return (
    <Trackable
      interfaceId={AIR_EXPRESS.INTERFACE_ID}
      loadId={AIR_EXPRESS.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>Air Express</Heading5>
            <Heading1>
              For those more
              <AccentCoral> urgent parcel deliveries</AccentCoral>
            </Heading1>
            <Heading2>DPD Air Express is the service for you</Heading2>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={jumbotronImg} columnSpan={6} />
      </Section>

      <Section bgImg={section1Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              <AccentOrange>No matter where in the world</AccentOrange>, we’ll
              get your parcel there
            </Heading1>
            <Body>
              DPD’s door to door global express courier service offers you
              unrivalled access with the fastest connectivity to over 200
              countries.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              Whether you are sending to a business or looking for an{" "}
              <AccentHotPink>
                express service for your e-commerce orders
              </AccentHotPink>
            </Heading2>
            <Heading4>
              DPD Air Express will give you everything you need:
            </Heading4>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.ZOOM_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardImg: dpdMap,
                  cardText: (
                    <Body>
                      Speed & real-time <br />
                      tracking
                    </Body>
                  ),
                },
                {
                  cardImg: dpdEmail,
                  cardText: (
                    <Body>
                      Delivery <br /> notifications
                    </Body>
                  ),
                },
                {
                  cardImg: dpdDigital,
                  cardText: (
                    <Body>
                      Paperless customs <br /> information transmission
                    </Body>
                  ),
                },
              ]}
              columnSpan={4}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <ImgContainer
          imgs={section3Img}
          columnSpan={6}
          imgsAnimation={ANIMATIONS.FADE_IN_LEFT}
        />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>In flight and flexible customs clearance</Heading1>
            <Heading3>
              We’ve developed a{" "}
              <AccentOrange> user-friendly leading-edge solution </AccentOrange>{" "}
              for the payment of duties and taxes.
            </Heading3>
            <Body>
              To improve customer confidence and avoid unnecessary delays, a
              branded email is sent to the nominated recipient informing them of
              the charges, with secure options to pay. If you want to cover the
              duty and tax charges, then no problem, duty paid is also available
              with DPD Air Express. The choice is yours!
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
