import australiaPdf from "../assets/countries/Australia_InformationGuide.pdf";
import bahrainPdf from "../assets/countries/Bahrain_InformationGuide.pdf";
import brt from "../assets/countries/BRT.jpg";
import canadaPdf from "../assets/countries/Canada_InformationGuide.pdf";
import chinaPdf from "../assets/countries/China_InformationGuide.pdf";
import chronopost from "../assets/countries/Chronopost.jpg";
import dpdIreland from "../assets/countries/dpdIreland.png";
import dpdUk from "../assets/countries/dpdUk.png";
import aramex from "../assets/countries/aramex.png";
import dtdc from "../assets/countries/dtdc.avif";
import hongKongPdf from "../assets/countries/HongKong_InformationGuide.pdf";
import indiaPdf from "../assets/countries/India_InformationGuide.pdf";
import japanPdf from "../assets/countries/Japan_InformationGuide.pdf";
import kuwaitPdf from "../assets/countries/Kuwait_InformationGuide.pdf";
import newZealandPdf from "../assets/countries/NewZealand_InformationGuide.pdf";
import norwayPdf from "../assets/countries/Norway_InformationGuide.pdf";
import omanPdf from "../assets/countries/Oman_InformationGuide.pdf";
import qatarPdf from "../assets/countries/Qatar_InformationGuide.pdf";
import saudiArabiaPdf from "../assets/countries/SaudiArabia_InformationGuide.pdf";
import seur from "../assets/countries/SEUR.jpg";
import singaporePdf from "../assets/countries/Singapore_InformationGuide.pdf";
import switzerlandPdf from "../assets/countries/Switzerland_InformationGuide.pdf";
import uaePdf from "../assets/countries/UAE_InformationGuide.pdf";
import usaPdf from "../assets/countries/USA_InformationGuide.pdf";
import { ReactComponent as FlagAustralia } from "../assets/icons/flagAustralia.svg";
import { ReactComponent as FlagBahrain } from "../assets/icons/flagBahrain.svg";
import { ReactComponent as FlagCanada } from "../assets/icons/flagCanada.svg";
import { ReactComponent as FlagChina } from "../assets/icons/flagChina.svg";
import { ReactComponent as FlagHongKong } from "../assets/icons/flagHongKong.svg";
import { ReactComponent as FlagIndia } from "../assets/icons/flagIndia.svg";
import { ReactComponent as FlagJapan } from "../assets/icons/flagJapan.svg";
import { ReactComponent as FlagKuwait } from "../assets/icons/flagKuwait.svg";
import { ReactComponent as FlagNewZealand } from "../assets/icons/flagNewZealand.svg";
import { ReactComponent as FlagNorway } from "../assets/icons/flagNorway.svg";
import { ReactComponent as FlagOman } from "../assets/icons/flagOman.svg";
import { ReactComponent as FlagQatar } from "../assets/icons/flagQatar.svg";
import { ReactComponent as FlagSaudiArabia } from "../assets/icons/flagSaudiArabia.svg";
import { ReactComponent as FlagSingapore } from "../assets/icons/flagSingapore.svg";
import { ReactComponent as FlagSwitzerland } from "../assets/icons/flagSwitzerland.svg";
import { ReactComponent as FlagUnitedArabEmirates } from "../assets/icons/flagUnitedArabEmirates.svg";
import { ReactComponent as FlagUnitedStates } from "../assets/icons/flagUnitedStates.svg";

// SUPPORTED LIST OF COUNTRIES FOR SEARCH
// const SUPPORTED_COUNTRIES = [
//   "Australia",
//   "Austria",
//   "Bahrain",
//   "Belgium",
//   "Brazil",
//   "Bulgaria",
//   "Canada",
//   "China",
//   "Croatia",
//   "Cyprus",
//   "Czech Republic",
//   "Denmark",
//   "Egypt",
//   "Estonia",
//   "Finland",
//   "France",
//   "Germany",
//   "Greece",
//   "Hong Kong",
//   "Hungary",
//   "India",
//   "Israel",
//   "Italy",
//   "Japan",
//   "Jordan",
//   "Kazakhstan",
//   "Kuwait",
//   "Latvia",
//   "Lithuania",
//   "Luxembourg",
//   "Netherlands",
//   "New Zealand",
//   "Norway",
//   "Oman",
//   "Poland",
//   "Portugal",
//   "Qatar",
//   "Republic of Ireland",
//   "Romania",
//   "Saudi Arabia",
//   "Singapore",
//   "Slovakia",
//   "Slovenia",
//   "South Africa",
//   "Spain",
//   "Sweden",
//   "Switzerland",
//   "USA",
//   "United Arab Emirates",
// ];

export const COUNTRY_FLAGS = {
  australia: <FlagAustralia />,
  bahrain: <FlagBahrain />,
  canada: <FlagCanada />,
  china: <FlagChina />,
  hongKong: <FlagHongKong />,
  india: <FlagIndia />,
  japan: <FlagJapan />,
  kuwait: <FlagKuwait />,
  newZealand: <FlagNewZealand />,
  norway: <FlagNorway />,
  oman: <FlagOman />,
  qatar: <FlagQatar />,
  saudiArabia: <FlagSaudiArabia />,
  singapore: <FlagSingapore />,
  switzerland: <FlagSwitzerland />,
  unitedArabEmirates: <FlagUnitedArabEmirates />,
  usa: <FlagUnitedStates />,
};

export const EU_COUNTRIES = [
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Israel",
  "Italy",
  "Kazakhstan",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Netherlands",
  "Norway",
  "Poland",
  "Portugal",
  "Republic of Ireland",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Switzerland",
];

export const REST_OF_WORLD_COUNTRIES = [
  "Australia",
  "Bahrain",
  "Brazil",
  "Canada",
  "China",
  "Egypt",
  "Hong Kong",
  "India",
  "Japan",
  "Jordan",
  "Kuwait",
  "New Zealand",
  "Oman",
  "Qatar",
  "Saudi Arabia",
  "Singapore",
  "South Africa",
  "USA",
  "United Arab Emirates",
];

export const COUNTRY_SPECIFIC_PROHIBITED_GOODS_PDFS = {
  australia: australiaPdf,
  bahrain: bahrainPdf,
  canada: canadaPdf,
  china: chinaPdf,
  hongKong: hongKongPdf,
  india: indiaPdf,
  japan: japanPdf,
  kuwait: kuwaitPdf,
  newZealand: newZealandPdf,
  norway: norwayPdf,
  oman: omanPdf,
  qatar: qatarPdf,
  saudiArabia: saudiArabiaPdf,
  singapore: singaporePdf,
  switzerland: switzerlandPdf,
  unitedArabEmirates: uaePdf,
  usa: usaPdf,
};

export const STRATEGIC_LOCATION_IMGS = {
  dpdIreland,
  seur,
  brt,
  dpdUk,
  chronopost,
  aramex,
  dtdc
};
