import classNames from "classnames";
import PropTypes from "prop-types";

export const Underline = ({ children, classes }) => {
  return <span className={classNames(classes, "Underline")}>{children}</span>;
};

Underline.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
