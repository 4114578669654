import { useMemo } from "react";

import classNames from "classnames";
import { Button, Container, Row } from "react-bootstrap";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as Close } from "../../assets/icons/close.svg";
import { WORLD_MAP } from "../../constants/actions";
import { STRATEGIC_LOCATION_IMGS } from "../../data/country";
import {
  camelize,
  getCountryImgUrl,
  getCountrySideDrawerContentComponent,
} from "../../helpers/helpers";
import { useStore } from "../../store/store";
import styles from "./CountrySideDrawer.module.scss";

export const CountrySideDrawer = () => {
  const {
    country,
    isCountrySideDrawerOpen,
    resetCountrySideDrawer,
    countrySideDrawerContentType,
    selectedStrategicLocation,
  } = useStore();
  const tracker = useTracker();

  let bgUrl = country && getCountryImgUrl(country.imagePath);

  if (selectedStrategicLocation?.depotName) {
    const depotName = camelize(selectedStrategicLocation?.depotName);

    bgUrl = STRATEGIC_LOCATION_IMGS[depotName];
  }

  // TODO: Remove this once all countries have images
  const inputColor = country
    ? country.imagePath
      ? ""
      : "rgba(57, 67, 79, 0.5)"
    : "";

  const CountrySideDrawerContent = useMemo(() => {
    return getCountrySideDrawerContentComponent(countrySideDrawerContentType);
  }, [countrySideDrawerContentType]);

  return (
    <>
      {isCountrySideDrawerOpen && (
        <div
          className={classNames(
            styles["country-sidebar-drawer"],
            "position-absolute h-100 animate__animated animate__slideInLeft"
          )}
        >
          <Container
            className={styles["country-sidebar-drawer-bg"]}
            style={{
              backgroundColor: inputColor,
              backgroundImage: `url(${bgUrl})`,
            }}
          >
            <Row className="justify-content-end p-2">
              <Button
                variant="light"
                className="btn-circle"
                onClick={() => {
                  resetCountrySideDrawer();
                  tracker.logEvent(WORLD_MAP.ON_CLICK_CLOSE);
                }}
                style={{
                  height: "32px",
                  width: "32px",
                }}
              >
                <Close />
              </Button>
            </Row>
          </Container>
          <Container
            className={classNames(
              "px-2",
              "dark-scrollbar",
              styles["country-sidebar-drawer-content"]
            )}
          >
            <CountrySideDrawerContent />
          </Container>
        </div>
      )}
    </>
  );
};
