import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/jumbotron-bg.png";
import revenue from "../../../assets/icons/revenue.png";
import dayImg from "../../../assets/icons/day.png";
import dpdAvatarImg from "../../../assets/icons/dpd-avatar.png";
import dpdPickupImg from "../../../assets/icons/dpd-pickup.png";
import brand1Img from "../../../assets/pages/about-us/brand-1-img.png";
import brand2Img from "../../../assets/pages/about-us/brand-2-img.png";
import brand3Img from "../../../assets/pages/about-us/brand-3-img.png";
import brand4Img from "../../../assets/pages/about-us/brand-4-img.png";
import brand5Img from "../../../assets/pages/about-us/brand-5-img.png";
import section1Bg from "../../../assets/pages/about-us/section-1-bg.png";
import section2Bg from "../../../assets/pages/about-us/section-2-bg.png";
import section3Bg from "../../../assets/pages/about-us/section-3-bg.png";
import section3Img from "../../../assets/pages/about-us/section-3-img.png";
import section4Bg from "../../../assets/pages/about-us/section-4-bg.png";
import jumbotronImg from "../../../assets/pages/about-us/who-we-are-jumbotron-bg.png";
import { CardContainer } from "../../../components/CardContainer/CardContainer";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentMint } from "../../../components/Typography/AccentMint";
import { Annotation } from "../../../components/Typography/Annotation";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { YoutubeEmbed } from "../../../components/YoutubeEmbed/YoutubeEmbed";
import { WHO_WE_ARE } from "../../../constants/actions";
import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";
import {
  EMPLOYEES,
  GLOBAL_PICKUP_POINTS,
  PARCELS_DELIVERED,
  REVENUE,
} from "../../../data/stats";
import { useAnimation } from "../../../hooks/useAnimation";

export const WhoWeAre = () => {
  const [ref, animate] = useAnimation();

  return (
    <Trackable
      interfaceId={WHO_WE_ARE.INTERFACE_ID}
      loadId={WHO_WE_ARE.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <Col className="text-center">
          <ImgContainer imgs={jumbotronImg} columnSpan={12} />
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={1000}
            animateOnce={true}
          >
            <Heading5>Who We Are.</Heading5>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={1500}
            animateOnce={true}
          >
            <Heading1>We are Geopost</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={1500}
            animateOnce={true}
          >
            <Heading3>
              We take a more personal approach to parcel delivery
            </Heading3>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={6} ref={ref}>
          {animate && (
            <ScrollAnimation
              animateIn={ANIMATIONS.FADE_IN_LEFT}
              delay={DEFAULT_ANIMATION_DELAY}
              animateOnce={true}
            >
              <Heading2>
                Geopost is the <AccentMint>largest</AccentMint> parcel delivery
                network in Europe.
              </Heading2>
              <Heading4>
                We combine innovative technology and local knowledge to provide
                a flexible and user-friendly service for both shippers and
                shoppers.
              </Heading4>
              <Body>
                With our industry-leading Predict service, we are setting a new
                standard for convenience by keeping shoppers in close contact
                with their deliveries.
              </Body>
            </ScrollAnimation>
          )}
        </Col>
      </Section>

      <Section bgImg={section2Bg}>
        <Col className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.ZOOM_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <CardContainer
                cards={[
                  {
                    cardImg: dpdAvatarImg,
                    cardTitle: <Heading4>{EMPLOYEES}</Heading4>,
                    cardText: <Body>Employees</Body>,
                  },
                  {
                    cardImg: dpdPickupImg,
                    cardTitle: <Heading4>{GLOBAL_PICKUP_POINTS}</Heading4>,
                    cardText: <Body>Pickup Points Worldwide</Body>,
                  },
                  {
                    cardImg: dayImg,
                    cardTitle: <Heading4>{PARCELS_DELIVERED}</Heading4>,
                    cardText: <Body>Parcels Delivered</Body>,
                  },
                  {
                    cardImg: revenue,
                    cardTitle: <Heading4>{REVENUE}</Heading4>,
                    cardText: (
                      <div>
                        <Body>Revenue</Body>
                        <Annotation>*Asendia revenue excluded</Annotation>
                      </div>
                    ),
                  },
                ]}
                columnSpan={3}
              />
            </Row>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <Col>
                <Heading3>Through the brands:</Heading3>
              </Col>
            </Row>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <ImgContainer imgs={[brand1Img, brand2Img]} columnSpan={6} />
              <ImgContainer
                imgs={[brand3Img, brand4Img, brand5Img]}
                columnSpan={4}
              />
            </Row>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Row>
              <Heading5>
                Together we are Geopost. We foster better business to deliver
                progress for commerce - for today and tomorrow.
              </Heading5>
            </Row>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <ImgContainer imgs={section3Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>A message from our CEO, Elaine Kerr</Heading2>
            <Heading4>
              At DPD we have the people and expertise to give your international
              customers the best possible delivery experience.
            </Heading4>
            <Body>
              Whether you’re sending parcels to Brussels or Beijing, our
              international delivery experts will take care of everything for
              you. You really can rely on us!
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section4Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={12}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Our global solutions</Heading1>
            <Heading3>
              Geopost’s international parcel delivery network is present in over
              50 countries and growing, complemented by strategic partnerships
              and joint ventures that ensure global coverage for a complete
              worldwide delivery solution.
            </Heading3>
            <Body>
              Our worldwide range of delivery services across more than 200
              countries covers everything you need to meet your requirements for
              speed, delivery experience, customs clearance or cost.
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section style={{ height: SECTION_HEIGHT }}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>Your delivery experts</Heading2>
            <YoutubeEmbed embedId="RQ5xqAEtl6o" />
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
