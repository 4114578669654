import { useEffect } from "react";

import { Navigate, Route, Routes, useLocation } from "react-router-dom";

import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { Sustainability } from "../pages/AboutUs/Sustainability/Sustainability";
import { WhoWeAre } from "../pages/AboutUs/WhoWeAre/WhoWeAre";
import { Home } from "../pages/WorldMap/Home";
// import { Chat } from "../pages/Chat/Chat";
import { EuVatChanges } from "../pages/InternationalUncovered/ExportFundamentals/EuVatChanges/EuVatChanges";
import { ExportFundamentals } from "../pages/InternationalUncovered/ExportFundamentals/ExportFundamentals";
import { HowToExport } from "../pages/InternationalUncovered/ExportFundamentals/HowToExport/HowToExport";
import { ShippingToEu } from "../pages/InternationalUncovered/ExportFundamentals/ShippingToEu/ShippingToEu";
import { ExportControls } from "../pages/InternationalUncovered/ExportFundamentals/ExportControls/ExportControls";
import { TipsForExportSuccess } from "../pages/InternationalUncovered/ExportFundamentals/TipsForExportSuccess/TipsForExportSuccess";
import { UkTradeAgreement } from "../pages/InternationalUncovered/ExportFundamentals/UkTradeAgreement/UkTradeAgreement";
import { YourGuideToDutiesTaxes } from "../pages/InternationalUncovered/ExportFundamentals/YourGuideToDutiesTaxes/YourGuideToDutiesTaxes";
import { InternationalInsights } from "../pages/InternationalUncovered/InternationalInsights/InternationalInsights";
import { InternationalUncovered } from "../pages/InternationalUncovered/InternationalUncovered";
import { BlogArticle } from "../pages/InternationalUncovered/Blog/BlogArticle/BlogArticle";
import { Blog } from "../pages/InternationalUncovered/Blog/Blog";
import { OurPartners } from "../pages/InternationalUncovered/OurPartners/OurPartners";
import { OurGroupPartnerships } from "../pages/InternationalUncovered/OurPartners/OurGroupPartnerships/OurGroupPartnerships";
import { OurTradePartnerships } from "../pages/InternationalUncovered/OurPartners/OurTradePartnerships/OurTradePartnerships";
import { AirClassic } from "../pages/OurServices/AirClassic/AirClassic";
import { AirExpress } from "../pages/OurServices/AirExpress/AirExpress";
import { DpdClassic } from "../pages/OurServices/DpdClassic/DpdClassic";
import { DpdDirect } from "../pages/OurServices/DpdDirect/DpdDirect";
import { Innovations } from "../pages/OurServices/Innovations/Innovations";
import { OurServices } from "../pages/OurServices/OurServices";
import { WorldMap } from "../pages/OurServices/WorldMap/WorldMap";
import { Search } from "../pages/Search/Search";
import Faqs from "../pages/Support/Faqs/Faqs";
import {
  clickOnElement,
  getOnclickParam,
  getParams,
  scrollIntoView,
} from "../utils/scroll";
import * as routes from "./constants";

export const Router = () => {
  const tracker = useTracker();
  const location = useLocation();

  useEffect(() => {
    tracker.setContext({
      url: window.location.href,
      userAgent: navigator.userAgent,
    });

    tracker.logEvent();
  }, [location]);

  useEffect(() => {
    const params = getParams();
    const onclickParam = getOnclickParam();
    if (params) {
      if (onclickParam) {
        setTimeout(() => {
          clickOnElement(onclickParam);
        }, 700);
        setTimeout(() => {
          clickOnElement(params);
        }, 700);

        return;
      }

      setTimeout(() => {
        scrollIntoView(params);
      }, 700);
    }
  }, [location]);

  // <Route path={routes.WORLD} element={<Home />} />
  return (
    <Routes>
      <Route path={routes.HOME} element={<Home />} />

      <Route
        path={routes.ABOUT_US}
        element={<Navigate to={routes.WHO_WE_ARE} replace />}
      />
      <Route path={routes.WHO_WE_ARE} element={<WhoWeAre />} />
      <Route path={routes.SUSTAINABILITY} element={<Sustainability />} />

      <Route path={routes.OUR_SERVICES} element={<OurServices />} />
      <Route path={routes.DPD_DIRECT} element={<DpdDirect />} />
      <Route path={routes.DPD_CLASSIC} element={<DpdClassic />} />
      <Route path={routes.AIR_CLASSIC} element={<AirClassic />} />
      <Route path={routes.AIR_EXPRESS} element={<AirExpress />} />
      <Route path={routes.INNOVATIONS} element={<Innovations />} />

      <Route
        path={routes.INTERNATIONAL_UNCOVERED}
        element={<InternationalUncovered />}
      />
      <Route path={routes.OUR_PARTNERS} element={<OurPartners />} />
      <Route
        path={routes.OUR_GROUP_PARTNERSHIPS}
        element={<OurGroupPartnerships />}
      />
      <Route
        path={routes.OUR_TRADE_PARTNERSHIPS}
        element={<OurTradePartnerships />}
      />
      <Route
        path={routes.INTERNATIONAL_INSIGHTS}
        element={<InternationalInsights />}
      />
      <Route path={routes.BLOG} element={<Blog />} />
      <Route path={routes.BLOG_ARTICLE} element={<BlogArticle />} />
      <Route
        path={routes.EXPORT_FUNDAMENTALS}
        element={<ExportFundamentals />}
      />
      <Route path={routes.HOW_TO_EXPORT} element={<HowToExport />} />
      <Route
        path={routes.YOUR_GUIDE_DUTIES}
        element={<YourGuideToDutiesTaxes />}
      />
      <Route path={routes.SHIPPING_TO_EU} element={<ShippingToEu />} />
      <Route path={routes.EXPORT_CONTROLS} element={<ExportControls />} />
      <Route path={routes.EU_VAT_CHANGES} element={<EuVatChanges />} />
      <Route
        path={routes.TIPS_FOR_EXPORT_SUCCESS}
        element={<TipsForExportSuccess />}
      />
      <Route path={routes.UK_TRADE_AGREEMENT} element={<UkTradeAgreement />} />

      <Route
        path={routes.SUPPORT}
        element={<Navigate to={routes.FAQS} replace />}
      />
      <Route path={routes.FAQS} element={<Faqs />} />

      <Route path={routes.WORLD_MAP} element={<WorldMap />} />
      <Route path={routes.SEARCH} element={<Search />} />
      <Route path="*" element={<Navigate to={routes.HOME} replace />} />
    </Routes>
  );
};
