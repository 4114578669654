import { useState } from "react";
import PropTypes from "prop-types";
import DatePicker from "react-datepicker";
import { Col, Container, Form } from "react-bootstrap";
import { format } from "date-fns";
import { Dropdown } from "../Dropdown/Dropdown";
import { useTracker } from "@dpdgroupuk/react-event-tracker";
import { INSIGHT_INFO_BLOG } from "../../constants/actions";
import { FILTER_DEFAULT_VALUES } from "../../constants/constants";
import {
  DPD_NEWS_FEED_CATEGORY_LIST,
  DPD_NEWS_FEED_COUNTRY_LIST,
} from "../../data/blog";
import { useWindowSize } from "../../hooks/useWindowSize";

import styles from "./BlogFilter.module.scss";

export const BlogFilter = ({ filters, onFilterUpdate, style }) => {
  const [startDate, setStartDate] = useState(null);

  const handleFilterChange = (value, filter) => {
    let filterValue = value;
    if (Object.values(FILTER_DEFAULT_VALUES).includes(value)) {
      filterValue = null;
    }
    onFilterUpdate({ ...filters, [filter]: filterValue });
  };

  const tracker = useTracker();
  const windowSize = useWindowSize().width;

  return (
    <Container style={{ ...style }}>
      <Col md={4}>
        <Form.Group
          onClick={() =>
            tracker.logEvent(
              INSIGHT_INFO_BLOG.ON_CLICK_GROUP_FEED_FILTER_CATEGORY
            )
          }
        >
          <Dropdown
            value={filters.category}
            title={FILTER_DEFAULT_VALUES.CATEGORY}
            options={Object.keys(DPD_NEWS_FEED_CATEGORY_LIST)}
            onChange={value => handleFilterChange(value, "category")}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group
          onClick={() =>
            tracker.logEvent(
              INSIGHT_INFO_BLOG.ON_CLICK_GROUP_FEED_FILTER_COUNTRY_LIST
            )
          }
        >
          <Dropdown
            value={filters.country}
            title={FILTER_DEFAULT_VALUES.COUNTRY}
            options={DPD_NEWS_FEED_COUNTRY_LIST}
            onChange={value => handleFilterChange(value, "country")}
          />
        </Form.Group>
      </Col>
      <Col md={4}>
        <Form.Group
          className="dark-datepicker-container"
          onClick={() =>
            tracker.logEvent(
              INSIGHT_INFO_BLOG.ON_CLICK_GROUP_FEED_FILTER_MONTH_YEAR
            )
          }
        >
          <DatePicker
            selected={startDate}
            onChange={date => {
              setStartDate(date);

              if (date) {
                const monthYear = format(date, "MMMM yyyy");
                handleFilterChange(monthYear.toString(), "date");
              } else {
                handleFilterChange(date, "date");
              }
            }}
            placeholderText={filters.date || FILTER_DEFAULT_VALUES.DATE}
            dateFormat="MMMM yyyy"
            showTwoColumnMonthYearPicker={
              windowSize <= 1009 && windowSize >= 767
            }
            showMonthYearPicker
            customInput={<input className={styles["dark-datepicker"]} />}
          />
        </Form.Group>
      </Col>
    </Container>
  );
};

BlogFilter.propTypes = {
  filters: PropTypes.object,
  onFilterUpdate: PropTypes.func,
  style: PropTypes.object,
};
