import ScrollAnimation from "react-animate-on-scroll";
import { Col } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../assets/backgrounds/jumbotron-bg.png";
import jumbotronImg from "../../assets/pages/international/jumbotron-img.png";
import section1Bg from "../../assets/pages/international/section-1-bg.png";
import section2Bg from "../../assets/pages/international/section-2-bg.png";
import section2Img from "../../assets/pages/international/section-2-img.png";
import section3Bg from "../../assets/pages/international/section-3-bg.png";
import section3Img from "../../assets/pages/international/section-3-img.png";
import section4Img1 from "../../assets/pages/international/section-4-img-1.png";
import section4Img2 from "../../assets/pages/international/section-4-img-2.png";
import section4Img3 from "../../assets/pages/international/section-4-img-3.png";
import { CallToActionBtn } from "../../components/CallToActionBtn/CallToActionBtn";
import { GetInTouch } from "../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../components/ImgContainer/ImgContainer";
import { Section } from "../../components/Section/Section";
import { AccentOrange } from "../../components/Typography/AccentOrange";
import { Heading1 } from "../../components/Typography/Heading1";
import { Heading2 } from "../../components/Typography/Heading2";
import { Heading4 } from "../../components/Typography/Heading4";
import { Heading5 } from "../../components/Typography/Heading5";
import { INSIGHT_INFO } from "../../constants/actions";
import { ANIMATIONS, DEFAULT_ANIMATION_DELAY } from "../../constants/constants";
import * as routes from "../../router/constants";

export const InternationalUncovered = () => {
  return (
    <Trackable
      interfaceId={INSIGHT_INFO.INTERFACE_ID}
      loadId={INSIGHT_INFO.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <ImgContainer imgs={jumbotronImg} columnSpan={12} />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>International Uncovered</Heading5>
            <Heading1>Find your next global opportunity</Heading1>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              <AccentOrange>
                New to exporting or a seasoned exporter{" "}
              </AccentOrange>
              and keen to know more?
            </Heading1>
            <Heading2>
              Our country insight research will provide inspiration.
            </Heading2>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={routes.INTERNATIONAL_INSIGHTS}>
              Find out more
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section2Bg}>
        <ImgContainer imgs={section2Img} columnSpan={6} />
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Export Fundamentals</Heading1>
            <Heading2>All the important information in one place</Heading2>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={routes.EXPORT_FUNDAMENTALS}>
              Access Our Learning Library
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Get the latest on everything DPD</Heading1>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn btnLink={routes.BLOG}>
              Find Out More
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={section3Img} columnSpan={6} />
      </Section>

      <Section>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>Our Partners</Heading1>
            <Heading4>
              DPD is proud to partner with The Department for Business & Trade
              and the Chartered Institute of Export & International Trade to
              <AccentOrange> help our customers sell </AccentOrange>
              overseas and grow their international markets.
            </Heading4>
          </ScrollAnimation>
        </Col>
        <ImgContainer
          imgs={[section4Img1, section4Img2, section4Img3]}
          columnSpan={4}
        />
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <CallToActionBtn
              classes="text-center"
              btnLink={routes.OUR_PARTNERS}
            >
              Find Out More
            </CallToActionBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
