import ScrollAnimation from "react-animate-on-scroll";
import { Col, Container, Row } from "react-bootstrap";

import { ReactComponent as CustomsIcon } from "../../assets/icons/iconCustomsOffical.svg";
import { ReactComponent as GlobeIcon } from "../../assets/icons/iconGlobe.svg";
import { ANIMATIONS } from "../../constants/constants";
import { CountryIcons } from "../CountryIcons/CountryIcons";
import { Body } from "../Typography/Body";
import { Heading3 } from "../Typography/Heading3";
import { Heading4 } from "../Typography/Heading4";

export const AirExpressDeliveryOption = () => {
  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_UP}
      delay={500}
      animateOnce={true}
    >
      <div className={"country-sidedrawer-data"}>
        <Container>
          <Col md={12}>
            <Heading3>Air Express</Heading3>
          </Col>

          <Col md={12}>
            <Heading4 classes={"country-sidedrawer-data-title"}>
              For those more urgent parcel deliveries, DPD Air Express is the
              service for you
            </Heading4>
          </Col>

          <Col md={12}>
            <CountryIcons
              icons={[
                {
                  icon: <GlobeIcon />,
                  title: "",
                  body: "No matter where in the world, we’ll get your parcel there",
                },
              ]}
            />
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              DPD’s door to door global express courier service offers you
              unrivalled access with the fastest connectivity to over 200
              countries.
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              Whether you are sending to a business or looking for an express
              service for your e-commerce orders
            </Body>
          </Col>

          <Col md={12}>
            <Body classes={"country-sidedrawer-data-text"}>
              DPD Air Express will give you everything you need - with speed,
              real-time tracking, delivery notifications and paperless customs
              information transmission. In flight and flexible customs clearance
            </Body>
          </Col>

          <Col md={12}>
            <Row>
              <CountryIcons
                icons={[
                  {
                    icon: <CustomsIcon />,
                    title: "",
                    body: "We’ve developed a user-friendly leading-edge solution for the payment of duties and taxes for the best experience for your recipient; but If you want to cover the duty and tax charges, then no problem, duty paid is also available - the choice is yours!",
                  },
                ]}
              />
            </Row>
          </Col>
        </Container>
      </div>
    </ScrollAnimation>
  );
};
