export const getParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("scrollTo");
};

export const getOnclickParam = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("onclick");
};

export const scrollIntoView = targetString => {
  const elements = [...document.querySelectorAll("*")];

  const matchedElements = elements.filter(element => {
    if (element.textContent === targetString) {
      return true;
    }
    return false;
  });

  if (matchedElements.length > 0) {
    const element = matchedElements[0];

    if (window.innerWidth < 600) {
      return scroll(element, -50);
    }

    scroll(element, -150);
  }
};

const scroll = (element, yOffset = 0) => {
  const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
  window.scrollTo({ top: y, behavior: "smooth" });
};

export const clickOnElement = target => {
  const elements = [...document.querySelectorAll("*")];

  const matchedElements = elements.filter(element => {
    if (element.textContent === target) {
      return true;
    }
    return false;
  });

  matchedElements[matchedElements.length - 1].click();
};
