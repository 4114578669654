import ScrollAnimation from "react-animate-on-scroll";
import { Col, Row } from "react-bootstrap";

import { Trackable } from "@dpdgroupuk/react-event-tracker";

import jumbotronBg from "../../../assets/backgrounds/jumbotron-purple-right-bg.png";
import section3Bg from "../../../assets/backgrounds/purple-top-center-bg.png";
import { ReactComponent as KeyboardIcon } from "../../../assets/icons/iconKeyboard.svg";
import { ReactComponent as OutOfHomeIcon } from "../../../assets/icons/iconOutOfHome.svg";
import { ReactComponent as ShopIcon } from "../../../assets/icons/iconShop.svg";
import cardImg1 from "../../../assets/pages/international/international-insights/card-img-1.png";
import jumbotronImg from "../../../assets/pages/international/international-insights/jumbotron-img.png";
import section1Bg from "../../../assets/pages/international/international-insights/section-1-bg.png";
import section2Bg from "../../../assets/pages/international/international-insights/section-2-bg.png";
import section3Img from "../../../assets/pages/international/international-insights/section-3-img.png";
import section4Bg from "../../../assets/pages/international/international-insights/section-4-bg.png";
import section5Bg from "../../../assets/pages/international/international-insights/section-5-bg.png";
import { CardContainer } from "../../../components/CardContainer/CardContainer";
import { DownloadBtn } from "../../../components/DownloadBtn/DownloadBtn";
import { ExternalLinkBtn } from "../../../components/ExternalLinkBtn/ExternalLinkBtn";
import { GetInTouch } from "../../../components/GetInTouch/GetInTouch";
import { ImgContainer } from "../../../components/ImgContainer/ImgContainer";
import { Section } from "../../../components/Section/Section";
import { AccentCoral } from "../../../components/Typography/AccentCoral";
import { AccentOrange } from "../../../components/Typography/AccentOrange";
import { Body } from "../../../components/Typography/Body";
import { Heading1 } from "../../../components/Typography/Heading1";
import { Heading2 } from "../../../components/Typography/Heading2";
import { Heading3 } from "../../../components/Typography/Heading3";
import { Heading4 } from "../../../components/Typography/Heading4";
import { Heading5 } from "../../../components/Typography/Heading5";
import { Bold } from "../../../components/Typography/BlogArticle/Bold";
import { BulletPoints } from "../../../components/Typography/BlogArticle/BulletPoints";
import { YoutubeEmbed } from "../../../components/YoutubeEmbed/YoutubeEmbed";
import { INTERNATIONAL_INSIGHTS } from "../../../constants/actions";
import {
  ALIGNMENT,
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  SECTION_HEIGHT,
} from "../../../constants/constants";
import * as routes from "../../../router/constants";

export const InternationalInsights = () => {
  return (
    <Trackable
      interfaceId={INTERNATIONAL_INSIGHTS.INTERFACE_ID}
      loadId={INTERNATIONAL_INSIGHTS.ON_LOAD}
    >
      <Section bgImg={jumbotronBg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading5>International Insights</Heading5>
            <Heading1>
              Read more about our insights and expertise across Europe and the
              world
            </Heading1>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={jumbotronImg} columnSpan={6} />
      </Section>

      <Section bgImg={section1Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>
              <AccentOrange>
                Presenting the new 2023 E-shopper Barometer
              </AccentOrange>
            </Heading2>
            <Heading4>
              Each year Geopost conducts an independent survey, equipping
              businesses with essential information to help them expand in
              European markets. This year’s barometer displays trends captured
              from over <Bold>24,000</Bold> interviews conducted in{" "}
              <Bold>22 European countries.</Bold>
            </Heading4>
            <Body>
              The E-shopper barometer provides significant insights into the
              e-commerce industry, such as:
              <BulletPoints>
                <li>
                  <Body>
                    How online shopping habits differ from one country to
                    another
                  </Body>
                </li>
                <li>
                  <Body>
                    What are the purchase drivers and blockers for modern EU
                    shoppers
                  </Body>
                </li>
                <li>
                  <Body>
                    Which industries show consistent growth throughout 2023
                  </Body>
                </li>
              </BulletPoints>
            </Body>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section>
        <div className="GetInTouch_card__mVGC4 card">
          <div className="card-body">
            <Col>
              <Row>
                <ScrollAnimation
                  animateIn={ANIMATIONS.FADE_IN}
                  delay={DEFAULT_ANIMATION_DELAY}
                  animateOnce={true}
                >
                  <Heading1>Key Takeaways from the 2023 Study:</Heading1>
                  <Row>
                    <Col className="text-center">
                      <ShopIcon />
                      <Heading1>
                        <AccentCoral>76%</AccentCoral>
                      </Heading1>
                      <Body>
                        of all Europeans are
                        <br></br>e-shoppers
                      </Body>
                    </Col>
                    <Col className="text-center">
                      <KeyboardIcon />
                      <Heading1>
                        <AccentCoral>72%</AccentCoral>
                      </Heading1>
                      <Body>
                        regular e-shoppers <br></br> are using C2C <br></br>{" "}
                        platforms
                      </Body>
                    </Col>
                    <Col className="text-center">
                      <OutOfHomeIcon />
                      <Heading1>
                        <AccentCoral>44%</AccentCoral>
                      </Heading1>
                      <Body>
                        of regular e-shoppers choose out-of-home delivery
                        solutions
                      </Body>
                    </Col>
                    <div></div>
                    <div></div>
                  </Row>
                  <Heading2>
                    Global uncertainty fuels online shopping boom
                  </Heading2>
                  <BulletPoints>
                    <li>
                      <Body>
                        Cost-conscious Europeans flock to C2C platforms for
                        affordability, convenience, and sustainability.
                      </Body>
                    </li>
                    <li>
                      <Body>
                        Out-of-home delivery soars as busy shoppers seek
                        flexible options beyond traditional home deliveries.
                      </Body>
                    </li>
                    <li>
                      <Body>
                        Continuous innovation ensures the overall e-shopping
                        experience evolves alongside changing consumer needs.
                      </Body>
                    </li>
                  </BulletPoints>
                </ScrollAnimation>
              </Row>
            </Col>
          </div>
        </div>
      </Section>

      <Section>
        <Col>
          <Row>
            <ScrollAnimation
              animateIn={ANIMATIONS.FADE_IN}
              delay={DEFAULT_ANIMATION_DELAY}
              animateOnce={true}
            >
              <Heading2>Is social media the new shopping paradise?</Heading2>
              <Body>
                Our 2023 e-shopper barometer reveals the most recent behaviours
                of online buyers in Europe and beyond.
                <br></br>
                <br></br>
                Discover the latest and most meaningful trends and delve into
                valuable analysis and insights of what the future of e-commerce
                may hold.
              </Body>
            </ScrollAnimation>
          </Row>
          <Row>
            <Col md={12}>
              <ScrollAnimation
                animateIn={ANIMATIONS.FADE_IN_UP}
                delay={DEFAULT_ANIMATION_DELAY}
                animateOnce={true}
              >
                <YoutubeEmbed embedId="Igt5yFgmLtY" />
              </ScrollAnimation>
            </Col>
          </Row>
        </Col>
      </Section>

      <Section bgImg={section2Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              <AccentCoral>Is frugality the new hype?</AccentCoral>
            </Heading1>
            <Heading4>
              Driven by cost-consciousness, C2C platforms are booming in Europe,
              with almost 3 in 4 regular e-shoppers now buying second hand.
              Financial reasons dominate the shift, with 44% making monthly
              purchases on these platforms. But the trend goes beyond wallets:
              it aligns with environmental concerns and empowers sellers to
              declutter and earn. This dynamic 2023 saw a 72% surge in regular
              e-shoppers joining the C2C movement, solidifying it as a major
              force in European e-commerce.
            </Heading4>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <DownloadBtn variant="danger" downloadLink={routes.DPD_BROCHURE}>
              Discover our brochure
            </DownloadBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_LEFT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              Discover more e-shopping habits in our online tool
            </Heading1>
            <Heading3>
              Start comparing e-shoppers’ behaviour: across Europe and the
              world.
            </Heading3>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <ExternalLinkBtn variant="danger" btnLink={routes.ESHOPPER_TOOL}>
              E-shopper Tool
            </ExternalLinkBtn>
          </ScrollAnimation>
        </Col>
        <ImgContainer imgs={section3Img} columnSpan={6} />
      </Section>

      <Section bgImg={section4Bg} alignment={ALIGNMENT.RIGHT}>
        <Col md={6}>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_RIGHT}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading1>
              We’re at the heart of a world that is changing before our eyes.
            </Heading1>
            <Heading2>
              Start comparing e-shoppers’ behaviour across Europe and the world.
            </Heading2>
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section3Bg}>
        <Col>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <CardContainer
              cards={[
                {
                  cardText: (
                    <Row className="m-4">
                      <Col md={1} className="mr-4">
                        <img src={cardImg1} />
                      </Col>
                      <Col md={10}>
                        <Heading2>
                          We’re at the heart of a world that is changing before
                          our eyes
                        </Heading2>
                        <Heading4>
                          “Since winter 2020, Geopost has been at the forefront
                          of global changes, both on a personal and industrial
                          level. These times demand a heightened sense of
                          responsibility for delivery professionals. Amidst this
                          transformative period, we’ve initiated a comprehensive
                          Human Centered Design program to emphasize the human
                          element in our initiatives. Experienced ethnologists
                          have observed European consumers to understand the
                          foundations of this new reality, capturing cultural,
                          environmental, and social diversity. This program aims
                          to integrate these insights into our services and
                          adapt to evolving consumer behaviours and values.”
                        </Heading4>
                        <Body>
                          Jean-Claude Sonet, EVP Marketing, Communication &
                          Sustainability
                        </Body>
                      </Col>
                    </Row>
                  ),
                },
              ]}
              columnSpan={12}
            />
          </ScrollAnimation>
        </Col>
      </Section>

      <Section bgImg={section5Bg} style={{ height: SECTION_HEIGHT }}>
        <Col md={12} className="text-center">
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={DEFAULT_ANIMATION_DELAY}
            animateOnce={true}
          >
            <Heading2>Download our New Commerce White Paper</Heading2>

            <Body>Dive into European consumers lives</Body>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn={ANIMATIONS.FADE_IN_UP}
            delay={600}
            animateOnce={true}
          >
            <DownloadBtn variant="danger" downloadLink={routes.WHITE_PAPER}>
              White Paper (1.2mb)
            </DownloadBtn>
          </ScrollAnimation>
        </Col>
      </Section>

      <GetInTouch />
    </Trackable>
  );
};
