import PropTypes from "prop-types";

import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-up-right-from-square-regular.svg";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";

export const ExternalLinkBtn = ({
  children,
  classes,
  btnLink,
  variant = "outline-primary",
}) => {
  return (
    <CallToActionBtn
      variant={variant}
      btnLink={btnLink}
      externalBtnLink={true}
      classes={classes}
    >
      {children}
      <ArrowRight className="ml-2 align-text-bottom" />
    </CallToActionBtn>
  );
};

ExternalLinkBtn.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  btnLink: PropTypes.string,
  variant: PropTypes.string,
};
