import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "./SearchListItem.module.scss";

export const SearchListItem = ({ item }) => {
  const { path, textContent, section } = item;
  const formattedTitle = textContent.split(/\r?\n/);
  const contentBody = textContent.replace(formattedTitle[0], "").trim();
  const formattedBody = contentBody.replace(/\s+/g, " ").trim();

  return (
    <Link
      className={styles.link}
      to={`${path}?scrollTo=${encodeURIComponent(formattedTitle[0])}${
        section ? `&onclick=${section}` : ""
      }`}
    >
      <div className={styles.card}>
        <h6 className={styles.title}>{formattedTitle[0]}</h6>
        <p className={styles.content}>{formattedBody}</p>
      </div>
    </Link>
  );
};

SearchListItem.propTypes = {
  item: PropTypes.string,
};
