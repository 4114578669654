import classNames from "classnames";
import PropTypes from "prop-types";

export const Quotee = ({ children, classes }) => {
  return (
    <div>
      <br></br>
      <p className={classNames(classes, "Quotee")}>{children}</p>
    </div>
  );
};

Quotee.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
