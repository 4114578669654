import { useCallback, useState } from "react";
import ScrollAnimation from "react-animate-on-scroll";
import { Col, Form, Row } from "react-bootstrap";

import {
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
  LEAD_TIME_COUNTRIES,
} from "../../constants/constants";
import { useStore } from "../../store/store";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import { Dropdown } from "../Dropdown/Dropdown";
import { Section } from "../Section/Section";
import { Heading1 } from "../Typography/Heading1";
import { Heading3 } from "../Typography/Heading3";
import styles from "./LeadTimeCalculator.module.scss";
import { LeadTimeCalculatorInputError } from "./LeadTimeCalculatorInputError";
import { LeadTimeResults } from "./LeadTimeResults";
import useLeadTime from "../../hooks/useLeadTime";
import { getLeadTime } from "../../apis/api";

export const LeadTimeCalculator = () => {
  const { isLeadTimeCalculatorEnabled } = useStore();
  const [loading, setLoading] = useState(false);

  const onCalculateLeadTime = useCallback(async values => {
    try {
      setLoading(true);
      const res = await getLeadTime(values);
      setLeadTimeData(res.data);
    } finally {
      setLoading(false);
    }
  }, []);

  const {
    isFormFilled,
    setLeadTimeValues,
    setLeadTimeData,
    errorMessage,
    showOriginError,
    showDestinationError,
    handleOnSubmit,
    leadTimeData,
    showError,
  } = useLeadTime(onCalculateLeadTime);

  return (
    <>
      {isLeadTimeCalculatorEnabled && (
        <Section>
          <Col md={12} className={styles["leadtime-calc-container"]}>
            <ScrollAnimation
              animateIn={ANIMATIONS.FADE_IN_RIGHT}
              delay={DEFAULT_ANIMATION_DELAY}
              animateOnce={true}
            >
              <div className="text-center">
                <Heading1>Lead-time calculator</Heading1>
                <Heading3>
                  Our lead-time calculator gives you door-to-door transit times
                  to Europe.
                </Heading3>
              </div>
              <Row>
                <Col md={8} className={styles["leadtime-form"]}>
                  <Row>
                    <Heading3 classes={styles["leadtime-title"]}>From</Heading3>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group>
                        <Form.Label>United Kingdom Postcode *</Form.Label>
                        <Form.Control
                          className={styles["leadtime-input"]}
                          onChange={event =>
                            setLeadTimeValues(prevState => {
                              return {
                                ...prevState,
                                originPostalCode: event.target.value,
                              };
                            })
                          }
                          placeholder="Enter UK postcode"
                          style={{
                            borderColor: showOriginError ? "red" : "white",
                          }}
                        />
                        {showOriginError && <LeadTimeCalculatorInputError />}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Heading3 classes={styles["leadtime-title"]}>To</Heading3>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Country *</Form.Label>
                        <Dropdown
                          title="Select country"
                          options={LEAD_TIME_COUNTRIES}
                          onChange={value =>
                            setLeadTimeValues(prevState => {
                              return {
                                ...prevState,
                                destinationCountry: value,
                              };
                            })
                          }
                          darkMode={false}
                          verticalActiveBtn={true}
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Postcode *</Form.Label>
                        <Form.Control
                          className={styles["leadtime-input"]}
                          onChange={event =>
                            setLeadTimeValues(prevState => {
                              return {
                                ...prevState,
                                destinationPostalCode: event.target.value,
                              };
                            })
                          }
                          placeholder="Enter postcode"
                          style={{
                            borderColor: showDestinationError ? "red" : "white",
                          }}
                        />
                        {showDestinationError && (
                          <LeadTimeCalculatorInputError />
                        )}
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} className="d-flex justify-content-end">
                      <CallToActionBtn
                        onClick={handleOnSubmit}
                        disabled={!isFormFilled()}
                      >
                        Calculate
                      </CallToActionBtn>
                    </Col>
                  </Row>
                </Col>

                <LeadTimeResults
                  data={leadTimeData}
                  loading={loading}
                  showError={showError}
                  errorMessage={errorMessage}
                />
              </Row>
            </ScrollAnimation>
          </Col>
        </Section>
      )}
    </>
  );
};
