import { useEffect, useMemo } from "react";

import ScrollAnimation from "react-animate-on-scroll";
import { Card, CardGroup, Col, Container } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";

import bgImg from "../../../../assets/backgrounds/purple-top-bg.png";
import articleHeaderDefault from "../../../../assets/pages/international/blog/articleHeaderDefault.png";

import {
  blogArticles,
  DPD_NEWS_FEED_CATEGORY_LIST,
} from "../../../../data/blog";
import { BLOG } from "../../../../router/constants";
import { getFormattedDate } from "../../../../helpers/helpers";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { GetInTouch } from "../../../../components/GetInTouch/GetInTouch";
import { Section } from "../../../../components/Section/Section";
import { Picks } from "../../../../components/Picks/Picks";
import { CallToActionBtn } from "../../../../components/CallToActionBtn/CallToActionBtn";
import { Header } from "../../../../components/Typography/BlogArticle/Header";
import { Heading4 } from "../../../../components/Typography/Heading4";
import { Heading5 } from "../../../../components/Typography/Heading5";
import { ANIMATIONS } from "../../../../constants/constants";
import { Body } from "../../../../components/Typography/Body";
import { ReactComponent as ArrowLeft } from "../../../../assets/icons/arrow-left.svg";

import styles from "./BlogArticle.module.scss";

export const BlogArticle = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const article = useMemo(
    () => blogArticles.find(article => id && article.id === +id),
    [id]
  );

  const previousIndex = blogArticles.findIndex(
    article => article.id === +id - 1
  );
  const nextIndex = blogArticles.findIndex(article => article.id === +id + 1);

  const previousArticle =
    previousIndex !== -1 ? blogArticles[previousIndex] : null;
  const nextArticle = nextIndex !== -1 ? blogArticles[nextIndex] : null;

  useEffect(() => {
    if (!article) {
      navigate(BLOG);
    }
  }, [article]);

  const windowSize = useWindowSize().width;

  return (
    <>
      {article && (
        <>
          <Section
            bgImg={article?.navImg || articleHeaderDefault}
            style={{
              backgroundPosition: "center center",
            }}
          >
            <Col md={12}>
              <Container className={"text-initial"}>
                <CallToActionBtn classes={styles.blogButton} btnLink={BLOG}>
                  <ArrowLeft style={{ marginRight: 12 }} />
                  <span className={styles.blogBtnText}>Blog</span>
                </CallToActionBtn>
                <Header classes={"text-center"}>{article.title}</Header>
                <Col className="d-flex">
                  <Card.Text>{getFormattedDate(article.dateTitle)}</Card.Text>
                  <Card.Text className="mx-1">&bull;</Card.Text>
                  <Picks
                    title={article.category}
                    colour={DPD_NEWS_FEED_CATEGORY_LIST[article.category]}
                  ></Picks>
                </Col>
              </Container>
            </Col>
          </Section>

          <Section bgImg={bgImg}>
            <ScrollAnimation
              animateIn={ANIMATIONS.FADE_IN_UP}
              animateOnce={true}
              style={{ width: "100%" }}
            >
              <Col md={12}>
                <Heading4>{article?.subtitle}</Heading4>
              </Col>
              <Col md={12} style={{ wordWrap: "break-word" }}>
                {article.body}
              </Col>
            </ScrollAnimation>
          </Section>

          <Section>
            <ScrollAnimation
              animateIn={ANIMATIONS.FADE_IN_UP}
              animateOnce={true}
              style={{ width: "100%" }}
            >
              <CardGroup>
                {nextArticle ? (
                  <Card
                    className={`${styles.navArticleCard} ${styles.nextArticleCard} bg-transparent`}
                  >
                    <Body>Next</Body>
                    <Heading5>{nextArticle.title}</Heading5>
                    <Container>
                      <Body>
                        {nextArticle.dateTitle}
                        <span className="mx-1">&bull;</span>
                        {nextArticle.category}
                      </Body>
                      <CallToActionBtn btnLink={`${BLOG}/${nextArticle.id}`}>
                        Read More
                      </CallToActionBtn>
                    </Container>
                  </Card>
                ) : (
                  <Card className={`${styles.emptyCard} bg-transparent`}></Card>
                )}
                {previousArticle ? (
                  <Card
                    style={{
                      borderLeft: windowSize < 576 ? "1px solid white" : "none",
                      borderRight:
                        windowSize >= 576 ? "1px solid white" : "none",
                      borderTop: "none",
                      borderBottom: "none",
                      textAlign: windowSize < 576 ? "left" : "right",
                    }}
                    className={`${styles.navArticleCard} directional-border bg-transparent`}
                  >
                    <Body className="card-text-right">Previous</Body>
                    <Heading5>{previousArticle.title}</Heading5>
                    <Container>
                      <Body>
                        {previousArticle.dateTitle}
                        <span className="mx-1">&bull;</span>
                        {previousArticle.category}
                      </Body>
                      <CallToActionBtn
                        btnLink={`${BLOG}/${previousArticle.id}`}
                      >
                        Read More
                      </CallToActionBtn>
                    </Container>
                  </Card>
                ) : (
                  <Card className={`${styles.emptyCard} bg-transparent`}></Card>
                )}
              </CardGroup>
            </ScrollAnimation>
          </Section>
          <GetInTouch />
        </>
      )}
    </>
  );
};
