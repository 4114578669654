import PropTypes from "prop-types";
import { Badge } from "react-bootstrap";

export const Picks = ({ title, colour }) => {
  return (
    <span>
      <Badge
        style={{
          backgroundColor: colour,
          borderRadius: 24,
          fontSize: 14,
          fontWeight: 400,
        }}
      >
        {title}
      </Badge>
    </span>
  );
};

Picks.propTypes = {
  title: PropTypes.string,
  colour: PropTypes.string,
};
