import PropTypes from "prop-types";

import { FILTERS } from "../../constants/constants";
import styles from "./SearchFilters.module.scss";

export const SearchFilters = ({
  active,
  setActive,
  setItemOffset,
  filterLengths,
  setCurrentPage,
}) => {
  const checkActive = (active, filter) =>
    active === filter ? styles.active : null;

  const onClick = item => {
    setActive(item);
    setItemOffset(0);
    setCurrentPage(0);
  };

  const checkLengthActive = (active, filter) =>
    active === filter ? styles.lengthActive : null;

  return (
    <div>
      <ul className={styles.ul}>
        <li onClick={() => onClick(FILTERS.ALL)}>
          <div className={checkActive(active, FILTERS.ALL)}>
            All{" "}
            <div
              className={`${styles.searchLength} ${checkLengthActive(
                active,
                FILTERS.ALL
              )}`}
            >
              {filterLengths.all}
            </div>
          </div>
        </li>
        <li onClick={() => onClick(FILTERS.ABOUT)}>
          <div className={checkActive(active, FILTERS.ABOUT)}>
            About{" "}
            <div
              className={`${styles.searchLength} ${checkLengthActive(
                active,
                FILTERS.ABOUT
              )}`}
            >
              {filterLengths.about}
            </div>
          </div>
        </li>
        <li onClick={() => onClick(FILTERS.SERVICES)}>
          <div className={checkActive(active, FILTERS.SERVICES)}>
            Our Services{" "}
            <div
              className={`${styles.searchLength} ${checkLengthActive(
                active,
                FILTERS.SERVICES
              )}`}
            >
              {filterLengths.services}
            </div>
          </div>
        </li>
        <li onClick={() => onClick(FILTERS.INSIGHT)}>
          <div className={checkActive(active, FILTERS.INSIGHT)}>
            Insight & Info{" "}
            <div
              className={`${styles.searchLength} ${checkLengthActive(
                active,
                FILTERS.INSIGHT
              )}`}
            >
              {filterLengths.international}
            </div>
          </div>
        </li>
        <li onClick={() => onClick(FILTERS.SUPPORT)}>
          <div className={checkActive(active, FILTERS.SUPPORT)}>
            Support{" "}
            <div
              className={`${styles.searchLength} ${checkLengthActive(
                active,
                FILTERS.SUPPORT
              )}`}
            >
              {filterLengths.support}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
};

SearchFilters.propTypes = {
  active: PropTypes.string,
  setActive: PropTypes.func,
  setItemOffset: PropTypes.func,
  filterLengths: PropTypes.object,
  setCurrentPage: PropTypes.func,
};
