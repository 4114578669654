import { useTracker } from "@dpdgroupuk/react-event-tracker";

import { ReactComponent as MailIcon } from "../../assets/icons/mail-icon.svg";
import { FOOTER } from "../../constants/actions";
import { useStore } from "../../store/store";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import styles from "./MessageBtn.module.scss";

export const MessageBtn = () => {
  const { setShowContactUsModal } = useStore();
  const tracker = useTracker();

  return (
    <div className={styles["contact-us-container"]}>
      <CallToActionBtn
        classes={["btn-circle"]}
        onClick={() => {
          setShowContactUsModal(true);
          tracker.logEvent(FOOTER.ON_CLICK_EMAIL_ICON);
        }}
      >
        <MailIcon />
      </CallToActionBtn>
    </div>
  );
};
