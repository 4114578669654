import classNames from "classnames";
import PropTypes from "prop-types";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Row } from "react-bootstrap";

import {
  ALIGNMENT,
  ANIMATIONS,
  DEFAULT_ANIMATION_DELAY,
} from "../../constants/constants";
import variables from "../../styles/colors.scss";

export const Section = ({
  bgImg,
  bgColor = variables.dark,
  alignment = ALIGNMENT.LEFT,
  children,
  classes,
  style,
}) => {
  const rowAlignment =
    alignment === ALIGNMENT.LEFT
      ? "justify-content-start"
      : alignment === ALIGNMENT.RIGHT
      ? "justify-content-end"
      : null;

  return (
    <ScrollAnimation
      animateIn={ANIMATIONS.FADE_IN_IMAGE}
      delay={DEFAULT_ANIMATION_DELAY}
      animateOnce={true}
    >
      <section
        className={classNames(classes, "section-image")}
        style={{
          background: !bgImg ? bgColor : "",
          backgroundImage: `url(${bgImg})`,
          ...style,
        }}
        data-searchable
      >
        <Container>
          <Row className={classNames("h-100 align-items-center", rowAlignment)}>
            {children}
          </Row>
        </Container>
      </section>
    </ScrollAnimation>
  );
};

Section.propTypes = {
  children: PropTypes.node,
  bgImg: PropTypes.string,
  bgColor: PropTypes.string,
  alignment: PropTypes.string,
  shouldAnimate: PropTypes.bool,
  classes: PropTypes.string,
  style: PropTypes.any,
};
