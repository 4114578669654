import classNames from "classnames";
import PropTypes from "prop-types";

export const Heading2 = ({ children, classes }) => {
  return <h2 className={classNames("heading-h2", classes)}>{children}</h2>;
};

Heading2.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.string,
};
