import PropTypes from "prop-types";
import LoadingOverlay from "react-loading-overlay";

import spinner from "../../assets/icons/dpd-spinner.gif";

export const Loader = ({ isActive, children }) => {
  return (
    <LoadingOverlay
      active={isActive}
      spinner={<img src={spinner} height={50} width={50} />}
    >
      {children}
    </LoadingOverlay>
  );
};

Loader.propTypes = {
  isActive: PropTypes.bool,
  children: PropTypes.node,
};
