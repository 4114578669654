import PropTypes from "prop-types";

import { ReactComponent as BtnIcon } from "../../assets/icons/chevron_left.svg";
import * as router from "../../router/constants";
import { CallToActionBtn } from "../CallToActionBtn/CallToActionBtn";
import { Heading2 } from "../Typography/Heading2";

export const ExportFundamentalsHeader = ({
  headerText,
  anchorLinkText = "Export Fundamentals",
  anchorLinkHref = router.EXPORT_FUNDAMENTALS,
}) => {
  return (
    <div className={"text-initial"}>
      <CallToActionBtn classes="mb-5" btnLink={anchorLinkHref}>
        <BtnIcon />
        {anchorLinkText}
      </CallToActionBtn>
      <Heading2 classes={"text-center"}>{headerText}</Heading2>
    </div>
  );
};

ExportFundamentalsHeader.propTypes = {
  headerText: PropTypes.string,
  anchorLinkText: PropTypes.string,
  anchorLinkHref: PropTypes.string,
};
