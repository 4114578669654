import classNames from "classnames";
import PropTypes from "prop-types";
import { Col, Container, Row } from "react-bootstrap";

import styles from "./BulletList.module.scss";

export const BulletList = ({ listItems, listIcon, classes }) => {
  return (
    <Container>
      <ul className={classNames(classes, styles["bullet-list"])}>
        {listItems.map((item, i) => {
          return (
            <Row key={i}>
              {"icon" in item ? (
                <Col md={1} xs={1} className={styles["bullet-list-icon"]}>
                  {item.icon}
                </Col>
              ) : listIcon ? (
                <Col md={1} xs={1} className={styles["bullet-list-icon"]}>
                  {listIcon}
                </Col>
              ) : (
                <span className="pl-3"></span>
              )}
              <Col md={11} xs={11} className={styles["bullet-list-text"]}>
                <li
                  style={{
                    listStyleType: "icon" in item || listIcon ? "none" : "",
                  }}
                >
                  <p>{item.text}</p>
                </li>
              </Col>
            </Row>
          );
        })}
      </ul>
    </Container>
  );
};

BulletList.propTypes = {
  listItems: PropTypes.arrayOf(PropTypes.any),
  listIcon: PropTypes.node,
  classes: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};
